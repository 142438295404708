import React, { ReactNode } from 'react';
import { WprBaseDialogViewComponent } from '../../../../../wpr-framework/view/component/WprBaseDialogViewComponent';
import { KattobiAgreeDialogView } from './KattobiAgreeDialogView';
import styles from './KattobiAgreeDialog.scss';
import Typography from '@material-ui/core/Typography';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';

export class KattobiAgreeSendData {
}

export class KattobiAgreeResultData {
}

/**
 * かっとび光同意事項ダイアログ用コンポーネント
 */
export class KattobiAgreeDialog extends WprBaseDialogViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new KattobiAgreeDialogView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** かっとび光同意事項ダイアログビュー情報 */
	public get viewInfo(): KattobiAgreeDialogView	{ return this.view as KattobiAgreeDialogView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	 */
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='reactPdf button scroll' style={{ overflowY: 'unset'}}>
					<div className='title_lineLeft title' style={{ backgroundColor: '#eceef4'}}>
						<Typography>契約者情報の第三者提供に関する同意</Typography>
					</div>
					<div className='subTitle'>
						１．契約者情報を提供・開示する第三者
					</div>
					<div className='text'>
						<ul style={{ listStyle: 'none'}} >
							<li>
								（1）「かっとび光」をご契約いただく場合
							</li>
							<li>
								「かっとび光」における回線事業者としての東日本電信電話株式会社（以下「NTT東日本」といいます。）
							</li>
							<li>
								（2）イッツコムからNTT東日本または他社光コラボ事業者へ事業者変更する場合
							</li>
							<li>
								事業者変更先となる、NTT東日本および光コラボレーションモデルに関する契約を締結している電気通信事業者
							</li>
							<li>
								（3）NTT東日本の回線を利用した他社サービスの提供を受ける場合
							</li>
							<li>
								NTT東日本の協定事業者、特定事業者、その他関連サービス提供事業者等
							</li>
						</ul>
					</div>
					<div className='subTitle'>
						2．提供・開示する契約者情報
					</div>
					<div className='text'>
						<ul style={{ listStyle: 'none'}}>
							<li>
								契約ID、契約者名、契約者住所（機器設置場所住所）、契約者連絡用電話番号、ご利用サービス品目など
							</li>
						</ul>
					</div>
					<div className='subTitle'>
						3.提供・開示の手段および方法
					</div>
					<div className='text'>
						<ul style={{ listStyle: 'none'}}>
							<li>
								イッツコムとの契約時にNTT東日本管轄のネットワークシステムに登録したデータをNTT東日本の認証システムを介して提供 
							</li>
						</ul>
					</div>
					<div className='subTitle'>
						4．提供・開示を受けた第三者における利用目的
					</div>
					<div className='text'>
						<ul style={{ listStyle: 'none'}}>
							<li>
								新規申し込み、サービス品目変更、不具合発生時の対応、サービス保守、解約、転用、事業者変更の手続き、およびNTT東日本関連サービスの提供に伴う手続き
							</li>
						</ul>
					</div>
					<div className='subTitle'>
						5．契約者情報提供の停止
					</div>
					<div className='text'>
						<ul style={{ listStyle: 'none'}}>
							<li>
								イッツ・コミュニケーションズは、契約者本人の求めに応じて当該本人が識別される個人情報の第三者への提供を停止します
							</li>
						</ul>
					</div>
					<div className='subTitle'>
						6．契約者情報の取得方法
					</div>
					<div className='text'>
						<ul style={{ listStyle: 'none'}}>
							<li>
								申込書面
							</li>
						</ul>
					</div>
					<div className='subTitle'>
						7．請求方法
					</div>
					<div className='text'>
						<ul style={{ listStyle: 'none'}}>
							<li>
								本人からの請求などは書面にて受け付けます。
							</li>
							<li>
								個人情報お問い合わせ窓口　044-577-7504 (9：30～12：30　13：30～18：00（土・日・祝祭日は除く）)
							</li>
						</ul>
					</div>
					<div className='agreeButton'>
						<MatButton name='agree' variant='contained' color='primary' view={this.view}>同意</MatButton>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
