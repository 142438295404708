import { WprModel } from "../../../../../wpr-framework/view/deco/WprModel";
import { WprDI_ScopeModel } from "../../../../../wpr-framework/view/di/WprDI_ScopeModel";
import { learningCourseListModel } from "../../../../models/mypage/itscomLearning/learningCourseListModel";
import { MypageBaseView } from "../../../base/MypageBaseView";
import { CompleteModel } from "../../../common/common_complete/models/CompleteModel";
import { LearningServiceCancelWizardModel } from "../../../wizard/ItscomLearningCancelWizardFlow";
import { MypageWizardMngr } from "../../../wizard/models/MypageWizardMngr";


/**
 * イッツコムラーニング解約申込完了画面
 */
export class LearningCancelCompleteView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('LearningCancelCompleteView'); }
	// --------------------------------------------------------------------------
	@WprModel('CompleteModel')
	private m_CompleteModel: CompleteModel = null;	// 完了情報モデル
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;		// 画面ウィザード管理クラス
	@WprModel('LearningModel')
	private m_LearningModel: learningCourseListModel	= null;	
	// メンバ変数 ---------------------------------------------------------------
	private m_CustomerName: string = null;	// 顧客名
	private m_LearningServiceCancelModel: LearningServiceCancelWizardModel  = null;		
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------

	/**
 * コントロール定義設定
 */
	public onRegisterConfig(): void {
		this.addConfig('title_complete', 'タイトル', 'CompleteModel', { bindName: 'title' });
		this.addConfig('user', 'ユーザー', 'CompleteModel', { bindName: 'user' });
		this.addConfig('msg1', '本文1', 'CompleteModel', { bindName: 'massage1' });
		this.addConfig('msg2', '本文2', 'CompleteModel', { bindName: 'massage2' });
		this.addConfig('dispCourse', 'コース', 'LearningModel');
		this.addConfig('loginId', 'ログインID', 'LearningModel');
	}
	/**
 * アクション登録
 */
	public onRegisterAction(): void {
		this.addAction('toQuestionnaire', this.onToQuestionnaire);
		this.addAction('back', this.onBack);
	}


	/**
 * ビュー表示通知
 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			// 遷移情報
			this.m_LearningServiceCancelModel = new LearningServiceCancelWizardModel();
			this.m_LearningModel =  this.m_MypageWizardMngr.getCache().m_LearningModel;
			const customerInfo = this.m_MypageWizardMngr.getViewInfo().customer;
			if (customerInfo.sexCD === '3' || customerInfo.sexCD === '')
				this.m_CustomerName = customerInfo.corporateName;
			else
				this.m_CustomerName = customerInfo.surName + ' ' + customerInfo.givenName;

			const model = new CompleteModel();
			model.user = this.m_CustomerName + 'さん';
			model.title = 'イッツコムラーニングの解約を受け付けました。';
			model.massage1 = '以下の内容で変更が完了しました。';
			model.massage2 = '連絡先メールアドレスに確認メールを送信しました。';
			this.m_CompleteModel = model;
			this.setViewMode('コース',this.m_LearningModel.dispCourse);
			this.refreshView();
		});
	}
	// アクション  ---------------------------------------------------------------
	/**
	 * アンケート
	 */
	public onToQuestionnaire(param: any): void {
		window.open('https://questant.jp/q/B7KS5E4S?id=' + this.m_MypageWizardMngr.getParams().customerId);
	}

	/**
	 * 契約内容の確認・変更メニューへ
	 */
	public onBack(param: any): void {
		location.href = this.getOldUrl() + 'Srvlist/Continfo_list/';
	}
	// --------------------------------------------------------------------------
}
