import { WprModel } from "../../../../wpr-framework/view/deco/WprModel";
import { WprDI_ScopeModel } from "../../../../wpr-framework/view/di/WprDI_ScopeModel";
import MypageErrorConstants from "../../../common/MypageErrorConstants";
import { MvnoEntryInfo } from "../../../service/models/apimodels/MvnoEntryInfo";
import { HMvnoTmpSaved } from "../../../service/models/entity/HMvnoTmpSaved";
import { MypageBaseView } from "../../base/MypageBaseView";

/**
 * エラー画面
 */
export class MypageErrorView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MypageError'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	// @WprModel('errorMessage')
	@WprDI_ScopeModel('errorMessage')
	private m_errorMessage: string = null;

	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {

		// this.addConfig('entryTerminalFlg', '端末申込', 'MvnoEntryInfo.entryInfo');
		// this.addConfig('errorMessage', 'エラーメッセージ', 'errorMessage', { bindModel: true });
		// this.addConfig('firstName', '氏名（名）', 'MvnoEntryInfo.entryInfo');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('toMypageTop', this.onToMypageTop);
		this.addAction('inquiryOther', this.onInquiryOther);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
		this.setStorageItem('b_url', window.location.href)
		if (!this.m_errorMessage) {
			this.m_errorMessage = '再度マイページにアクセスしてください。\n';
		}
		const errorCd = this.getUrlParam('errorCd');
		//名義変更エラー
		if (errorCd === MypageErrorConstants.NAMECHANGE_ERROR) {
			this.m_errorMessage = '名義変更のお手続き中です。<br />'
				+ '完了まで今しばらくお待ちください。<br />'
				+ '改めてお申し込みが必要名場合には、フリーダイヤルまでお問合せください。\n';
		}
		//セッションエラー
		else if (errorCd === MypageErrorConstants.SESSION_ERROR) {
			this.m_errorMessage = 'セッションエラーが発生しました。再度マイページにアクセスしてください。\n';
		}
		//APIエラー
		else if (errorCd === MypageErrorConstants.API_ERROR) {
			this.m_errorMessage = 'エラーが発生しました。しばらくしてからもう一度お試しください。\n';
		}
		//9000エラー
		else if (errorCd === MypageErrorConstants.SERVICE_ERROR) {
			this.m_errorMessage = 'エラーが発生しました。しばらくしてからもう一度お試しください。\n';
		}
		// 端末登録APIエラー(-10)
		else if (errorCd === MypageErrorConstants.INSTALL_TERMINAL_API_ERROR) {
			this.m_errorMessage = 'セッション切れ、ブラウザバック等の操作でエラーが発生いたしました。<br/>お手数おかけしますが、QRコードの読み込みからやり直しをお願いいたします。\n';
		}
		// 端末登録完了済みエラー(-100)
		else if (errorCd === MypageErrorConstants.TERMINAL_FINISHED_ERROR) {
			this.m_errorMessage = '既に機器登録は完了しております。\n';
		}
		// 端末登録-300エラー
		else if (errorCd === MypageErrorConstants.TERMINAL_ERROR300) {
			this.m_errorMessage = 'エラーが発生いたしました。<br/>担当より3日以内の営業時間内に、オンライン申込時にご登録いただきました連絡先にご連絡いたします。<br/>ご不便おかけし申し訳ございませんが、今しばらくお待ちください。\n';
		}
		// 端末登録-400エラー
		else if (errorCd === MypageErrorConstants.TERMINAL_ERROR400) {
			this.m_errorMessage = 'エラーが発生しました。しばらくしてからもう一度お試しください。\n';
		}
		// 端末登録その他エラー
		else if (errorCd === MypageErrorConstants.TERMINAL_ERROR101 || errorCd === MypageErrorConstants.TERMINAL_ERROR102 || errorCd === MypageErrorConstants.TERMINAL_ERROR103 || errorCd === MypageErrorConstants.TERMINAL_ERROR104 || errorCd === MypageErrorConstants.TERMINAL_ERROR105 || errorCd === MypageErrorConstants.TERMINAL_ERROR106 || errorCd === MypageErrorConstants.TERMINAL_ERROR200){
			this.m_errorMessage = 'エラーが発生いたしました。<br/>担当より今日明日中の営業時間内に、オンライン申込時にご登録いただきました連絡先にご連絡いたします。<br/>ご不便おかけし申し訳ございませんが、今しばらくお待ちください。\n';
		}
		this.setViewData('errorMessage', this.m_errorMessage);
		this.refreshView();
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * その他のお問い合わせ
	 */
	public onInquiryOther(param: any): void {
		location.href = this.getOldUrl() + "Inquiry/Inquiry_othe_entry/";
	}

	/**
	 * 次へ
	 */
	public onToMypageTop(param: any): void {
		// location.href = "https://stage.mypage.itscom.net/Top/My_top/";
		location.href = this.getOldUrl() + "Top/My_top/";
	}
	// --------------------------------------------------------------------------
}
