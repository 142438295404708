import React, { ReactNode } from 'react';

import { Typography, Grid, Divider } from '@material-ui/core';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import importantIcon from '/shared/image/common/important_purple.png';
import netIcon from '/shared/image/common/logo_iTSCOMTV.png';
import { LearningCancelConfirmView } from './LearningCancelConfirmView';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { WprSpan } from '../../../../../wpr-framework/component/controls/WprSpan';
import styles from './LearningCancelConfirm.scss';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
/**
 * イッツコムラーニング解約確認画面用コンポーネント
 */
export class LearningCancelConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new LearningCancelConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** マガジン解約申込画面ビュー情報 */
	public get viewInfo(): LearningCancelConfirmView { return this.view as LearningCancelConfirmView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	 */
	public getStylesObject(): any { return styles; }


	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='inContractArea'>
						
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text'  view={this.view} />
								<Typography className='title_comment' style={{ fontWeight: '500' }}>以下の内容でよろしければ、「解約」ボタンをクリックしてください。</Typography>
							</div>
						</div>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>コース</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{ color: 'black' }}>
									<WprSpan name={'dispCourse'} className='itemUnit' view={this.view} style={{ color: '#484848', fontWeight: '550', fontsize: '40spx' }}></WprSpan>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<WprContainer mode='コース.デキタス' view={this.view}>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>デキタスログインID</div>
									</Grid>
								</WprContainer>
								<WprContainer mode='コース.デジタネ' view={this.view}>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>デジタネログインID</div>
									</Grid>
								</WprContainer>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{ color: 'black' }}>
									<WprSpan name={'loginId'} className='itemUnit' view={this.view} style={{ color: '#484848', fontWeight: '550', fontsize: '40spx', overflowWrap: 'break-word'}}></WprSpan>
								</Grid>
							</Grid>
						</div>
						<div className='attention_fontSize'>
							<ul style={{ listStyle: 'none',marginBottom: '0px'}} className='text'>
								<li>
									【イッツコムラーニングサービスのご解約】
								</li>
							</ul>
							<ul className='ul_ten attention_fontSize margin'>
								<li>
									１アカウントずつ解約を受け付けております。複数のアカウントの解約をご希望の場合は、恐れ入りますが改めて本フォームの入力をお願いいたします。
								</li>
							</ul>
							<ul style={{ listStyle: 'none',marginBottom: '0px'}} className='text'>
								<li>
									【イッツコムラーニング デキタスをご解約の方】
								</li>
							</ul>
							<ul className='ul_ten attention_fontSize margin'>
								<li>
									毎月末日付での解約となります。毎月末より10日以前に解約を承った場合は、当該月の月末付の解約となります。
								</li>
								<li>
									当サービスは開始月を含めた3ヶ月間が最低利用期間となっております。最低利用期間に満たない時点での解約をご希望の場合は、最短解約可能月での受付となります。
									<br />ただし、各月の1日～15日にイッツコムラーニング デキタスをお申込みいただいた方に限り、お申込日の属する月の月末より10日以前に解約を承った場合、お申込日の属する月の末日付での解約となります。また、お申込日の属する月の月額利用料は発生いたしません。
								</li>
								<li>
									毎月末より10日前以後に解約を承った場合は、翌月末付での解約となります。ただし年末年始はこの限りではありません。<br />（例：9月20日までのお申し出…9月30日付での解約　9月21日以降のお申し出…10月31日付での解約）
								</li>
								<li>
									ご解約のお申し出をキャンセルされる場合、毎月末より10日以前までにお申し出ください。毎月末より10日前より後の解約キャンセルは承りかねますので、再申し込みが必要となります。
								</li>
								<li>
									一度ご解約されたアカウントの学習データは削除されますため、再申込等により新たなアカウントをご利用いただく場合もデータを引き継ぐことは出来ません。
								</li>
							</ul>
							<ul style={{ listStyle: 'none',marginBottom: '0px'}} className='text'>
								<li>
									【イッツコムラーニング デジタネをご解約の方】
								</li>
							</ul>
							<ul style={{ listStyle: 'none',paddingLeft: 'unset'}} className='margin'>
								<li>
									・月払いプランのご解約
								</li>
								<li>
									毎月末日付での解約となります。毎月末より10日以前に解約を承った場合は、当該月の月末付の解約となります。
								</li>
								<li>
									当サービスは開始月を含めた2ヶ月間が最低利用期間となっております。最低利用期間に満たない時点での解約をご希望の場合は、最短解約可能月での受付となります。
								</li>
								<li>
									毎月末より10日前以後に解約を承った場合は、翌月末付での解約となります。ただし年末年始はこの限りではありません。<br />（例：9月20日までのお申し出…9月30日付での解約　9月21日以降のお申し出…10月31日付での解約）
								</li>
								<li>
									ご解約のお申し出をキャンセルされる場合、毎月末より10日以前までにお申し出ください。毎月末より10日前より後の解約キャンセルは承りかねますので、再申し込みが必要となります。
								</li>
								<li>
									一度ご解約されたアカウントの学習データは削除されますため、再申込等により新たなアカウントをご利用いただく場合もデータを引き継ぐことは出来ません。
								</li>
							</ul>
							<ul style={{ listStyle: 'none',paddingLeft: 'unset'}} className='margin divider'>
								<li>
									・年払いプランのご解約
								</li>
								<li>
									年払いプランの契約期間は1年間となり、契約満了月の月末より10日以前に解約を承った場合は、契約期間満了月付での受付となります。契約満了月の月末より10日以後に解約を承った場合は、次回の契約満了月付での解約となります。
								</li>
								<li>
									一度ご解約されたアカウントの学習データは削除されますため、再申込等により新たなアカウントをご利用いただく場合もデータを引き継ぐことは出来ません。
								</li>
								<li>
									ご解約のお申し出をキャンセルされる場合、毎月末より10日以前までにお申し出ください。毎月末より10日前より後の解約キャンセルは承りかねますので、再申し込みが必要となります。
								</li>
							</ul>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' className='orangeButton orangeButton_parts' view={this.view}>解約
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
