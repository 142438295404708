import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { S_01_004View } from './S_01_004View';
import { MatCheckBox } from '../../../../wpr-framework/component/controls/material/MatCheckBox';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { Footer } from '../../common/footer/Footer';
import { Header } from '../../common/header/Header';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { PDFDialog } from '../../dialog/p_d_f_dialog/PDFDialog';
import styles from './S_01_004.scss';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import { CompleteDialog } from '../dialog/complete_dialog/CompleteDialog';
import { Divider, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core';
import { MatTableSortLabel } from '../../../../wpr-framework/component/controls/material/MatTableSortLabel';
import { WprAnchor } from '../../../../wpr-framework/component/controls/WprAnchor';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import document_icon from "../../../../../shared/image/contractConfirm/document_icon.png"
import { MGeneral } from '../../../service/models/entity/MGeneral';
import { MGeneralCheckInfo } from '../../../models/MGeneralCheckInfo';
import { KattobiAgreeDialog } from '../dialog/kattobi_agree_dialog/KattobiAgreeDialog';


/**
 * 契約内容の確認用コンポーネント
 */
export class S_01_004 extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new S_01_004View(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 契約内容の確認ビュー情報 */
	public get viewInfo(): S_01_004View { return this.view as S_01_004View; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('APList', null, this.onAPListRender);
		this.addListRender('IEList', null, this.onIEListRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div>
					<div className='pageCard'>
						<div className="pageHeader title">
							契約内容確認
						</div>
						<Divider />
						<div className='pageContent'>
							<div className=''>
								<div className=''>
									表示された契約内容確認書を最下部まで確認をお願いいたします。
									<br />最下部まで確認いただくことで下記「契約内容に同意する」にチェックが可能になります。
								</div>
							</div>

							<div>
								<WprContainer mode='IEリスト.有' view={this.view}>
									<div>
										<div className='title_maru'>重要事項確認等</div>
										{this.renderList('IEList')}
									</div>

								</WprContainer>
								<WprContainer mode='APリスト.有' view={this.view}>
									<div>
										<div className='title_maru'>AP利用規約</div>
										{this.renderList('APList')}
									</div>
								</WprContainer>
								<WprContainer mode='かっとび光.表示' view={this.view}>
									<div>
										<div className='title_maru'>個人情報提供同意</div>
										<div>
											<MatButton name='k_Show' view={this.view} className='button_white'>
												<img src={document_icon} className='documentIcon' />
												<Typography className='buttonText'>
													かっとび光のお申し込みにあたりご同意いただきたい事項
												</Typography>
											</MatButton>
										</div>
									</div>
								</WprContainer>
							</div>
							
							<div className='box_gray'>
								<div className='boxContent'>
									<div className='title_maru'>契約内容確認書</div>
									<WprContainer mode='PDFスクロール.未' view={this.view}>
										<div>
											<p className='comment'>
												契約内容確認書の確認が
												<span className='errorText'>最後まで</span>
												完了していません。
												<br/>再度、「契約内容確認書の確認はこちら」からご確認いただき、
												<span className='errorText'>画面最下部までスクロールして</span>
												お読みいただくようにお願いいたします。
											</p>

										</div>
									</WprContainer>
									<div>
										<MatButton name='b_DialogPDF' className='button_white' view={this.view}>
											<img src={document_icon} className='documentIcon' />
											<Typography className='buttonText'>
												契約内容確認書の確認はこちら
											</Typography>
										</MatButton>
									</div>

									<p className='comment'>
										表示された契約内容確認書を最下部まで確認をお願いいたします。
										<br/>最下部まで確認いただくことで契約内容に同意いただくことが可能になります。
									</p>
								</div>
							</div>

							<div style={{ textAlign: 'center' }}>
								<MatCheckBox name='c_Confirm' label='契約内容に同意する' view={this.view} />
							</div>

							<div className='pageFooter'>
								<div>
									<MatButton name='b_Next' view={this.view} className='nextButton' >
										確認を完了する
										<NavigateNextIcon />
									</MatButton>
								</div>

							</div>
						</div>
					</div>
				</div>
				{/* <div className='display'>
					<Header />
					<div className='common'> */}
				{/* <h1>契約内容確認</h1>

					{this.renderList('APList')}
					<div className='childCenter contents'>
						<WprContainer mode='PDFスクロール.未' view={this.view}>
							<p className='alert'>
								契約内容確認書の確認が
								<span>最後まで</span>
								完了していません。再度、「契約内容の確認はこちら」からご確認いただき、
								<span>画面最下部までスクロールして</span>
								お読みいただくようにお願いいたします。
							</p>
						</WprContainer>
						<MatButton name='b_DialogPDF' className='pdfButton2' view={this.view}>契約内容確認書の確認はこちら</MatButton>
						<p className='alert'>
							表示された契約内容確認書を最下部まで確認をお願いいたします。
							最下部まで確認いただくことで契約内容に同意いただくことが可能になります。
						</p>

						<p className='checkBox'><MatCheckBox name='c_Confirm' label='契約内容に同意する' view={this.view} /></p>
						<MatButton name='b_Next' variant='text' color='primary' className='NextButton' view={this.view}>確認を完了する　＞</MatButton>
					</div> */}
				{/* </div>
					<Footer /> */}
				{/* </div> */}
				{/* ダイアログ */}
				<PDFDialog name='pdfダイアログ' className='pdfDialog' view={this.view} />
				<CompleteDialog name='ダイアログ' className='smallDialog' view={this.view} />
				<KattobiAgreeDialog name='かっとび光同意事項ダイアログ'className='pdfDialog' view={this.view}/>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=ImportamtInfoList
	 */
	public onIEListRender(rowInfo: WprRowInfo): any {
		const data: MGeneralCheckInfo = rowInfo.rowData;
		const mgeneral: MGeneral = data.mGeneral;
		const className = (data.checked) ? 'button_white button_white_checked' : 'button_white';
		return (
			<div>
				<MatButton name='b_Show' view={this.view} row={rowInfo} className='button_white' id={String(mgeneral.generalId)}>
					<img src={document_icon} className='documentIcon' />
					<MatTypography name='name1' className='buttonText' view={this.view} row={rowInfo} />
				</MatButton>
			</div>
		);
	}
	/**
	 * リスト名=APList
	 */
	public onAPListRender(rowInfo: WprRowInfo): any {
		const data: MGeneralCheckInfo = rowInfo.rowData;
		const mgeneral: MGeneral = data.mGeneral;
		const className = (data.checked) ? 'button_white button_white_checked' : 'button_white';
		return (
			<div>
				<MatButton name='b_Show' view={this.view} row={rowInfo} className='button_white' id={String(mgeneral.generalId)}>
					<img src={document_icon} className='documentIcon' />
					<MatTypography name='name1' className='buttonText' view={this.view} row={rowInfo} />
				</MatButton>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
