// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * イッツコムラーニング申込画面用CSS\r\n */\n.AddLearningSelect-password {\n  background: #F7F7F7;\n  padding: 0 15px;\n  border: 1px solid #E3E3E3;\n  border-radius: 4px;\n  height: 44px;\n  width: -webkit-fill-available;\n  outline-color: #6169B8;\n  letter-spacing: 0.1em;\n  text-align: left;\n  font-weight: 500 !important;\n  font-family: \"游ゴシック体\", YuGothic, \"游ゴシック\", \"Yu Gothic\", \"ヒラギノ角ゴ ProN W3\", \"Hiragino Kaku Gothic ProN\", メイリオ, Meiryo, sans-serif !important;\n  color: #484848; }\n", ""]);
// Exports
exports.locals = {
	"password": "AddLearningSelect-password"
};
module.exports = exports;
