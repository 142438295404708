import { GetGradeResponse } from "../../../service/models/dto/mypagerenewal/GetGradeResponse";
import { MGeneral } from "../../../service/models/entity/MGeneral";
import { MCampaign } from "../../../service/models/entity/primary/MCampaign";
import { MCampaignGroup } from "../../../service/models/entity/primary/MCampaignGroup";

/**
 * イッツコムラーニング画面情報
 */
export class learningCourseListModel {
	// public 変数	-----------------------------------------------------------
	public dispCourse: string;						// 画面表示コース名
	public inquiryCourse: string;					// 問い合わせ表示コース名
	public monthAmount: string;						// 月額
	public amountType:string;
	public payAmount: string;						// 料金一覧
	public payUnit: string;							// 料金単位
	public check: boolean;							// 選択
	public loginId: string;							// ログインID
	public contractAmount: string;					// 月額
	public itemName: string;						// 項目名
	public itemData: string;						// 項目データ
	// ------------------------------------------------------------------------
}
