import { MvnoEntryCustomerView } from "../view/mvno/entry/mvno_entry_customer/MvnoEntryCustomerView";

/**
 * メールテンプレートID
*/
export const MAIL_CONSTANTS = [
    {
        view: '名義変更_新',
        customerMail: '1',
        // systemMail: '1',
        inquiryMail: '1'
    },
    {
        view: '名義変更_旧',
        customerMail: '2',
        // systemMail: '1',
        inquiryMail: '2'
    },
    {
        view: 'TVオプション_追加',
        customerMail: '7'
    },
    {
        view: 'TVオプション_追加_仮申込',
        customerMail: '7',
        systemMail: '6',
        inquiryMail: '7'
    },
    {
        view: 'TVオプション_解約',
        customerMail: '8'
    },
    {
        view: 'TVオプション_仮解約',
        customerMail: '8',
        systemMail: '7',
        inquiryMail: '8'
    },
    {
        view: 'Magaine_申込み',
        customerMail: '9',
        systemMail: '8',
        inquiryMail: '9'
    },
    {
        view: 'Magaine_解約',
        customerMail: '10',
        systemMail: '9',
        inquiryMail: '10'
    },
    {
        view: 'かっとびモバイル_解約',
        customerMail: '19',
        systemMail: '16',
        inquiryMail: '17'
    },
    {
        view: 'TV機器変更',
        customerMail: '6',
        systemMail: '5',
        inquiryMail: '6'
    },
    {
        view: 'ネット_追加',
        customerMail: '13',
        systemMail: '12',
        inquiryMail: '13'
    },
    {
        view: 'ネット_追加_フレッツ',
        customerMail: '33',
        systemMail: '12',
        inquiryMail: '13'
    },
    {
        view: 'みるプラス_IP-STB_申込み',
        customerMail: '11',
        systemMail: '10',
        inquiryMail: '11'
    },
    {
        view: 'みるプラス_見放題パックプライム_申込み',
        customerMail: '12',
        systemMail: '11',
        inquiryMail: '12'
    },
    {
        view: 'IH_追加',
        customerMail: '22'
    },
    {
        view: 'IH_追加_レンタル',
        systemMail: '19',
        inquiryMail: '20'
    },
    {
        view: 'IH_追加_購入',
        systemMail: '20',
        inquiryMail: '21'
    },
    {
        view: 'IH_変更',
        customerMail: '23'
    },
    {
        view: 'IH_変更_レンタル',
        systemMail: '21',
        inquiryMail: '22'
    },

    {
        view: 'IH_変更_購入',
        systemMail: '22',
        inquiryMail: '23'
    },
    {
        view: 'NET_変更_直接',
        customerMail: '14'
    },
    {
        view: 'フレッツNET_変更_直接',
        customerMail: '15'
    },
    {
        view: 'フレッツNET_変更_仮申込み',
        customerMail: '16'
    },
    {
        view: 'NET_変更_仮申込み',
        customerMail: '17'
    },
    {
        view: 'NET_変更',
        systemMail: '13',
        inquiryMail: '14'
    },
    {
        view: 'NET_変更_ダミー',
        systemMail: '14',
        inquiryMail: '15'
    },
    {
        view: 'Phone_追加',
        customerMail: '20',
        systemMail: '17',
        inquiryMail: '18'
    },
    {
        view: '着信転送_追加',
        customerMail: '21',
        // systemMail: '18',
        inquiryMail: '19'
    },
    {
        view: 'TV_変更_直接',
        customerMail: '4'
    },
    {
        view: 'TV_変更_仮申込み',
        customerMail: '5',
        systemMail: '3',
        inquiryMail: '4'
    },
    {
        view: 'TV_変更_仮申込みダミー',
        customerMail: '5',
        systemMail: '4',
        inquiryMail: '5'
    },
    {
        view: 'TV_追加',
        customerMail: '3',
        systemMail: '2',
        inquiryMail: '3'
    },
    {
        view: 'IPアドレス_変更',
        customerMail: '18',
        systemMail: '15',
        inquiryMail: '16'
    },
    {
        view: 'NHK_申込み',
        customerMail: '24',
        // systemMail: '23',
        inquiryMail: '24'
    },
    {
        view: 'NHK_変更',
        customerMail: '25',
        // systemMail: '24',
        inquiryMail: '25'
    },
    {
        view: 'NHK_解約',
        customerMail: '26',
        // systemMail: '25',
        inquiryMail: '26'
    },
    {
        view: 'メッシュWi-Fi_申込み',
        customerMail: '27',
        systemMail: '26',
        inquiryMail: '27'
    },
    {
        view: 'とこサポ_追加',
        customerMail: '28',
        systemMail: '27',
        inquiryMail: '28'
    },
    {
        view: '移設_申込み',
        customerMail: '29',
        systemMail: '28',
        inquiryMail: '29'
    },
    {
        view: '工事_予約',
        customerMail: '30',
        systemMail: '29',
        inquiryMail: '30'
    },
    {
        view: '工事_変更',
        customerMail: '31',
        systemMail: '30',
        inquiryMail: '31'
    },
    {
        view: '工事_キャンセル',
        customerMail: '32',
        systemMail: '31',
        inquiryMail: '32'
    },
    {
        view: '端末登録_正常終了',
        customerMail: '34',
        inquiryMail: '33'
    },
    {
        view: '端末登録_エラー',
        customerMail: '34',
        inquiryMail: '34'
    },
    {
        view: 'イッツコムラーニング_申込み',
        customerMail: '35',
        systemMail: '32',
        inquiryMail: '35'
    },
    {
        view: 'イッツコムラーニング_解約',
        customerMail: '36',
        systemMail: '33',
        inquiryMail: '36'
    }
]