import { WprBaseDialogViewInfo } from '../../../../../wpr-framework/view/dialog/WprBaseDialogViewInfo';
import { KattobiAgreeResultData } from './KattobiAgreeDialog';

/**
 * かっとび光同意事項ダイアログ
 */
export class KattobiAgreeDialogView extends WprBaseDialogViewInfo {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('KattobiAgreeDialogView'); }
	// --------------------------------------------------------------------------

	// override プロパティ  ------------------------------------------------------
	/** ダイアログタイトル */
	public get title(): string { return ''; }
	/** バリデーション */
	public get validation(): boolean { return false; }
	/** キャンセルボタン使用フラグ */
	public get useCancelButton(): boolean { return false; }
	/** OKボタン使用フラグ */
	public get useOKButton(): boolean { return false; }
	/** フッタ使用フラグ */
	public get useFooter(): boolean { return false; }
	/** Divider使用フラグ */
	public get useDividers(): boolean { return false; }
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 同意
	 */
	public onAgree(param: any): void {
		const resultData: KattobiAgreeResultData = new KattobiAgreeResultData;
		this.close(true,true);
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('agree', this.onAgree);
	}

	/**
	 * ダイアログ表示通知
	 */
	public onShowDialog() {
		this.refreshView();
	}

	/**
	 * ダイアログOKイベント
	 * ダイアログを閉じる場合は、this.close()を呼び出す
	 */
	// public onDialogOK(): void {
	// 	const resultData: KattobiAgreeResultData = new KattobiAgreeResultData;
	// 	this.close(true,resultData);
	// }
	// --------------------------------------------------------------------------
}
