// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * かっとび光同意事項ダイアログ用CSS\r\n */\n.KattobiAgreeDialog-agreeButton {\n  padding-top: 3rem;\n  text-align: center; }\n\n.KattobiAgreeDialog-title .MuiTypography-body1 {\n  font-weight: bold;\n  font-size: 1.5rem; }\n\n.KattobiAgreeDialog-subTitle {\n  font-size: 1rem;\n  text-decoration: underline 1px;\n  text-underline-offset: 4px; }\n\n.KattobiAgreeDialog-text ul {\n  padding-left: 13px; }\n  @media screen and (max-width: 450px) {\n    .KattobiAgreeDialog-text ul {\n      padding-left: 0px; } }\n\n.KattobiAgreeDialog-text li {\n  font-size: 14px; }\n\n::-webkit-scrollbar {\n  width: 4px !important; }\n  @media screen and (max-width: 450px) {\n    ::-webkit-scrollbar {\n      width: 0px !important; } }\n", ""]);
// Exports
exports.locals = {
	"agreeButton": "KattobiAgreeDialog-agreeButton",
	"title": "KattobiAgreeDialog-title",
	"subTitle": "KattobiAgreeDialog-subTitle",
	"text": "KattobiAgreeDialog-text"
};
module.exports = exports;
