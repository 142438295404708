import { Divider, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { NameChangeCompleteView } from './NameChangeCompleteView';
import styles from './NameChangeComplete.scss';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { WprAnchor } from '../../../../wpr-framework/component/controls/WprAnchor';
import questionnaireIcon from '/shared/image/common/button_questionnaire_on.png';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';

/**
 * 名義変更受付完了用コンポーネント
 */
export class NameChangeComplete extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new NameChangeCompleteView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 名義変更受付完了ビュー情報 */
	public get viewInfo(): NameChangeCompleteView	{ return this.view as NameChangeCompleteView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard'>
				<div className='main'>
					<div className='title_center'>
						<Typography className='title_complete'>お申込みありがとうございます！</Typography>
					</div>
					<div className='titleComplete_normal'>
						<MatTypography name='user' view={this.view} className='title_border'/>
					</div>
					<div className='subText'>
						ご登録のメールアドレスに受付完了のメールをお送りしましたのでご確認ください。
						<br />
						名義変更手続きが完了しましたら、再度メールにてご連絡いたします。
					</div>
					<WprContainer mode='名義変更区分.2,3' view={this.view}>
						<div className='grayBlock'>
							<div className='grayBlockTitle'>
								同居のご家族への契約者変更について
							</div>
							<div className='grayBlockInfo'>
								新名義者様宛に送付いたしました登録案内のメールより、新名義者様の情報入力をお願いいたします。
								<br />
								新名義者様のお申し込みが完了した後、担当部門にて内容を確認させていただきますので、
								<br />
								お早めにお申込みいただきますようよろしくお願いいたします。
							</div>
						</div>
						<div className='grayBlock'>
							<div className='grayBlockTitle'>
								支払いについて
							</div>
							<div className='grayBlockInfo'>
								新名義者様での支払い方法変更の手続きが完了するまでは旧名義人様の支払方法が継続となりますのでご了承ください。
							</div>
						</div>
					</WprContainer>
					<WprContainer mode='ケーブルプラス電話.加入' view={this.view}>
						<div className='grayBlock'>
							<div className='grayBlockTitle'>
								ケーブルプラス電話ご加入またはケーブルプラスSTBご利用のお客様へ
							</div>
							<div className='grayBlockInfo'>
								KDDI株式会社によるケーブルプラス電話サービスの名義変更が必要となりますので以下のご案内をご確認ください。
								<div className='grayBlockInfo'>
									---KDDIサービス名義変更についての今後の流れ---
									<br />
									①KDDI株式会社より改めて名義変更届が契約住所へ送付されます。
									<br />
									②お客様にてご記入いただき、KDDI株式会社宛にご返送願います。
									<br />
									③手続きが整いましたら、すべての名義変更処理が完了となります。
								</div>
							</div>
						</div>
					</WprContainer>
					<WprContainer mode='NHK.加入' view={this.view}>
						<div className='grayBlock'>
							<div className='grayBlockTitle'>
								NHK団体一括支払いについて
							</div>
							<div className='grayBlockInfo'>
								NHKの契約名義も変更希望の場合はNHKへ連絡をお願いいたします。
							</div>
						</div>
					</WprContainer>
					<WprContainer mode='WOWOW.加入' view={this.view}>
						<div className='grayBlock'>
							<div className='grayBlockTitle'>
								WOWOWについて
							</div>
							<div className='grayBlockInfo'>
								WOWOWの契約名義も変更希望の場合はWOWOWへ連絡をお願いいたします。
							</div>
						</div>
					</WprContainer>
					<div className='questionnaire'>
						<WprAnchor name='toQuestionnaire' view={this.view}  >
							<img src={questionnaireIcon} style={{maxWidth: '100%'}} />
						</WprAnchor>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='back' view={this.view} className='completeButton backContInfoList' >
								<NavigateBeforeIcon />
								<span className='screen'>お客さま情報確認・変更へ</span>
							</MatButton>
						</div>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
