import { WprControlInfo } from "../../../../../wpr-framework/view/control/WprControlInfo";
import { WprListData } from "../../../../../wpr-framework/view/deco/WprListData";
import { WprModel } from "../../../../../wpr-framework/view/deco/WprModel";
import { WprDI_Control } from "../../../../../wpr-framework/view/di/WprDI_Control";
import { WprDI_ScopeModel } from "../../../../../wpr-framework/view/di/WprDI_ScopeModel";
import { WprValidError } from "../../../../../wpr-framework/view/validation/WprValidError";
import MypageDispdivcdConstants from "../../../../common/MypageDispdivcdConstants";
import { CryptRijndaelUtil } from "../../../../common/util/CryptRijndaelUtil";
import { learningCourseListModel } from "../../../../models/mypage/itscomLearning/learningCourseListModel";
import { AccessAccountService } from "../../../../service/AccessAccountService";
import { AccesspassModel } from "../../../../service/models/apimodels/AccesspassModel";
import { ViewInfoModel } from "../../../../service/models/apimodels/ViewInfoModel";
import { GetIsContractRadiusAccountPasswordRequest } from "../../../../service/models/dto/mypagerenewal/GetIsContractRadiusAccountPasswordRequest";
import { MypageBaseView } from "../../../base/MypageBaseView";
import { ProgressBarData } from "../../../common/progress_bar/ProgressBarView";
import { ErrorModel } from "../../../../models/mypage/smart/ErrorModel";
import { LearningServiceCancelWizardModel } from "../../../wizard/ItscomLearningCancelWizardFlow";
import { MypageWizardMngr } from "../../../wizard/models/MypageWizardMngr";
import { WprMailAddressValidator } from "../../../../../wpr-framework/view/validation/validator/WprMailAddressValidator";
import { WprHankakuValidator } from "../../../../../wpr-framework/view/validation/validator/WprHankakuValidator";
import { WprViewMode } from "../../../../../wpr-framework/view/deco/WprViewMode";
import { LoginIdCheck } from "./mode/MultiCheck";
import { Contract } from "../../../../models/bss/contract/Contract";
import { Customer } from "../../../../models/bss/customer/Customer";
import { ObjectUtil } from "../../../../common/util/ObjectUtil";
import MypageCodeTypeConstants from "../../../../common/MypageCodeTypeConstants";
import { MGeneral } from "../../../../service/models/entity/MGeneral";
import { WprMaxLengthValidator } from "../../../../../wpr-framework/view/validation/validator/WprMaxLengthValidator";

/**
 * イッツコムラーニング解約申込
 */
export class LearningCancelEntryView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('LearningCancelEntryView'); }
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_LoginId: WprControlInfo	= null;	// ログインID
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;	// 画面ウィザード用モデル
	@WprModel('LearningModel')
	private m_LearningModel: learningCourseListModel	= null;		
	@WprListData('ErrorListModel')
	private m_ErrorListModel: ErrorModel[]	= null;	// エラーモデル
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_LoginIdCheckMode = new LoginIdCheck();
	// --------------------------------------------------------------------------

	// メンバ変数  ---------------------------------------------------------------
	private m_Contract: Contract							= null;	// 契約情報
	private m_Customer:	Customer							= null;	// 顧客情報
	private m_LearningServiceCancelModel: LearningServiceCancelWizardModel  = null;	
	private m_NewMailValidator: WprMailAddressValidator = new WprMailAddressValidator();	//メールアドレスバリデーター	
	private m_MaxLengthValidator: WprMaxLengthValidator = new WprMaxLengthValidator();
	private m_hankakuValidator: WprHankakuValidator = new WprHankakuValidator();
	private m_LearningService: MGeneral[]				= null;
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('dispCourse', 'コース', 'LearningModel');
		this.addConfig('loginId', 'ログインID', 'LearningModel', { hissu: true });
		this.addListConfig('errorMessage', 'エラーメッセージ', 'ErrorListModel');
	}

	/**
	 * アクション登録
 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext, true);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	*/
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			// 遷移情報
			this.m_LearningServiceCancelModel = new LearningServiceCancelWizardModel();
			this.m_LearningModel =  this.m_MypageWizardMngr.getCache().m_LearningModel;
			// 契約情報
			const contractId = this.m_MypageWizardMngr.getParams().contractId;
			if (ObjectUtil.isNotNullOrEmptyStr(contractId)) {
				this.m_Contract = this.m_MypageWizardMngr.getContract(this, contractId);
			}
			// this.m_Contract.serviceCD = '1201000121';
			// 契約中のイッツコムラーニングサービス
			this.m_LearningService = this.m_MypageWizardMngr.getMGenralList(this, MypageCodeTypeConstants.ITSCOM_LEARNING_SERVICE, '').filter(data => data.generalCd === this.m_Contract.serviceCD);
			this.m_LearningModel.dispCourse = this.m_LearningService[0].name2;
			if (this.m_LearningModel.dispCourse.includes('デキタス') && ObjectUtil.isNotNullOrUndefined(this.m_Contract.equipment[0].equipmentID) && ObjectUtil.isNullOrEmptyStr(this.m_LearningModel.loginId)) {
				this.m_LearningModel.loginId = 'its' + this.m_Contract.equipment[0].equipmentID;
			}
			this.m_LearningModel.inquiryCourse = this.m_LearningService[0].name3;
			this.setViewMode('コース', this.m_LearningModel.dispCourse);
			this.refreshView();
		});
	}

	/**
	 * 入力チェック（独自のValidationを実施する場合に使用する）
	 *	エラー追加例：validError.addError('エラーメッセージ');				// 相関チェックなどのエラー
	 * 				 this.setControlError('control', 'error');			 // コントロールのエラー
	 * 				 validError.showErrorMessage.('エラーメッセージ');	   // エラーメッセージ表示
	 * 		※上記のエラーを追加した場合は、アクションは実行しません。
	 * 		※コントロールの値をチェックする場合は、refreshModelは使用しないでください。
	 * 		　コントロールの値をチェックする場合は、コントロールをDIしてvalueを使用してください。
	 * 		　コントロールが双方向のコンバータを使用している場合は、dataValueを使用してください。
	 * @param actionName アクション名
	 * @param validError バリデーションエラー情報
	 * @param row 行情報
	 */
	protected onValid(actionName: string, validError: WprValidError, row?: any): void {
		if (actionName == 'next') {
			this.m_ErrorListModel = new Array();
			let errorFlg:boolean = false;
			if (this.m_LoginId.value) {
				// デジタネログインID：メアド形式チェック
				if (this.m_LearningModel.dispCourse.includes('デジタネ')) {
					if (!this.m_NewMailValidator.checkValidValue(this.m_LoginId.value)) {
						errorFlg = true;
					}
				// デキタスログインID：半角英数字チェック
				} else if(this.m_LearningModel.dispCourse.includes('デキタス')) {
					if (!this.m_hankakuValidator.checkValidValue(this.m_LoginId.value)) {
						errorFlg = true;
					}
				}
				// ログインID：256文字以内チェック
				if (!this.m_MaxLengthValidator.checkValidValue(this.m_LoginId.value, 256)) {
					errorFlg = true;
				}
				if (errorFlg) {
					const model = new ErrorModel();
					model.errorMessage = this.m_LearningModel.dispCourse.includes('デジタネ') ? 'デジタネログインID（メールアドレス）を正しく入力してください' : 'デキタスログインIDを正しく入力してください'
					this.m_ErrorListModel.push(model);
				}
			}
		}
		if (this.m_ErrorListModel.length > 0) {
			validError.addError('Error');
			this.setViewMode('エラー','表示');
			this.setListData('ErrorListModel', this.m_ErrorListModel);
		}
	}

	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------

	public onNext(param: any): void {
		this.refreshModel();
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	*/
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------
}