import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { AddTvComfirmView } from './AddTvComfirmView';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Typography, Divider, Grid, Card, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import { MatTableRow } from '../../../../../wpr-framework/component/controls/material/MatTableRow';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import tvIcon from '/shared/image/common/logo_iTSCOMTV.png';
import { MatSelect } from '../../../../../wpr-framework/component/controls/material/MatSelect';
import styles from './AddTvComfirm.scss';

/**
 * テレビ追加確認用コンポーネント
 */
export class AddTvComfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new AddTvComfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** テレビ追加確認ビュー情報 */
	public get viewInfo(): AddTvComfirmView	{ return this.view as AddTvComfirmView;	}
	// --------------------------------------------------------------------------

	// override メソッド  ----------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('TvOptionListModel', null, this.onTvOptionListModelRender);
		// this.addListRender('CampaignListModel', null, this.onCampaignListModelRender);
	}
	
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='sub-logo'>
						<img src={tvIcon}></img>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view}/>
								<Typography className='title_comment'>以下の内容でよろしければ、「申込み」ボタンをクリックしてください。</Typography>
							</div>
						</div>
						<WprContainer mode='キャンペーンコード.有' view={this.view}>
							<div className='campaignArea'>
								<span className='campaignTitle' style={{ color: '#484848', fontWeight:'700'}}>適用キャンペーン</span><br/>
								<MatTypography name='campaignName' style={{ color: '484848'}} view={this.view} className='campaignText'/>
								<MatTypography name='campaignExplanation' style={{ color: '484848'}} view={this.view}className='campaignText'/>
							</div>
						</WprContainer>
						<div className='feeTable' style={{marginTop:'40px',marginBottom: '0px'}}>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>現在の月額利用料の合計</div>
								</Grid>
								<Grid item xs={12} md={8} className='currentFeeArea courseValue'>
									{/* <span className='feeValue'>
										<MatTypography name='breforeFee' view={this.view}/>
										<span className='feeUnit'>円</span>
									</span> */}
									<Grid item xs={12} md={8} className='feeVal courseValue'>
										<MatTypography name='breforeFee' className='itemUnit unit' view={this.view}/>
										<span className='feeUnit' style={{fontWeight: '500'}}>円</span>
									</Grid>
									<WprContainer mode='お得パック.加入済み' view={this.view}>
										<span className='otokuName'>
											<span className='feeUnit_bold'>(※【 </span>
											<MatTypography name='otokuName' view={this.view} style={{display: 'initial'}}/>
											<span className='feeUnit_bold'>】割引適用)</span>
										</span>
									</WprContainer>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>新しい月額利用料の合計</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<WprContainer mode='新月額利用料.料金' view={this.view}>
										<MatTypography name='afterFee' className='newFeeUnit' view={this.view}/>
										<span className='feeUnit_bold' style={{fontWeight: '500'}}>円</span>
									</WprContainer>
									<WprContainer mode='新月額利用料.※' view={this.view}>
										<Typography className='itemUnit'>※ ご連絡にてご案内致します。</Typography>
									</WprContainer>
								</Grid>
							</Grid>	
						</div>
						<div className='title_space'>
							<div className='itemTitle title_maru'>基本情報</div>
						</div>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>申込みコースの月額</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='amount' className='itemUnit unit' view={this.view}/>
									<span className='feeUnit' style={{fontWeight: '500'}}>円</span>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>申込みコース</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='course' className='itemUnit' view={this.view}/>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>機器の種類</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='terminal' className='itemUnit' view={this.view}/>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>iTSCOM magazine</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='magazine' className='itemUnit' view={this.view}/>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>希望場所</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<div className='flex'>
										<WprContainer mode={'物件.戸建'} view={this.view}>
											<MatTypography name='floor' className='itemUnit' view={this.view} style={{marginRight: '10px'}}/>
										</WprContainer>
										<MatTypography name='location' className='itemUnit' view={this.view}/>
									</div>
									<div>
										<MatTypography name='comment' className='itemUnit' view={this.view}/>
									</div>
								</Grid>
							</Grid>	
						</div>
						<div className='title_space'>
							<div className='itemTitle title_maru'>オプションチャンネル</div>
						</div>
						<WprContainer mode='オプション.PC' view={this.view}>
							<Card className='optionList'>
								<TableContainer>
									<Table className = 'top'>
										<colgroup>
											<col style={{width:'35%'}}/>
											<col style={{width:'65%'}}/>
										</colgroup>
										<TableBody className='radius'>
											<TableRow className='hedderCell'>
												<TableCell align='center' className='border_right' rowSpan={this.state.data.sentenceData} style={{borderBottom: 'none'}}>
													<Typography variant='body2' className='font_bold' style={{fontSize:'15px'}}>チャンネル名</Typography>
												</TableCell>
											</TableRow>
											{this.renderList('TvOptionListModel')}
										</TableBody>
									</Table>
								</TableContainer>
							</Card>
						</WprContainer>
						{/* SP用 */}
						<WprContainer mode='オプション.SP' view={this.view}>
							<Card className='optionListSP'>
								<TableContainer>
									<Table className = 'topSP'>
										<TableBody className='radiusSP'>
											<TableRow className='hedderCellSP'>
												<TableCell align='center'>
													<Typography variant='body2' className='font_bold' style={{fontSize:'15px'}}>チャンネル名</Typography>
												</TableCell>
											</TableRow>
											{this.renderList('TvOptionListModel')}
										</TableBody>
									</Table>
								</TableContainer>
							</Card>
						</WprContainer>
						{/* #72624対応 */}
						<WprContainer mode={'衛星.表示'} view={this.view}>
							<div className='title_space'>
								<div className='title_left'>
									<Typography className='title_comment'>
										■衛星劇場・東映チャンネル　新規お申し込みキャンペーン■<br/>
										<b style={{fontSize:'17px'}}>2024/12/1～2025/1/31</b>の期間中に衛星劇場にご契約いただき、1ヵ月分の利用料金で最大2ヵ月間視聴可能となるキャンペーンを実施します。
									</Typography>
									<Typography className='title_comment'>
										※キャンペーン適用期間終了後は、衛星劇場月額利用料金（2,200円／税込）にて自動継続となります。<br/>
										※<b style={{fontSize:'17px'}}>2024/12/1～2025/1/31</b>の間に新たに視聴を開始頂き、お申し込みの翌月もご契約頂くことが条件となります。<br/>
										　お申し込み当月にご解約いただいた場合には、適用外となります。<br/>
										※2024年11月30日時点で該当チャンネルをご契約頂いている場合は適用対象外となりますのでご注意ください。
									</Typography>
								</div>
							</div>
						</WprContainer>
						<WprContainer mode={'東映.表示'} view={this.view}>
							<div className='title_space'>
								<div className='title_left'>
									<Typography className='title_comment'>
										■衛星劇場・東映チャンネル　新規お申し込みキャンペーン■<br/>
										<b style={{fontSize:'17px'}}>2024/12/1～2025/1/31</b>の期間中に東映チャンネルにご契約いただき、1ヵ月分の利用料金で最大2ヵ月間視聴可能となるキャンペーンを実施します。
									</Typography>
									<Typography className='title_comment'>
										※キャンペーン適用期間終了後は、東映チャンネル月額利用料金（1,650円／税込）にて自動継続となります。<br/>
										※<b style={{fontSize:'17px'}}>2024/12/1～2025/1/31</b>の間に新たに視聴を開始頂き、お申し込みの翌月もご契約頂くことが条件となります。<br/>
										　お申し込み当月にご解約いただいた場合には、適用外となります。<br/>
										※2024年11月30日時点で該当チャンネルをご契約頂いている場合は適用対象外となりますのでご注意ください。
									</Typography>
								</div>
							</div>
						</WprContainer>
						{/*  */}
						<div style = {{marginBottom: '30px'}}>
							<ul className='ul_kome attention_fontSize' style={{marginBottom: '0'}}>
								<li>
									表示されている月額は定価となります。実際の請求金額とは異なる場合があります。
								</li>
								<li>
									合計月額には割引も適用されています。
								</li>
								<li>
									以下の組み合わせを選択された場合、お得パック割引対象外となりますのでご注意ください。
								</li>
							</ul>
							<ul className='ul_ten attention_fontSize' style={{marginTop: '0', marginBottom: '0'}}>
								<li>
									お得パック2年プラン対象外：(ビッグまたはαエース)＋STB
								</li>
								<li>
									お得パック3年プラン対象外：(ビッグまたはαエース)＋(HitPotまたはSTB)
								</li>
								<li>
									お得パックトリプル3年プラン対象外：ミニ＋(HitPotまたはSTB)
								</li>
							</ul>
							<ul className='ul_kome attention_fontSize' style={{marginBottom: '0', marginTop: '0'}}>
								<li>
									長期契約プランご利用中のお客さまにつきましては、2024年9月以降に長期契約プラン以外のサービスを変更または追加された場合、システム仕様上一時的に、一部サービスにまとめて割引が適用された状態での明細表示となり、まとめて割引そのものの明細は非表示となります。2024年9月価格改定におきましては1世帯あたり360円（税込396円）以上の増額はございません。
								</li>
							</ul>
						</div>
						<div className='title_space'>
							<div className='itemTitle title_maru'>作業の希望日時</div>
						</div>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>第一希望</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue value'>
									<MatTypography name='desiredDate1' view={this.view} className='itemUnit' style={{marginRight: '20px'}}/>
									<MatSelect name='selectTime1' vm='時間帯' view={this.view} className='itemSelect'/>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>第二希望</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue value'>
									<MatTypography name='desiredDate2' view={this.view} className='itemUnit' style={{marginRight: '20px'}}/>
									<MatSelect name='selectTime2' vm='時間帯' view={this.view} className='itemSelect'/>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>第三希望</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue value'>
									<MatTypography name='desiredDate3' view={this.view} className='itemUnit' style={{marginRight: '20px'}}/>
									<MatSelect name='selectTime3' vm='時間帯' view={this.view} className='itemSelect'/>
								</Grid>
							</Grid>	
						</div>
						<div className='title_space'>
							<div className='itemTitle title_maru'>工事費</div>
						</div>
						<div className='feeTable'>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>工事費</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='constrAmount' view={this.view} className='itemUnit'/>
									<MatTypography name='payUnit' className='itemUnit' view={this.view}/>
								</Grid>
							</Grid>
						</div>
						<div className='feeTable inputArea' style={{marginTop: '30px'}}>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>前日連絡先</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='beforeContact' className='itemUnit' view={this.view} />
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>当日連絡先</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='sameDayContact' className='itemUnit' view={this.view} />
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>当日立ち合い者</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='name' className='itemUnit' view={this.view} />
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>リフォーム</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='reformDisp' view={this.view} className='itemUnit'/>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>セキュリティ会社</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='securityDisp' view={this.view} className='itemUnit'/>
								</Grid>
							</Grid>
						</div>
						{/* <WprContainer mode='初期費用キャンペーン.有' view={this.view}>
							<div className='title_space'>
								<div className='itemTitle title_maru'>キャンペーン</div>
							</div>
							<div className='feeTable'>	
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>内容</div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue'>
										<MatTypography name='campaignName' view={this.view} className='itemUnit'/>
									</Grid>
								</Grid>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>工事・配送費</div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue'>
										-<MatTypography name='campaignDiscount' view={this.view} className='itemUnit'/><span className='itemUnit'>円</span>
									</Grid>
								</Grid>
							</div>
						</WprContainer> */}
						<WprContainer mode='ケーブルプラスSTB.表示' view={this.view}>
							<div className='title_space'>
								<div className='itemTitle title_maru'>ケーブルプラスSTBご利用の場合は、
									<a href='https://www.itscom.co.jp/info/covenant/gjslkq0000003us8-att/cabletv_stb-2.pdf' target = '_blank'>「Google利用規約・au ID利用規約・ウィルスバスターfor au仕様許諾契約書」</a>への同意が必要です。
								</div>
							</div>
							<div className='title_center' style={{marginTop:'30px'}}>
								<div className='title_center'>
									<MatCheckBox name='confirm' label='承諾する' view={this.view}/>
								</div>
							</div>
						</WprContainer>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								この内容で申込む
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=TvCourseListModel
	 */
	public onTvOptionListModelRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow view={this.view} row={rowInfo}>
				<TableCell className='bodyCell'>
					<MatTypography name='option' view={this.view} row={rowInfo}  style={{fontSize: '14px', fontWeight: '500'}}/>
				</TableCell>
			</MatTableRow>
		);
	}
	/**
	 * リスト名=CampaignListModel
	 */
	// public onCampaignListModelRender(rowInfo: WprRowInfo): any {
	// 	return (
	// 		<MatTypography name='campaignName' style={{ color: '484848'}} view={this.view} row={rowInfo} className='campaignText'/>
	// 	);
	// }
	// --------------------------------------------------------------------------
}
