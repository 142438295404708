import { WprBaseViewModeModeInfo } from "../../../../../../wpr-framework/mode/WprBaseViewModeModeInfo";
import { WprBaseViewMultiModeInfo } from "../../../../../../wpr-framework/mode/WprBaseViewMultiMode";
import { WprMultiMode } from "../../../../../../wpr-framework/mode/WprMultiMode";
import { ObjectUtil } from "../../../../../common/util/ObjectUtil";
import { AddLearningAgreeView } from "../AddLearningAgreeView";


/**
 * 約款モード
 */
export class AgreeMode extends WprBaseViewMultiModeInfo<AddLearningAgreeView> {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('AgreeMode');
	}
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('確認事項全チェック済');
		list.push('確認事項全チェック未');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('next', { enabled: [ { mode: '確認事項全チェック済', value: true }, { mode: 'other', value: false } ] });
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {	
		const checkConfirmMode = this.getViewMode('確認事項');
		if (ObjectUtil.isNotNullOrUndefined(checkConfirmMode)) {
			newMode.addMode('確認事項全チェック済');
		} else {
			newMode.addMode('確認事項全チェック未');
		}
	}
	// --------------------------------------------------------------------------
}
