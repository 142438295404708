import { MypageWizardFlow } from './MypageWizardFlow';
import { MypageBaseView } from '../base/MypageBaseView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { WizardModel } from '../../models/WizardModel';
import { NameChangeAppModel } from '../../service/models/apimodels/NameChangeAppModel';
import { NameChangeInfo } from '../../service/models/apimodels/NameChangeInfo';
import { NameChangePayment } from '../../service/models/apimodels/NameChangePayment';
import { NameChangeNewInformationView } from '../name_change/name_change_new_information/NameChangeNewInformationView';
import { NameChangeNewFormView } from '../name_change/name_change_new_form/NameChangeNewFormView';
import { NameChangeNewIdentificationView } from '../name_change/name_change_new_identification/NameChangeNewIdentificationView';
import { NameChangeNewConfirmView } from '../name_change/name_change_new_confirm/NameChangeNewConfirmView';
import { NameChangeNewCompleteView } from '../name_change/name_change_new_complete/NameChangeNewCompleteView';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import { MCommon } from '../../service/models/entity/primary/MCommon';

/**
 * 名義変更ウィザードクラス
 */
export class NameChangeNewWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();

		this.m_StartViewName = new NameChangeNewInformationView().name;
        
		// 新名義人パターン
		this.m_DefaultWizardFlow = new Array();
        this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Cust_mod/Continfo_disp/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: '新名義人受付', viewName: this.m_StartViewName, path: 'name_change_new_information'});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: '名義変更入力', viewName: new NameChangeNewFormView().name, path: 'name_change_new_form'});
		this.m_DefaultWizardFlow.push({stepNum: 3, title: '本人確認', viewName: new NameChangeNewIdentificationView().name, path: 'name_change_new_identification'});
		this.m_DefaultWizardFlow.push({stepNum: 4, title: '申込み内容確認', viewName: new NameChangeNewConfirmView().name, path: 'name_change_new_confirm'});
		this.m_DefaultWizardFlow.push({stepNum: 5, title: '申込み完了', viewName: new NameChangeNewCompleteView().name, path: 'name_change_new_complete'});
    }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
        if (viewName === this.m_StartViewName)
            return this.constructor.name;
        return null;
	}
		
	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistInfoRequest{
		const req: RegistInfoRequest = new RegistInfoRequest();
		const cacheData = mngr.getCache();
		const mailconstants = MAIL_CONSTANTS.find (mail => {
			return mail.view === '名義変更_新';
		});
		// お客様メール
		req.customerId = cacheData.nameChangeAppInfo.customerId;
		req.customerMailId = mailconstants.customerMail;
		// お問い合わせ登録
		req.inquiryId = mailconstants.inquiryMail;
		req.systemInfo = new InquiryMailInfo();
		req.systemInfo.customerId = cacheData.nameChangeAppInfo.customerId;
		req.systemInfo.enteredName = cacheData.nameChangeAppInfo.nameChangeInfo.newName;
		req.systemInfo.enteredNameKana = cacheData.nameChangeAppInfo.nameChangeInfo.newNameKana;
		req.systemInfo.relationDispName = cacheData.nameChangeAppInfo.nameChangeInfo.relationShip;
		req.systemInfo.sex = cacheData.nameChangeAppInfo.nameChangeInfo.gender;
		const birthday = cacheData.nameChangeAppInfo.nameChangeInfo.birthday;
		req.systemInfo.birthday = birthday.substring(0,4) + '年' + birthday.substring(4,6) + '月' + birthday.substring(6,8) + '日';
		req.systemInfo.enteredTel1 = cacheData.nameChangeAppInfo.nameChangeInfo.phoneNumber1;
		req.systemInfo.enteredTel2 = cacheData.nameChangeAppInfo.nameChangeInfo.phoneNumber2;
		req.systemInfo.newMail = cacheData.nameChangeAppInfo.nameChangeInfo.newMail;
		if (cacheData.nameChangeAppInfo.nameChangePayment.changePaymentFlg === true) {
			if (cacheData.nameChangeAppInfo.nameChangePayment.creditFlg === true) {
				req.systemInfo.paymentDispname = '新しい支払方法 クレカ';
				req.systemInfo.changedAddr = '';
			} else {
				if (cacheData.nameChangeAppInfo.nameChangePayment.contractAddressFlg === true) {
					req.systemInfo.paymentDispname = '新しい支払方法 口座 契約住所へ送付';
				} else {
					req.systemInfo.paymentDispname = '新しい支払方法 口座 異なる住所へ送付';
				}
				req.systemInfo.changedAddr = cacheData.nameChangeAppInfo.nameChangePayment.deliveryAddress;
			}
		} else {
			req.systemInfo.paymentDispname = '従来の支払方法';
			req.systemInfo.changedAddr = '';
		}
		const kbn = cacheData.nameChangeAppInfo.nameChangeInfo.nameChangeKbn;
		if(kbn === '1')  {
			req.systemInfo.nameChangeType = '苗字の変更（改姓）';
			req.systemInfo.nameChangeKbn = '改称';
		}
		if(kbn === '2') {
			req.systemInfo.nameChangeType = 'ご契約者さま逝去';
			req.systemInfo.nameChangeKbn = '承継';
		}
		if(kbn === '3') {
			req.systemInfo.nameChangeType = '同居のご家族への契約者変更';
			req.systemInfo.nameChangeKbn = '譲渡';
		}
		req.systemInfo.escalationFlg = true;
		return req;
	}

	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.contractFlg = true;
		req.commonList = new Array();
		req.commonList.push(new MCommon('WOWOWサービス', 'opt' , 'digital_wowow'));
		req.commonList.push(new MCommon('NHKサービス', 'srvother' , 'nhk'));
		return req;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
     public checkFlowBreak(mngr: MypageWizardMngr): string {
		let test: boolean = false;
		if (test) {
			return '/error';
		}
		return null;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): NameChangeApiModel {
		let cacheData = new NameChangeApiModel();
		cacheData.nameChangeAppInfo = new NameChangeAppModel();
		cacheData.nameChangeAppInfo.nameChangeInfo = new NameChangeInfo();
		cacheData.nameChangeAppInfo.nameChangePayment = new NameChangePayment();
        cacheData.nameChangeAppInfo.nameChangePayment.changePaymentFlg = false;
        cacheData.nameChangeAppInfo.nameChangePayment.creditFlg = true;
        cacheData.nameChangeAppInfo.nameChangePayment.contractAddressFlg = true;
        cacheData.nameChangeAppInfo.paymentBuilding = '';
        cacheData.nameChangeAppInfo.paymentRoomNum = '';
		return cacheData;
	}
	
}

/**
 * 画面ウィザードAPI用モデル
 */
 export class NameChangeApiModel {
	// public 変数	-----------------------------------------------------------
		public nameChangeAppInfo: NameChangeAppModel	= null;
	// ------------------------------------------------------------------------
}


