import { WprModel } from "../../../../../wpr-framework/view/deco/WprModel";
import { WprDI_ScopeModel } from "../../../../../wpr-framework/view/di/WprDI_ScopeModel";
import { ObjectUtil } from "../../../../common/util/ObjectUtil";
import { learningCourseListModel } from "../../../../models/mypage/itscomLearning/learningCourseListModel";
import { MagazineModel } from "../../../../service/models/apimodels/MagazineModel";
import { MypageBaseView } from "../../../base/MypageBaseView";
import { ProgressBarData } from "../../../common/progress_bar/ProgressBarView";
import { LearningServiceCancelWizardModel } from "../../../wizard/ItscomLearningCancelWizardFlow";
import { MypageWizardMngr } from "../../../wizard/models/MypageWizardMngr";



/**
 * イッツコムラーニング解約確認画面
 */
export class LearningCancelConfirmView extends MypageBaseView {
	
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('LearningCancelConfirmView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr	= null;	// 画面ウィザード用モデル
	@WprModel('LearningModel')
	private m_LearningModel: learningCourseListModel	= null;		
	// --------------------------------------------------------------------------

	// メンバ変数  ---------------------------------------------------------------
	private m_LearningServiceCancelModel: LearningServiceCancelWizardModel  = null;		
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('dispCourse', 'コース', 'LearningModel');
		this.addConfig('loginId', 'ログインID', 'LearningModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			// 遷移情報
			this.m_LearningModel =  this.m_MypageWizardMngr.getCache().m_LearningModel;
			this.setViewMode('コース',this.m_LearningModel.dispCourse);
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------
	
	// アクション  ---------------------------------------------------------------
	/**
	 * 申込む
	*/
	public onNext(param: any): void {
		this.refreshModel();
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}

	// --------------------------------------------------------------------------
}
