import { WprBaseViewMultiModeInfo } from '../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../wpr-framework/mode/WprMultiMode';
import { S_01_004View } from '../S_01_004View';
import { WprDI_Control } from '../../../../../wpr-framework/view/di/WprDI_Control';
import { WprControlInfo } from '../../../../../wpr-framework/view/control/WprControlInfo';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';

/**
 * 閲覧確認
 */
export class ReadCheckMode extends WprBaseViewMultiModeInfo<S_01_004View> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_c_Confirm: WprControlInfo	= null;	// 契約内容に同意
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('ReadCheckMode');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('確認事項全チェック済');
		list.push('PDFスクロール済');
		list.push('PDFスクロール未');
		list.push('契約内容に同意済');
		list.push('未同意');
		list.push('かっとび光同意済');
		list.push('かっとび光同意未');
		list.push('押下済み');
		list.push('未押下');
		// list.push('非活性2');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('b_Next', { enabled: [ { mode: '契約内容に同意済&確認事項全チェック済', value: true }, { mode: 'other', value: false } ] });
		// this.addControl('b_DialogPDF', { check: true });
		this.addControl('c_Confirm', { check: true, enabled: [ { mode: 'PDFスクロール済', value: true }, { mode: 'other', value: false } ] });
		this.addControl('b_DialogPDF', { class: [ { mode: 'PDFスクロール済', class: 'button_white button_white_checked' }, { mode: 'other', class: 'button_white' } ] });
		this.addControl('k_Show', { class: [ { mode: 'かっとび光同意済', class: 'button_white button_white_checked' }, { mode: 'other', class: 'button_white' } ] });
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		const kattobiHyouzi = this.getViewMode('かっとび光');	
		const checkConfirmMode = this.getViewMode('確認事項');
		if (kattobiHyouzi !== '表示') {
			if (ObjectUtil.isNotNullOrUndefined(checkConfirmMode)) {
				newMode.addMode('確認事項全チェック済');
			}
		} else {
			if (this.getViewMode('かっとび光同意') === '済') {
				newMode.addMode('かっとび光同意済');
			}
			if (ObjectUtil.isNotNullOrUndefined(checkConfirmMode) && this.getViewMode('かっとび光同意') === '済') {
				newMode.addMode('確認事項全チェック済');
			}
		}
		
		
		const pdfCheck = this.getViewMode('PDFスクロール');
		if (ObjectUtil.isNotNullOrUndefined(pdfCheck)) {
			// newMode.addMode('PDF表示済');
			newMode.addMode('PDFスクロール' + pdfCheck);	// PDFスクロール済,PDFスクロール未
		}

		if (this.m_c_Confirm.value) 
			newMode.addMode('契約内容に同意済');
		
		if (!newMode.getModeCount()) 
			newMode.addMode('未同意');
		// if ( mode2 !== null &&  typeof mode2 !== 'undefined')
		// 	newMode.addMode(mode2);		// 活性2,非活性2
		// if (this.getViewMode('mode1') === '活性1' && newMode.includes('活性3') )
		// 	newMode.addMode('活性4');
		// newMode.addMode('未同意');
	}
	// --------------------------------------------------------------------------
}
