import React, { ReactNode } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Divider, Typography, TableCell, Grid, Table, TableHead, TableRow, TableBody } from '@material-ui/core';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import { MatTableContainer } from '../../../../../wpr-framework/component/controls/material/MatTableContainer';
import { MatTableRow } from '../../../../../wpr-framework/component/controls/material/MatTableRow';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import logoNet from '/shared/image/common/logo_iTSCOMNET.png';
import nextIcon from '/shared/image/common/next_icon_orange.png';
import { AddLearningSelectView } from './AddLearningSelectView';
import { WprInput } from '../../../../../wpr-framework/component/controls/WprInput';
import styles from './AddLearningSelect.scss';
import errorIcon from '/shared/image/common/icon_error.png';

/**
 * イッツコムラーニング申込画面用コンポーネント
 */
export class AddLearningSelect extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new AddLearningSelectView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** [サービス追加（変更）申込]コース内容の選択ビュー情報 */
	public get viewInfo(): AddLearningSelectView	{ return this.view as AddLearningSelectView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	 */
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('LearningList', 'PC', this.onCourseListPcModelRender);
		this.addListRender('ErrorListModel', null, this.onErrorListModelRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard'>
				<div className="pageHeader stepFlow">
					<ProgressBar name='progressBar' view={this.view} />
				</div>
				<div className='sub-logo2'>
					
				</div>
				<div className='main net'>
					<div className='pageTitle'>
						<div className='title_left'>
							<MatTypography name='title' className='title_text' view={this.view}/>
							<Typography className='title_sentence'>ご希望のコースを選択してください。</Typography>
						</div>
						<WprContainer mode='エラー.表示' view={this.view}>
							<div className='errorArea'>
								<span className='parallel'>
									<img src={errorIcon} alt='' className='x_img'/>
									<div className='errorList'>
										{this.renderList('ErrorListModel')}
									</div>
								</span>
							</div>
						</WprContainer>
						<Grid container>
							<Grid item xs={12}>
								<MatTableContainer listName={'LearningList'} view={this.view} noDataUseHeader noDataMessage=' '>
									<Table className = 'optionSelsctPcTable'>
										<colgroup>
											<col style={{width: '20%'}}/>
											<col style={{width: '45%'}}/>
											<col style={{width: '35%'}}/>
										</colgroup>
										<TableHead className='hedderrow'>
											<TableRow className='toptable'>
												<TableCell align='center' className='border_right font_bold'>選択</TableCell>
												<TableCell align='center' className='border_right font_bold'>コース名</TableCell>
												<TableCell align='center' className='border_right font_bold' style={{ borderRight: 'none'}}>料金</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.renderList('LearningList', 'PC')}
											<WprContainer mode='データなし.表示' view={this.view}>
												<TableRow>
													<TableCell className='non_list' colSpan={5}>データが存在しませんでした</TableCell>
												</TableRow>
											</WprContainer>
										</TableBody>
									</Table>
								</MatTableContainer>
							</Grid>
						</Grid>
						<WprContainer mode='ログインID.表示' view={this.view}>
							<div className='feeTable' style = {{marginBottom: '0px'}}>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>デジタネログインID
											<span className='txtRequired'>*</span>
										</div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal2 courseValue'>
										<WprInput placeholder='例:*****@***.itscom.net' name='loginId' className='password' view={this.view} style={{ fontWeight: '500', fontSize: '14px'}}/>
									</Grid>
								</Grid>
							</div>
							<div className='attention_fontSize' style = {{marginTop: '12px'}}>
							<ul className='ul_kome'>
								<li>
									デジタネ無料体験にてご登録いただいたメールアドレスをご入力ください
								</li>
							</ul>
						</div>
						</WprContainer>
					</div>
				</div>
				<Divider className='select1_underSpace'/>
				<div className='pageFooter footer-space'>
					<div>
						<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
							確認
							<NavigateNextIcon />
						</MatButton>
					</div>
					<div>
						<MatButton name='back' view={this.view} className='backButton' >
							<NavigateBeforeIcon />
							戻る
						</MatButton>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=CourseListModel
	 * PC用
	 */
	public onCourseListPcModelRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow view={this.view} row={rowInfo}>
				<TableCell align='center' className='border_right service'>
					<MatCheckBox name='check' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='border_right service'>
					<MatTypography name='dispCourse' className='courseSize' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='border_right service' style={{ borderRight: 'none'}}>
					<MatTypography name='amountType' className='courseFee' view={this.view} row={rowInfo}/>
					<MatTypography name='payAmount' className='courseFee' view={this.view} row={rowInfo}/>
					<MatTypography name='payUnit' className='courseUnit' view={this.view} row={rowInfo}/>
				</TableCell>
			</MatTableRow>
		);
	}

	/**
	 * リスト名=ErrorListModel
	 */
	public onErrorListModelRender(rowInfo: WprRowInfo): any {
		return (
			<div>
				<MatTypography name='errorMessage' view={this.view} row={rowInfo}/>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
