import { Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ReactNode } from 'react';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';
import { WprSpan } from '../../../../wpr-framework/component/controls/WprSpan';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { MatCheckBox } from '../../../../wpr-framework/component/controls/material/MatCheckBox';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ProgressBar } from '../../common/progress_bar/ProgressBar';
import { AddPhoneSelectView } from './AddPhoneSelectView';
import phoneIcon from '/shared/image/common/logo_iTSCOMphone.png';
import orangeIcon from '/shared/image/common/icon_Help_bgLigntOrange.png';
import errorIcon from '/shared/image/common/icon_error.png';
import nextIcon from '/shared/image/common/next_icon_orange.png';
import styles from './AddPhoneSelect.scss';
import { MatSelect } from '../../../../wpr-framework/component/controls/material/MatSelect';
import { MatTextField } from '../../../../wpr-framework/component/controls/material/MatTextField';
import { MatDatePicker } from '../../../../wpr-framework/component/controls/material/MatDatePicker';
import { WprErrorContainer } from '../../../../wpr-framework/component/controls/WprErrorContainer';
import { WprViewError } from '../../../../wpr-framework/component/controls/WprViewError';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import { MatTableContainer } from '../../../../wpr-framework/component/controls/material/MatTableContainer';
import { MatTableRow } from '../../../../wpr-framework/component/controls/material/MatTableRow';
import { MatRadio } from '../../../../wpr-framework/component/controls/material/MatRadio';
import virticalArrow from '/shared/image/common/vertical_arrow.png';
/**
 * 追加サービス申込み用コンポーネント
 */
export class AddPhoneSelect extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new AddPhoneSelectView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 追加サービス申込みビュー情報 */
	public get viewInfo(): AddPhoneSelectView { return this.view as AddPhoneSelectView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('OptionListModel', 'PC', this.onOptionPcListModelRender);
		this.addListRender('OptionListModel', 'SP', this.onOptionSpListModelRender);
		this.addListRender('ErrorModelList', null, this.onErrorModelListRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='pageCard'>
					<div className='pageHeader stepFlow'>
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='sub-logo'>
						<img src= {phoneIcon}></img>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view} />
							</div>
						</div>
						<WprContainer mode='エラー.表示' view={this.view}>
							<div className='errorArea'>
								<span className='parallel'>
									<img src={errorIcon} alt='' className='x_img'/>
									<div className='errorList'>
									{this.renderList('ErrorModelList')}
									</div>
								</span>
							</div>
						</WprContainer>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle' >
									<div>申込みコース</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatCheckBox name='courseCheck' color='primary' className='checkbox_size' view={this.view}/>
									<WprSpan name={'course'} view={this.view} className='itemUnit'></WprSpan>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>機器のご提供方法</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatCheckBox name='equipmentProvidingCheck' color='primary' className='checkbox_size' view={this.view} />
									<WprSpan name={'equipmentProviding'} view={this.view} className='itemUnit'/>
								</Grid>
							</Grid>
							<WprContainer mode='お得パック.なし' view={this.view}>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>お得パック
											<a href='https://www.itscom.co.jp/service/profit/' target='_blank'><img src= {orangeIcon} alt='' style={{marginLeft: '3px'}}/></a>
										</div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue'>
										{/* <span className='itemUnit'> */}
											<MatCheckBox name='yesOtoku' color='primary' className='checkbox_size' view={this.view}/>
											<span className='itemUnit'>希望する</span>
											<MatCheckBox name='noOtoku' color='primary' className='checkbox_size' view={this.view}/>
											<span className='itemUnit'>希望しない</span>
										{/* </span> */}
									</Grid>
								</Grid>
							</WprContainer>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>
										希望場所
										<span className='txtRequired'>*</span>
									</div>
								</Grid>
								<Grid item xs={12} md={8} className='courseValue value' style={{padding: '0.6rem'}}>
									<div className='placeArea'>
										<WprContainer mode='階数.表示' view={this.view}>
											<MatSelect placeholder='(階数)' name='floor' vm='floor' view={this.view} style={{width: '125px'}}/>
										</WprContainer>
										<MatSelect placeholder='(場所)' name='location' vm='location' view={this.view} style={{width: '175px'}}/>
									</div>
									<div><MatTextField placeholder='(場所)' name='comment' view={this.view} style={{width: '95%'}}></MatTextField></div>
								</Grid>
							</Grid>
						</div>
						<div>
							<ul className='ul_kome'>
								<li>
									お客さまのご住所および建物の設備状況によっては、ご利用になれない場合がございます。
								</li>
							</ul>
						</div>
						<div className='title_space'>
							<div className='itemTitle title_maru'>固定電話のご利用状況についてお答えください<span className='txtRequired'>*</span></div>
						</div>
						<div className='input_gray'>
							<MatRadio name='phoneNumerSelect' value='1' label='新しい番号を作る' color='primary' id='phoneNumer_1' view={this.view}/>
							<MatRadio name='phoneNumerSelect' value='2' label='今ある番号を使う' color='primary' id='phoneNumer_2' view={this.view}/>
						</div>
						<WprContainer mode='固定電話情報.表示' view={this.view}>
							<div className='center'>
								<img src={virticalArrow} alt='' className='virArrow'/>
							</div>
							<div className='input_gray'>
								<Grid container>
									<Grid item xs={12} md={4}>
										<MatRadio name='lineType' value='1' label='NTTアナログ電話' color='primary' id='lineType_1' view={this.view}/>
									</Grid>
									<Grid item xs={12} md={4}>
										<MatRadio name='lineType' value='2' label='ホームプラス電話' color='primary' id='lineType_2' view={this.view}/>
									</Grid>
									<Grid item xs={12} md={4}>
										<MatRadio name='lineType' value='3' label='NTT光' color='primary' id='lineType_3' view={this.view}/>
									</Grid>
								</Grid>
								<Grid container>
									<Grid item xs={12} md={4}>
										<MatRadio name='lineType' value='4' label='auひかり' color='primary' id='lineType_4' view={this.view}/>
									</Grid>
									<Grid item xs={12} md={4}>
										<MatRadio name='lineType' value='5' label='NTTISDN' color='primary' id='lineType_5' view={this.view}/>
									</Grid>
									<Grid item xs={12} md={4}>
										<MatRadio name='lineType' value='6' label='ソフトバンク' color='primary' id='lineType_6' view={this.view}/>
									</Grid>
								</Grid>
								<Grid container>
									<MatRadio name='lineType' value='7' label='その他' color='primary' id='lineType_7' view={this.view}/>
									<MatTextField name='otherLineComment' placeholder='会社名' view={this.view} className='text'/>
								</Grid>
							</div>
							<div className='center'>
								<img src={virticalArrow} alt='' className='virArrow'/>
							</div>
							<div className='input_gray'>
								<div>ご利用中のお電話番号をご入力ください。(半角ハイフンなし)<span className='txtRequired'>*</span></div>
								<MatTextField name='phoneNumber' placeholder='例：036***0***' view={this.view} className='text'/>
							</div>
							<div className='center'>
								<img src={virticalArrow} alt='' className='virArrow'/>
							</div>
							<div className='input_gray'>
								<div>ご利用中サービスのご契約者様名をご入力ください。<span className='txtRequired'>*</span></div>
								<div className='nameTitle'><span className='space'>カナ</span><MatTextField name='contractorNameKana' view={this.view} className='text'/></div>
								<div className='nameTitle'><span className='space'>漢字</span><MatTextField name='contractorNameKanji' view={this.view} className='text'/></div>
							</div>
						</WprContainer>
						<WprContainer mode='au携帯電話.表示' view={this.view}>
							<div className='title_space'>
								<div className='itemTitle title_maru'>auの携帯電話をご利用中ですか？<span className='txtRequired'>*</span></div>
							</div>
							<div className='input_gray'>
								<MatRadio name='auPhoneUsing' value='1' label='利用している' color='primary' id='auPhone_1' view={this.view}/>
								<span className='phoneDisplay'>
									<MatRadio name='auPhoneUsing' value='2' label='利用していない' color='primary' id='auPhone_2' view={this.view}/>
								</span>
							</div>
						</WprContainer>
						<WprContainer mode='au携帯電話入力欄.表示' view={this.view}>
							<div className='center'>
								<img src={virticalArrow} alt='' className='virArrow'/>
							</div>
							<div className='input_gray'>
								<div>ご利用中の携帯電話をご入力ください。(半角ハイフンなし)<span className='txtRequired'>*</span></div>
								<MatTextField name='auPhoneNumber' placeholder='例：036***0***' view={this.view} className='text'/>
							</div>
						</WprContainer>
						<WprContainer mode='電話サービス設備.表示' view={this.view}>
							<div className='title_space'>
								<div className='itemTitle title_maru'>電話サービス 設備の確認</div>
							</div>
							<div style={{paddingTop: '10px'}}>
								<span className='ques_icon'>Q1</span>
								<span className='ques_text'>配線状況:モジュラージャックを複数箇所利用していますか？</span>
							</div>
							<div className='checkArea'>
								<div>
									<MatCheckBox name='multiModularJacks' color='primary' view={this.view} className='check'/>
								</div>
								<span className='checklabel'>
									<WprContainer mode='配線状況.未選択' view={this.view}>
										はい
									</WprContainer>
									<WprContainer mode='配線状況.選択' view={this.view}>
										複数台ご利用の場合は、追加工事が発生する場合がございます。
									</WprContainer>
								</span>
							</div>
							<div style={{paddingTop: '10px'}}>
								<span className='ques_icon'>Q2</span>
								<span className='ques_text'>電話回線を利用した各種サービスの利用状況の確認です。</span><span className='txtRequired'>*</span>
							</div>
							<div>
								<MatCheckBox name='noService' color='primary' view={this.view} className='check'/><span className='checklabel'>利用なし</span>
								<br/><MatCheckBox name='homeSecurity' color='primary' view={this.view} className='check'/><span className='checklabel'>ホームセキュリティ</span>
								<WprContainer mode='備考_ホームセキュリティ.表示' view={this.view}>
									<p className='checkText'>サービスよってはご利用いただけない場合があります。</p>
									<p className='checkText'>お客さまにてサービス提供会社にお問い合わせください。</p>
								</WprContainer>
								<br/><MatCheckBox name='emergencyCall' color='primary' view={this.view} className='check'/><span className='checklabel'>緊急通報システム、福祉電話、ホームエレベータの利用</span>
								<WprContainer mode='備考_緊急通報備考.表示' view={this.view}>
									<p className='checkText'>ケーブルプラス電話は、停電時およびメンテナンス・障害発生によりご利用ができなくなる場合があります。</p>
									<p className='checkText'>電話回線が途切れることで重篤な問題が発生する可能性のある設備(ホームエレベータ・緊急通報システム等)に接続される場合はご利用はお勧めいたしません。</p>
								</WprContainer>
								<br/><MatCheckBox name='intercom' color='primary' view={this.view} className='check'/><span className='checklabel'>インターフォン利用</span>
								<WprContainer mode='備考_インターフォン.表示' view={this.view}>
									<p className='checkText'>別途工事が必要となる場合や、ご利用いただけない場合があります。</p>
								</WprContainer>
								<br/><MatCheckBox name='homeBusinessPhone' color='primary' view={this.view} className='check'/><span className='checklabel'>ホームテレフォン・ビジネスフォン利用</span>
								<WprContainer mode='備考_ホーム・ビジネステレフォン.表示' view={this.view}>
									<p className='checkText'>複数の電話機およびホームテレフォンの動作に際し、主装置を利用している場合はご利用いただけません。</p>
								</WprContainer>
								<br/><MatCheckBox name='gasWater' color='primary' view={this.view} className='check'/><span className='checklabel'>ガス・水道等の検針サービス</span>
								<WprContainer mode='備考_ガス・水道.表示' view={this.view}>
									<p className='checkText'>電話回線を使ったガス・水道等の検針サービスをご利用中の場合は、ケーブルプラス電話のお申し込み前にサービス提供会社へ電話サービスを切替える旨ご連絡ください。</p>
									<p className='checkText'>※お切換えのできない場合もございます。</p>
								</WprContainer>
								<br/><MatCheckBox name='phoneRental' color='primary' view={this.view} className='check'/><span className='checklabel'>電話機を回線業者よりレンタルしている</span>
								<WprContainer mode='備考_電話機レンタル.表示' view={this.view}>
									<p className='checkText'>別途電話機のご用意が必要となる可能性があります。</p>
								</WprContainer>
								<br/><MatCheckBox name='otherService' color='primary' view={this.view} className='check'/><span className='checklabel'>その他</span>
								<WprContainer mode='備考_その他.表示' view={this.view}>
									<p className='checkText'>別途工事が必要となる場合や、ご利用いただけない場合があります。</p>
								</WprContainer>
							</div>
						</WprContainer>
						<WprContainer mode='電話サービス注意事項.表示' view={this.view}>
							<div className='title_space phoneServiceCaution'>
								<div className='itemTitle title_maru'>電話サービス お申し込みに当たっての注意事項</div>
								<p>下記の注意事項をご一読の上、確認ボタンを押下してください。</p>
								<p>
									開通確認が必要な場合
									<br/>工事時に新しい電話番後が発行されていない場合、開通確認にテストを行います。必ず在宅をお願いいたします。
								</p>
								<p>
									auスマートバリューお申し込みの場合はお客さまよりKDDIへ申告が必要です。
									<br/>auスマートバリュー適用の際はauへの手続きが必要と説明を受けた。
									<br/>適用にはauショップ、または専用ダイヤルにて受付が可能です。
									<br/>au携帯から局番なし157（無料）au以外の携帯電話・一般電話から0077－7－111（無料）
									<br/>※既にauに適用をお申し込みのお客さまも再度ご確認をお願いいたします。
								</p>
								<p>
									通話明細はKDDIのWEBサイト「My au」（https://cs.kddi.com/）にてご確認いただけます。<br/>
									My auにて前月を含む過去3 ヵ月以内の通話明細をご確認いただけます。当月の通話明細は確認できません。<br/>
									※紙面でも通話明細をご希望の場合は、別途お申し込み（有償220円／月（税込））が必要となります。
								</p>
								<p>
									auまとめトークの適用にはauケータイ電話番号の申告が必要です。<br/>
									auまとめトークは、ケーブルプラス電話とauケータイのご登録契約者名が同じ、もしくは登録住所が同じ場合に適用となります。<br/>
									※au自宅割適用についてはauへご確認ください。
								</p>
								<p>
									停電時・メンテナンス時、障害時は利用できません。<br/>
									ケーブルプラス電話は、停電時またはメンテナンス時はご利用いただけません。 (発着信ともにご利用いただけません。)
								</p>
								<p>
									電話帳(タウンページ・ハローページ)の配布はございません。<br/>
									電話帳の配布をご希望の場合はタウンページセンター (連絡先：0120-506-309)にお問い合わせください。
								</p>
								<p>
									正常な開通について<br/>
									ケーブルプラス電話を正常に開通させるため、 ケーブルプラス電話用宅内機器の電源を常時入れていただく必要があります。<br/>
									（ナンバーポータビリティの場合のみ）引継ぎ元の申告内容に誤りがあった場合、開通するまで時間がかかります
								</p>
							</div>
							<div className='input_gray confirm'>
								<div className='checkArea'>
									<div>
										<MatCheckBox name='phoneServiceConfirm' color='primary' view={this.view} className='check'/>
									</div>
									<span className='checklabel'>電話サービス お申込みに当たっての注意事項を確認した</span><span className='txtRequired'>*</span>
								</div>
							</div>
						</WprContainer>
						<div className='itscomNet_img title_space'>
							<a href='https://www.itscom.co.jp/service/ipphone/course.html' className='courseUrl' target='_blank'>
								<b>オプション詳細 </b>
								<img src={nextIcon} alt='' className='orange_circleArrow'/>
							</a>
						</div>	
						<Grid container>
							<Grid item xs={12}>
								<MatTableContainer listName={'OptionListModel'} view={this.view} noDataUseHeader noDataMessage=' '>
									{/* PC用 */}
									<WprContainer mode='オプション.PC' view={this.view}>
										<Table className = 'optionSelsctPcTable'> 
											<colgroup>
												<col style={{width: '20%'}}/>
												<col style={{width: '40%'}}/>
												<col style={{width: '40%'}}/>
											</colgroup>
											<TableHead className='hedderrow'>
												<TableRow className='toptable'>
													<TableCell align='center' className='border_right font_bold'>選択</TableCell>
													<TableCell align='center' className='border_right font_bold'>オプション名</TableCell>
													<TableCell align='center' className='font_bold'>月額料金</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{this.renderList('OptionListModel', 'PC')}
											</TableBody>
										</Table>
									</WprContainer>
									{/* SP用 */}
									<WprContainer mode='オプション.SP' view={this.view}>
										<Table className ='optionSelsctSpTable'>
											<colgroup>
												<col style={{width: '25%'}}/>
												<col style={{width: '75%'}}/>
											</colgroup>
											<TableHead className='hedderrow'>
												<TableRow className='toptable'>
													<TableCell rowSpan={3} align='center' className='border_right font_bold'>選択</TableCell>
													<TableCell rowSpan={1} align='center' className='font_bold'>オプション名</TableCell>
												</TableRow>
												<TableRow className='toptable'>
													<TableCell rowSpan={1} align='center' className='font_bold'>月額料金</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{this.renderList('OptionListModel', 'SP')}
											</TableBody>
										</Table>
									</WprContainer>
								</MatTableContainer>
							</Grid>
						</Grid>
						<ul className='gray'>
							<u className='title'>≪発信電話番号表示≫</u>
							<div className='space'>
								かけてきた相手が発信番号を通知している場合のみ表示します。国際電話、公衆電話、表示圏外となる通話など、電話番号を通知できない通信では表示されません。
							</div>
							<u className='title'>≪番号通知リクエスト≫</u>
							<div className='space'>
								「発信番号表示」の申し込みが必要です。国際電話、公衆電話、表示圏外となる通話など、電話番号を通知できない通信では表示されません。
								プッシュ本信号の出せる電話機からのみ操作いただけます。加入時はサービス開始に設定されています。お申込み後、ご契約電話回線から「148」をダイヤルし、ガイダンスに従って機能を有効にしてください。
							</div>
							<u className='title'>≪割込番号表示≫</u>
							<div className='space'>
								「割込電話」と「発信番号表示」の申し込みが必要です。かけてきた相手が発信者番号を通知している場合のみ表示します。
								国際電話、公衆電話、表示圏外となる通話など、電話番号を通知できない通信では表示されません。
							</div>
							<u className='title'>≪迷惑電話自動ブロック≫</u>
							<li className='space'>
								設定の有無にかかわらず、月額料金は発生します。<br/>プッシュホン信号の出せる電話機からのみ操作いただけます。<br/>31件目の登録をすると、1件目に登録した電話拒否設定が解除されます。
							</li>
							<u className='title'>≪着信転送≫</u>
							<li className='space'>
								着信転送のお申込みには本人確認が必要になります。
							</li>
							<li>
								転送先には次の番号を設定できません。
								<br/>110番や104番などの3ケタの番号、0120-・・・、0800-・・・、050-・・・(一部の番号)、00△△で始まる番号など、#ダイヤル、伝言ダイヤル*、国際電話の番号
							</li>
								転送先から別の転送先までの通話料は、転送先のご負担となります。
							<li>
								スケジュール転送の設定は、My auでご登録ください。電話機からは設定できません。転送先の電話機には、発信者の電話番号が表示される場合があります。
							</li>
						</ul>
						<ul className='gray'>
							<u className='title'>≪オプションお得パックについて≫</u>
							<div className='space'>
								以下のオプションサービスすべてお申込みいただいた場合は「オプションお得パック」が適用となり、月額利用料の合計額1,430円を759円に割引いたします。
								なお「オプションお得パック」は、以下対象サービス利用料のご請求時に自動で適用されるため、「申込内容確認書」および「契約内容確認書」の月額利用料には反映されません。
								<br/>対象サービス：迷惑電話自動ブロック/割込通話/発信電話番号表示/番号通知リクエスト/割込番号表示
							</div>
						</ul>
						<WprContainer mode='迷惑電話.表示' view={this.view}>
							<ul className='gray'>
								<div className='title_blue'>迷惑電話自動ブロック同意事項</div>
								<div className='space'>
									迷惑電話自動ブロック（以下「本サービス」）のご利用にあたり、以下(1)～(3)の内容に同意します。
									<br/>以下(1)～(3)に同意いただけない場合、本サービスはお申込みいただくことができません。
									<ul className='number_ul'>
										<li className='number'>
											本サービスでは、本サービスの利用者宛にかかってきたすべての通話について、迷惑電話かどうかを自動的に判定します。<br/>
											ただし、全ての迷惑電話の正確な判定を保証するものではありません。
										</li>
										<li className='number'>
											迷惑電話データベースの情報精度向上を目的に、利用者ごとに集計した以下の情報を１回/日の頻度でトビラシステムズ株式会社に提供します。<br/>
											【トビラシステムズ株式会社に提供する情報】<br/>
										</li>
										<div>通話ごとの情報</div>
										<div className='marginLeft'>本サービスの標準ブロックリストにある電話番号又は利用者が着信拒否を設定したお客様指定ブロックリストから利用者の固定電話にかかってきた通話についての次の情報</div>
										<div className='marginLeft'>
											①発信日時、②発信元電話番号（非通知等の場合はその旨）<br/>
											③本サービスによって着信を拒否・許可した状況、④通話時間
										</div>
										<div> 利用者ごとの情報</div>
										<div className='marginLeft'>
											⑤利用者の固定電話の電話番号(下4ケタは隠蔽)、⑥その日の着信回数(発信番号通知・非通知別)
										</div>
										<li className='number'>
											契約者と利用者が異なる場合、上記情報提供がなされることについて契約者は利用者に説明します。
										</li>
									</ul>
								</div>
							</ul>
							<div className='title_center' style={{paddingBottom: '1rem'}}>
								<MatCheckBox name='prankCallFlg' label='同意する' view={this.view}/>
							</div>
						</WprContainer>
						<div className='title_space'>
							<div className='itemTitle title_maru'>リフォームの確認</div>
							<Typography className='title_comment' style={{marginLeft: '15px'}}>当社にて機器を設置して以降、配線・端子の交換、場所移動を伴うリフォームを行いましたか</Typography>
						</div>
						<div className='title_center'>
							<MatCheckBox name='reformFlg' label='リフォームを行った' view={this.view}/>
						</div>
						<div className='title_space'>
							<div className='itemTitle title_maru'>セキュリティ会社加入の確認</div>
							<Typography className='title_comment' style={{marginLeft: '15px'}}>ホームセキュリティにご加入している場合に工事の際にご連絡が必要な場合があります。</Typography>
						</div>
						<div className='title_center'>
							<div className='title_center'>
								<MatCheckBox name='securityFlg' label='セキュリティ会社に加入しています' view={this.view}/>
								<WprContainer mode={'セキュリティ会社.表示'} view={this.view}>
									<div className='flex'>
										<Typography className='title_comment' style={{marginRight: '15px'}}>	セキュリティ会社</Typography>
										<MatTextField name='securityComment' view={this.view} style={{width: '50%'}}></MatTextField>
									</div>
								</WprContainer>
							</div>
						</div>
						<div className='title_space'>
							<div className='itemTitle title_maru'>TV端子の確認<span className='txtRequired'>*</span></div>
							<Typography className='title_comment' style={{marginLeft: '15px'}}>機器の移動の際に移動先の場所にTV端子があることが必要になります。</Typography>
						</div>
						<div className='title_center'>
							<MatCheckBox name='tvPortFlg' label='移動先の場所にTV端子があることを確認しました' view={this.view}/>
						</div>
						<div className='title_space itemTitle title_maru'>作業の希望日時</div>
							<div className='dateArea'>
								<Grid container spacing={1}>
									<Grid item xs={12} md={6} className='itemTitle2'>
										第一希望
										<span className='txtRequired'>*</span>
										<div className='customerInput'>
											<MatDatePicker name='desiredDate1' view={this.view} placeholder='年/月/日' use='mobile' />
										</div>
									</Grid>
									<Grid item xs={12} md={6} className='itemTitle2'>
										時間帯
										<span className='txtRequired'>*</span>
										<div className='customerInput'>
											<MatSelect name='selectTime1' vm='時間帯' view={this.view} />
										</div>
									</Grid>
									<Grid item xs={12} md={6} className='itemTitle2'>
										第二希望
										<span className='txtRequired'>*</span>
										<div className='customerInput'>
											<MatDatePicker name='desiredDate2' view={this.view} placeholder='年/月/日' use='mobile' />
										</div>
									</Grid>
									<Grid item xs={12} md={6} className='itemTitle2'>
										時間帯
										<span className='txtRequired'>*</span>
										<div className='customerInput'>
											<MatSelect name='selectTime2' vm='時間帯' view={this.view} />
										</div>
									</Grid>
									<Grid item xs={12} md={6} className='itemTitle2'>
										第三希望
										<span className='txtRequired'>*</span>
										<div className='customerInput'>
											<MatDatePicker name='desiredDate3' view={this.view} placeholder='年/月/日' use='mobile' />
										</div>
									</Grid>
									<Grid item xs={12} md={6} className='itemTitle2'>
										時間帯
										<span className='txtRequired'>*</span>
										<div className='customerInput'>
											<MatSelect name='selectTime3' vm='時間帯' view={this.view} />
										</div>
									</Grid>
								</Grid>
							</div>
							<ul className='ul_kome'>
								<li>訪問担当が到着するおおよその時間帯目安となります。</li>
								<li>ご訪問日時については近日中にお電話にてご連絡いたします。<br/>訪問担当の空き状況によってご希望に沿えない場合もございますことご了承ください。<br/>お建物の状況等によっては工事前に別途調査が必要となる場合がございます。</li>
							</ul>
							<div className='feeTable inputArea' style={{marginTop: '30px'}}>	
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>前日連絡先<span className='txtRequired'>*</span></div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
										<MatTextField name='beforeContact' className='itemUnit' placeholder='電話番号ハイフンなし' view={this.view} />
									</Grid>
								</Grid>	
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>当日連絡先<span className='txtRequired'>*</span></div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
										<MatTextField name='sameDayContact' className='itemUnit' placeholder='電話番号ハイフンなし' view={this.view} />
									</Grid>
								</Grid>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>当日立ち合い者<span className='txtRequired'>*</span></div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue' style={{padding: '15px'}}>
										<MatTextField name='name' className='itemUnit' placeholder='当日立ち合い者' view={this.view} />
									</Grid>
								</Grid>
							</div>
						</div>
						<Divider />
						<div className='pageFooter footer-space'>
							<div>
								<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >確認
									<NavigateNextIcon />
								</MatButton>
							</div>
							<div>
								<MatButton name='back' view={this.view} className='backButton' >
									<NavigateBeforeIcon />
										戻る
								</MatButton>
							</div>
						</div>
					</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=OptionListModel
	 * PC用
	 */
	public onOptionPcListModelRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow view={this.view} row={rowInfo}>
				<TableCell align='center' className='border_right'>
					<MatCheckBox name='check' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='border_right'>
					<MatTypography name='optionName' view={this.view} row={rowInfo}/>
					<MatTypography name='notes' className='notes' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell align='center'>
					<MatTypography name='optionFee' className='courseFee' view={this.view} row={rowInfo}/>
					<MatTypography name='payUnit' className='courseUnit' view={this.view} row={rowInfo}/>
				</TableCell>
			</MatTableRow>
		);
	}

	/**
	 * リスト名=OptionListModel
	 * SP用
	 */
	public onOptionSpListModelRender(rowInfo: WprRowInfo): any {
		return (
			<>
				<TableRow>
					<TableCell className='border_right' align='center' rowSpan ={2}> 
						<MatCheckBox name='check' view={this.view} row={rowInfo}/>
					</TableCell>
					<TableCell rowSpan ={1}>
						<MatTypography name='optionName' className='courseSize' view={this.view} row={rowInfo}/>
						<MatTypography name='notes' className='spNotes' view={this.view} row={rowInfo}/>
					</TableCell>
				</TableRow>
				<TableRow className='optionRow'>
					<TableCell rowSpan ={1}>
						<MatTypography name='optionFee' className='courseFee' view={this.view} row={rowInfo}/>
						<MatTypography name='payUnit' className='courseUnit' view={this.view} row={rowInfo}/>
					</TableCell>
				</TableRow>
			</>
		);
	}	

	/**
	 * リスト名=ErrorModelList
	 */
	public onErrorModelListRender(rowInfo: WprRowInfo): any {
		return (
			<div>
				<MatTypography name='errorMessage' view={this.view} row={rowInfo}/>
			</div>
		);
	}
	// --------------------------------------------------------------------------
}
