import { WprControlInfo } from '../../../../../wpr-framework/view/control/WprControlInfo';
import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { WprDI_Control } from '../../../../../wpr-framework/view/di/WprDI_Control';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { GetMGeneralRequest } from '../../../../service/models/dto/mypagerenewal/GetMGeneralRequest';
import { MGeneral } from '../../../../service/models/entity/MGeneral';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';
import { AgreeMode } from './mode/AgreeMode';
import { YakkaCheckListMode } from './mode/YakkaCheckListMode';

/**
 * イッツコムラーニング申込約款画面
 */
export class AddLearningAgreeView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('AddLearningAgreeView'); }
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_confirm: WprControlInfo	= null;	// 承諾
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;		// 画面ウィザード管理クラス
	@WprListData('YakkanListModel')
	private m_YakkanListModel: MGeneral[] = new Array();	// 約款リストモデル
	// -------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_AgreeMode = new AgreeMode();	// 約款モード
	@WprViewMode
	private m_YakkaCheckListMode = new YakkaCheckListMode();	// 約款チェックリストモード
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addListConfig('yakkanName', '名称1', 'YakkanListModel', { bindName: 'name1' });
		this.addListConfig('text1', '名称1', 'YakkanListModel');
		this.addListConfig('text2', '名称1', 'YakkanListModel');
		this.addConfig('confirm', '承諾', null);
		this.addConfig('next', '次へ', null);
		this.addListConfig('fileSize', '数値1', 'YakkanListModel', { bindName: 'num1' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
		this.addListAction('yakkan', 'YakkanListModel', this.onYakkan);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			const req: GetMGeneralRequest = new GetMGeneralRequest();
			req.generalList = this.m_MypageWizardMngr.activeFlow.getWizardApiModel(this.m_MypageWizardMngr, this).yakkanList;
			this.m_CommonService.getMGeneralList(this, req, (result) => {
				for (const yakkan of result.generalModelList) {
					this.m_YakkanListModel.push(yakkan.generalList[0]);
				}
				this.m_YakkanListModel[0].text1 = '必須確認項目';
				this.setListData('YakkanListModel', this.m_YakkanListModel);
				this.refreshView();
			});
		});
	}

	/**
	 * 値変更通知
	 * @param name 名前
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeValue(name: string, value: any): boolean {
		return true;
	}
	// --------------------------------------------------------------------------



	// アクション  ---------------------------------------------------------------
	/**
	 * 申込む
	 */
	public onNext(param: any): void {
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}

	/**
	 * 約款名
	 */
	public onYakkan(param: any, item: any): void {
		item.flag1 = true;
		this.setViewMode('確認事項', this.isAllCheckedList() ? '全チェック済' : null);
		window.open(item.name2);
		this.refreshView();
	}
	// --------------------------------------------------------------------------

	/**
	 * 約款全て確認済みであるか
	 * @returns 
	 */
	private isAllCheckedList(): boolean {
		if (ObjectUtil.isNotNullOrEmptyArray(this.m_YakkanListModel)) {
			for (const yakkan of this.m_YakkanListModel) {
				if ((ObjectUtil.isNotNullOrUndefined(yakkan.flag1) && !yakkan.flag1)) {
					return false;
				}
			}
		}
		// 全てチェックされていた場合、true
		return true;
	}
}
