import { MypageBaseView } from '../../base/MypageBaseView';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { CustomerSupportModel } from '../../../view/common/customer_support/models/CustomerSupportModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';

/**
 * お問い合わせ
 */
export class CustomerSupportView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('CustomerSupportView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprModel('CustomerSupportModel')
	private m_CustomerSupportModel: CustomerSupportModel	= null;	// お問い合わせモデル
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	// --------------------------------------------------------------------------

	// メンバ変数 ----------------------------------------------------------------
	private m_Service: string	= null;	// サービス種別
	private m_Type: string	= null;	// 申込み区分
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('msg1', '文言1', 'CustomerSupportModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('back', this.onBack);
		this.addAction('fd', this.onFd);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.registAccessLog();
		this.setStorageItem('b_url', window.location.href);
		this.m_CustomerSupportModel = new CustomerSupportModel();
		const code = this.getUrlParam('no');
		if (code.substring(3, 6) === '001')
			this.m_Service = 'テレビ'
		else if (code.substring(3, 6) === '002')
			this.m_Service = 'インターネット'
		else if (code.substring(3, 6) === '003')
			this.m_Service = 'ケーブルプラス電話'
		else if (code.substring(3, 6) === '004')
			this.m_Service = '移設'

		if (code.substring(6, 9) === '001')
			this.m_Type = '機器の追加'
		else if (code.substring(6, 9) === '002')
			this.m_Type = 'コース変更'
		else if (code.substring(6, 9) === '003')
			this.m_Type = '端末変更'
		else if (code.substring(6, 9) === '004')
			this.m_Type = '機器移動'

		//特定導入方式の場合
		if (code.substring(0, 3) === '001')
			this.m_CustomerSupportModel.msg1 = 'ご希望の内容は、当物件ではご利用頂くことができかねます。<br/>ご理解のほどよろしくお願い申し上げます。';
		//戸建賃貸の場合
		else if (code.substring(0, 3) === '002')
			this.m_CustomerSupportModel.msg1 = 'お客様のお住まいではマイページ上から' + this.m_Type + 'はできません。<br/>大変お手数をおかけ致しますが、イッツコムカスタマーセンターまでご連絡ください。';
		//2軸物件の場合
		else if (code.substring(0, 3) === '003')
			this.m_CustomerSupportModel.msg1 = 'お客様のお住まいではマイページ上から' + this.m_Type + 'はできません。<br/>大変お手数をおかけ致しますが、イッツコムカスタマーセンターまでご連絡ください。';
		//回線種別その他の場合
		else if (code.substring(0, 3) === '004')
			this.m_CustomerSupportModel.msg1 = 'お客様のお住まいではマイページ上から' + this.m_Type + 'はできません。<br/>大変お手数をおかけ致しますが、イッツコムカスタマーセンターまでご連絡ください。';
		//ネット無料コースの場合
		else if (code.substring(0, 3) === '005') {
			this.m_CustomerSupportModel.msg1 = 'マイページ上からコース変更を行う際はクレジットカードの登録が必要になります。<br/>大変お手数をおかけ致しますが、クレジットカードの登録をお願い致します。';
			this.setViewMode('支払い方法', '表示');
		}
		//設置場所(応接室|職場|休憩室|社長室)
		else if (code.substring(0, 3) === '006') 
			this.m_CustomerSupportModel.msg1 = '応接室、職場、休憩室、社長室への設置申込につきましてはご利用方法の確認が必要となりますので、<br/>大変お手数をおかけ致しますが、イッツコムカスタマーセンターまでご連絡ください。';
		//マイグレ
		else if (code.substring(0, 3) === '007') {
			this.m_CustomerSupportModel.msg1 = 'マイページ上でお申込みを受付できません。<br/>大変お手数をおかけ致しますが、イッツコムカスタマーセンターまでご連絡ください。';
		}
		//ネット変更；メッシュWi-Fi契約中、ネット160M以上なし
		else if (code.substring(0, 3) === '008') {
			this.m_CustomerSupportModel.msg1 = 'マイページ上でお申込みを受付できません。<br/>大変お手数をおかけ致しますが、イッツコムお客様センターまでご連絡ください。';
		}
		this.refreshView();
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * マイページトップへ戻る        
	 */
	public onBack(param: any): void {
		location.href = this.getOldUrl() + 'Top/My_top/';
	}
	/**
	 * お問い合わせ        
	 */
	public onFd(param: any): void {
		location.href = this.getOldUrl() + 'Inquiry/Inquiry_entry/';
	}
	// --------------------------------------------------------------------------
}
