// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * イッツコムラーニング申込約款画面用CSS\r\n */\n.AddLearningAgree-hiddenClass {\n  display: none !important; }\n\n.AddLearningAgree-checked {\n  background-color: #6169B8 !important;\n  border: 2px solid #6169B8 !important; }\n  .AddLearningAgree-checked * {\n    color: #FFFFFF !important; }\n", ""]);
// Exports
exports.locals = {
	"hiddenClass": "AddLearningAgree-hiddenClass",
	"checked": "AddLearningAgree-checked"
};
module.exports = exports;
