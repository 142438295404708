import { WprModel } from '../../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { AddServiceModel } from '../../../../service/models/apimodels/AddServiceModel';
import { GetSimulationRequest } from '../../../../service/models/dto/mypagerenewal/GetSimulationRequest';
import { MypageBaseView } from '../../../base/MypageBaseView';
import { ProgressBarData } from '../../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../../wizard/models/MypageWizardMngr';
import { ObjectUtil } from '../../../../common/util/ObjectUtil';
import { GetTvContractDateRequest } from '../../../../service/models/dto/itscomTv/GetTvContractDateRequest';
import { Contract } from '../../../../models/bss/contract/Contract';
import { Customer } from '../../../../models/bss/customer/Customer';
import { ItscomTvService } from '../../../../service/ItscomTvService';
import { ContractInfo } from '../mod_net_select/models/ContractInfo';
import { WprListData } from '../../../../../wpr-framework/view/deco/WprListData';
import { BaseInfoListModel } from '../mod_net_select/models/BaseInfoListModel';
import MypageDispdivcdConstants from '../../../../common/MypageDispdivcdConstants';
import { MCommon } from '../../../../service/models/entity/primary/MCommon';
import { CourseListModel } from '../../../../models/mypage/net/CourseListModel';
import { NetFlowModel } from '../../../../models/mypage/net/NetFlowModel';
import { GetGradeRequest } from '../../../../service/models/dto/mypagerenewal/GetGradeRequest';
import { MCampaign } from '../../../../service/models/entity/primary/MCampaign';
import { GetConstructionCostRequest } from '../../../../service/models/dto/mypagerenewal/GetConstructionCostRequest';
import { MCampaignGroup } from '../../../../service/models/entity/primary/MCampaignGroup';

/**
 * [サービス変更申し込み]申込内容の確認
 */
export class ModNetConfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('ModNetConfirmView'); }
	// --------------------------------------------------------------------------
	
	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprModel('NetInfo')
	private m_NetInfo: CourseListModel						= null;		// ネット画面情報
	@WprModel('NetFlowModel')
	private m_NetFlowModel: NetFlowModel					= null;		// ネット遷移情報
	@WprModel('ContractInfo')
	private m_ContractInfo: ContractInfo					= null;		// 契約情報
	@WprListData('BaseInfoList')
	private m_BaseInfoList: BaseInfoListModel[]				= null;		// 基本情報リスト
	@WprListData('ConfirmListModel')
	private m_ConfirmListModel: CourseListModel[]			= null;		// 変更後リスト
	@WprListData('BefConfirmList')
	private m_BefConfirmList: CourseListModel[]				= null;		// 変更前リスト
	@WprModel('CampaignGroup')
	private m_CampaignGroup: MCampaignGroup	= null;	// キャンペーングループモデル
	@WprListData('CampaignListModel')
	private m_CampaignListModel: MCampaign[]				= null;		// キャンペーンリストモデル

	// --------------------------------------------------------------------------
	// メンバ変数  ---------------------------------------------------------------
	private m_Contract: Contract 							= null; // 契約情報
	private m_Customer:	Customer							= null;	// 顧客情報
	private m_CableModemList: MCommon[]						= null;	// ケーブルモデムリスト
	private m_CableWiredList: MCommon[]						= null;	// ケーブルモデムリスト(Wired)
	private m_CableWifiList: MCommon[]						= null; // ケーブルモデムリスト(Wifi)
	// --------------------------------------------------------------------------
	
	// サービス  ----------------------------------------------------------------
	private m_ItscomTvService: ItscomTvService	= new ItscomTvService();	// ItscomTvサービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('curFee', '旧月額', 'NetInfo', { converter: '3桁カンマ' });
		this.addConfig('newFee', '新月額', 'NetInfo', { converter: '3桁カンマ' });
		this.addConfig('otokuPlan', '現在のプラン名', 'NetInfo');
		this.addListConfig('itemName', '項目名', 'ConfirmListModel');
		this.addListConfig('itemData', '項目データ', 'ConfirmListModel');
		this.addListConfig('comment', 'フリー入力', 'ConfirmListModel');
		this.addConfig('startDate', '契約開始日', 'NetInfo');
		this.addListConfig('itemName', '項目名', 'BefConfirmList');
		this.addListConfig('itemData', '項目データ', 'BefConfirmList');
		this.addConfig('desiredDate1', '第一希望', 'NetInfo', { converter: '日付（yyyy/MM/dd）' });
		this.addConfig('selectTime1', '第一希望　時間帯', 'NetInfo', { disabled: true});
		this.addConfig('desiredDate2', '第二希望', 'NetInfo', { converter: '日付（yyyy/MM/dd）' });
		this.addConfig('selectTime2', '第二希望　時間帯', 'NetInfo', { disabled: true});
		this.addConfig('desiredDate3', '第三希望', 'NetInfo', { converter: '日付（yyyy/MM/dd）' });
		this.addConfig('selectTime3', '第三希望　時間帯', 'NetInfo', { disabled: true});
		this.addConfig('cost', '工事費', 'NetInfo', { converter: '3桁カンマ' });
		this.addConfig('payUnit', '料金単位', 'NetInfo');
		// this.addConfig('campaignName', '名称2', 'NetInfo.campaign', { bindName: 'name2' });
		// this.addConfig('campaignDiscount', '数値1', 'NetInfo.campaign', { bindName: 'num1' });
		this.addConfig('campaignName', 'キャンペーン名', 'CampaignGroup', { bindName: 'campaignGroupDispName' });
		this.addConfig('campaignExplanation', 'キャンペーン文言', 'CampaignGroup', { bindName: 'explanation' });
		this.addConfig('beforeContact', '前日連絡先', 'NetInfo');
		this.addConfig('sameDayContact', '当日連絡先', 'NetInfo');
		this.addConfig('name', '当日立ち合い者', 'NetInfo');
		this.addConfig('securityDisp', 'セキュリティ会社', 'NetInfo');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			const contractId = this.m_MypageWizardMngr.getParams().contractId;
			if (ObjectUtil.isNotNullOrEmptyStr(contractId))
				this.m_Contract = this.m_MypageWizardMngr.getContract(this, contractId);
			this.m_ContractInfo = this.m_MypageWizardMngr.getCache().m_ContractInfo;
			this.m_NetInfo = this.m_MypageWizardMngr.getCache().m_NetInfo;
			this.m_BaseInfoList = this.m_MypageWizardMngr.getCache().m_BaseInfoList;
			this.m_Customer = this.m_MypageWizardMngr.getViewInfo().customer;
			this.m_NetFlowModel = this.m_MypageWizardMngr.getCache().m_NetFlowModel;
			// 機器の種類(変更後)
			const cableName = this.m_MypageWizardMngr.getMCommonList(this,'cable_modem_wired', null, null, null);
			const cableWifiName = this.m_MypageWizardMngr.getMCommonList(this,'cable_modem_wifi', null, null, null);
			this.m_CableModemList = this.m_MypageWizardMngr.getMCommonList(this, 'srvnet', 'cable_modem', null, null);
			this.m_CableWiredList = this.m_CableModemList.filter(row => row.category3 === 'wired');
			this.m_CableWifiList = this.m_CableModemList.filter(row => row.category3 === 'wifi');
			if (this.m_NetInfo.cable)
				this.m_NetInfo.afterEquipment = cableName[0].commonValue;
			else if (this.m_NetInfo.cableWifi)
				this.m_NetInfo.afterEquipment = cableWifiName[0].commonValue;
			// 変更なしの場合、変更前の端末の種類を判定
			else if (this.m_NetInfo.noChangeCable) {
				const cableWired = this.m_CableWiredList.filter(data => data.commonValue === this.m_ContractInfo.equipmentTypeCd);
				if (cableWired && cableWired.length > 0) 
					this.m_NetInfo.afterEquipment = cableName[0].commonValue;
				const cableWifi = this.m_CableWifiList.filter(data => data.commonValue === this.m_ContractInfo.equipmentTypeCd);
				if (cableWifi && cableWifi.length > 0) {
					this.m_NetInfo.afterEquipment = cableWifiName[0].commonValue;
					this.m_NetInfo.befCableWifiFlg = true;
				}
			}
			// 機器のご提供方法(変更後)
			const providList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMHOME_TERMINALSTATUS, '');
			if (this.m_NetInfo.equipmentProvidingCheck) {
				const rental = providList.filter(data => data.namecd === '1');
				if (rental && rental.length > 0)
					this.m_NetInfo.afterProvision = rental[0].dispnamep;
			}
			else if (this.m_NetInfo.buyCheck) {
				const buy = providList.filter(data => data.namecd === '0');
				if (buy && buy.length > 0)
					this.m_NetInfo.afterProvision = buy[0].dispnamep;
			}
			// 変更なしの場合、元のレンタルフラグを継承
			else if (this.m_NetInfo.noChange) {
				const noChange = providList.filter(data => data.namecd === this.m_Contract.equipment[0].rentalFlag);
				if (noChange && noChange.length > 0)
					this.m_NetInfo.afterProvision = noChange[0].dispnamep;
			}
			const kattobi = this.m_MypageWizardMngr.getMCommonList(this,'namecd','course_net','value',null);
			if (this.m_NetInfo.aftServiceCd === kattobi[0].commonValue) { // かっとびバリューの場合
				const deviceStatus = providList.filter(data => data.namecd === '9');
				if (deviceStatus && deviceStatus.length > 0)
					this.m_NetInfo.afterProvision = deviceStatus[0].dispnamep;
				this.m_NetInfo.kattobiFlg = true;
			}
			// 契約開始日判定
			if (!this.m_NetInfo.constractionFlg)
				this.getStartDate();
			else {
				this.m_NetInfo.directflg = false;
				this.m_NetInfo.startDate = null;
			}
			// 申込み内容
			this.setConfirmList();
			// 料金シミュレーション
			this.paymentSimulation();
			// 表示判定	
			if (this.m_NetInfo.otokuFlg) {
				this.setViewMode('お得パック' ,'加入中');
				this.setViewMode('新月額利用料', '※');
			}
			else if (this.m_NetInfo.yesOtoku) 
				this.setViewMode('新月額利用料', '※');
			else
				this.setViewMode('新月額利用料', '料金');
			//キャンペーン
			if (this.m_NetInfo.campaignList) {
				this.setViewMode('キャンペーンコード', '有');
				this.m_CampaignGroup = this.m_NetInfo.campaignGroup;
				this.m_CampaignListModel = this.m_NetInfo.campaignList;
				this.setListData('CampaignListModel', this.m_CampaignListModel);
				// this.setViewMode('初期費用キャンペーン', this.m_TvBaseModel.campaign.subCd1 === '01' ? '有' : '無');
				// if (this.m_TvBaseModel.campaign.subCd1 === '01' && this.m_TvBaseModel.campaign.subCd2 === '03')
				// 	this.m_TvBaseModel.campaign.num1 = Number(this.m_TvBaseModel.constrAmount);
			}
			else
				this.setViewMode('キャンペーンコード', '無');
			//セキュリティ会社
			if (this.m_NetInfo.securityFlg)
				this.m_NetInfo.securityDisp = this.m_NetInfo.securityComment;
			else
				this.m_NetInfo.securityDisp = 'なし';
			// 工事費
			if (this.m_NetInfo.constractionFlg) {
				this.setViewMode('工事エリア','表示');
				this.setConstructionFee();
			}
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 申込む
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_NetInfo.afterProvision = null;
		this.m_NetInfo.cost = null;
		this.m_NetInfo.payUnit = null;
		// 申し込み情報のクリア
		this.m_MypageWizardMngr.getCache().m_ConfirmListModel = new Array();
		this.m_MypageWizardMngr.goBack(this);
	}
	// --------------------------------------------------------------------------

	// privateメソッド  ----------------------------------------------------------
	/**
	 * 契約開始日判定
	 */
	private getStartDate() {
		let afTerminalCd = null;	// 申込コースの端末CD
		if (this.m_NetInfo.cableWifi)	
			afTerminalCd = '80';
		else if (this.m_NetInfo.cable) {
			const cableModem = this.m_CableModemList.filter(data => data.commonValue === this.m_ContractInfo.equipmentTypeCd);
			if (cableModem && cableModem.length > 0)
				afTerminalCd = '50';
		}
		else if (this.m_NetInfo.noChangeCable && this.m_Customer.building[0].buildingLineTypeCD === '2') {
				const cableWired = this.m_CableWiredList.filter(data => data.commonValue === this.m_ContractInfo.equipmentTypeCd);
				if (cableWired && cableWired.length > 0)
					afTerminalCd = '50';
				const cableWifi = this.m_CableWifiList.filter(data => data.commonValue === this.m_ContractInfo.equipmentTypeCd);
				if (cableWifi && cableWifi.length > 0)
					afTerminalCd = '80';
		}
		const dOnuList = this.m_MypageWizardMngr.getMCommonList(this, 'srvnet', 'd_onu_code', null, null);
		const dOnu = dOnuList.filter(data => data.commonValue === this.m_ContractInfo.equipmentTypeCd);
		if (dOnu && dOnu.length > 0)
			afTerminalCd = '70';
		const req: GetTvContractDateRequest = new GetTvContractDateRequest();
		let aftterCode: string;
		// 店子コースの場合
		if (ObjectUtil.isNotNullOrUndefined(this.m_NetInfo.tanakoServiceCd))
			aftterCode = this.m_NetInfo.tanakoServiceCd;
		else
			aftterCode = this.m_NetInfo.aftServiceCd;
		req.serviceTypeCd = this.m_Contract.serviceTypeCD;
		req.befServiceCd = this.m_ContractInfo.serviceCd;
		req.befEquipmentCd = this.m_ContractInfo.equipmentTypeCd;
		req.befRentalFlg = this.m_Contract.equipment[0].rentalFlag;
		req.aftServiceCd = aftterCode;
		req.aftEquipmentCd = afTerminalCd;
		if (this.m_NetInfo.equipmentProvidingCheck) // レンタル
			req.aftRentalFlg = '1';
		else if (this.m_NetInfo.buyCheck) 			// 購入
			req.aftRentalFlg = '0';
		else if (this.m_NetInfo.noChange) {			// 変更なし
			req.aftRentalFlg = null;
			if (ObjectUtil.isNotNullOrUndefined(this.m_Contract.equipment[0].rentalFlag))
				req.aftRentalFlg = this.m_Contract.equipment[0].rentalFlag;
		}
		this.m_ItscomTvService.getContractDate(this, req, (result) => {
			if (result) {
				this.m_NetInfo.directflg = result.directFlg;
				// 直接申込み
				if (result.directFlg) {
					this.setViewMode('直接申込', '表示');
					if (result.courseUp === '1')
						this.m_NetInfo.startDate = '翌日';
					else
						this.m_NetInfo.startDate = '翌月';
				}
				// 仮申込み
				else if (!result.directFlg)
					this.m_NetInfo.startDate = null;
			}
		});
	}

	/**
	 * 料金シミュレーション
	 */	
	private paymentSimulation() {
		const req: GetSimulationRequest = new GetSimulationRequest();
		req.customerId = this.m_MypageWizardMngr.getParams().customerId;
		req.addServiceList = new Array();
		const feeModel = new AddServiceModel;
		feeModel.contractId = this.m_Contract.contractID;
		// 店子コースの場合
		if (ObjectUtil.isNotNullOrUndefined(this.m_NetInfo.tanakoServiceCd))
			feeModel.serviceCd = this.m_NetInfo.tanakoServiceCd;
		else
			feeModel.serviceCd = this.m_NetInfo.aftServiceCd;
		feeModel.optionCdList = new Array();
		// 端末の種類《ケーブルモデムsWifi》を選択した場合、または端末の種類《変更なし》かつ前契約が《ケーブルモデムWifi》の場合
		if (this.m_NetInfo.cableWifi || (this.m_NetInfo.noChangeCable && this.m_NetInfo.befCableWifiFlg)) {
			const optionCd = this.m_MypageWizardMngr.getMCommonList(this, 'srvnet', 'wire_sv', null, null);
			if (this.m_Customer.building[0].buildingLineTypeCD === '2')
				feeModel.optionCdList.push(optionCd[0].commonValue);
			else {
				const hfcCableWifiList = this.m_MypageWizardMngr.getMCommonList(this, 'srvnet', 'hfc_wireless_sv', 'wireless_disp_sv', null);
				const cableWifi = hfcCableWifiList.filter(data => data.commonValue === this.m_NetInfo.aftServiceCd);
				if (ObjectUtil.isNullOrUndefined(cableWifi) || cableWifi.length === 0)
					feeModel.optionCdList.push(optionCd[0].commonValue);
			}
		}
		// 機器のご提供方法
		if (this.m_NetInfo.equipmentProvidingCheck)
			feeModel.rentalFlg = '1';
		if (this.m_NetInfo.buyCheck)
			feeModel.rentalFlg = '0';
		if (this.m_NetInfo.noChange)
			feeModel.rentalFlg = this.m_Contract.equipment[0].rentalFlag;
		if (this.m_NetInfo.kattobiFlg)
			feeModel.rentalFlg = '9';
		req.addServiceList.push(feeModel);
		this.m_CommonService.getPaymentSimulation(this, req, (result) => {
			this.m_NetInfo.curFee = result.breforeFee;
			// 新しい月額利用料
			if (result.minusFlg) {
				this.m_NetInfo.newFee = String(Number(result.afterFee) + 396);
			} else {
				this.m_NetInfo.newFee = result.afterFee;
			}
			// 変更前の月額
			if (result.discountFlg && ObjectUtil.isNotNullOrEmptyArray(this.m_BefConfirmList) && Number(this.m_ContractInfo.contractAmount) >= 396) {
				for (let bef of this.m_BefConfirmList) {
					if (bef.itemName === '変更前の月額') {
						const discountAmount:string = (Number(this.m_ContractInfo.contractAmount) - 396).toLocaleString();
						bef.itemData = discountAmount + '円';
					}
				}
			}
			const payment = this.m_MypageWizardMngr.getViewInfo().customerPayment;
			if (result.breforeFee === '0' && payment && 
				payment[0].payment[0].paymentTypeCD === '2' && !this.m_MypageWizardMngr.isPaidService(this))
				this.setViewMode('請求書有料発行', '表示');
			this.refreshView();
		});
	}

	/**
	 * 申込み内容リスト作成
	 */
	private setConfirmList() {
		// 変更前確認リスト
		this.m_BefConfirmList = this.m_MypageWizardMngr.getCache().m_BefConfirmList;
		if (ObjectUtil.isNullOrUndefined(this.m_BefConfirmList) || this.m_BefConfirmList.length === 0) {
			const amount = new CourseListModel();
			amount.itemName = '変更前の月額';
			amount.itemData = Number(this.m_ContractInfo.contractAmount).toLocaleString() + '円';
			this.m_BefConfirmList.push(amount);
			const course = new CourseListModel();
			course.itemName =  '申込みコース';
			course.itemData =  this.m_ContractInfo.contractCourse;
			this.m_BefConfirmList.push(course);
			const deviceInfo = this.m_BaseInfoList.filter(item => item.itemName === '機器の種類');
			if (deviceInfo && deviceInfo.length > 0) {
				if (deviceInfo[0].itemData !== '') {
					const type = new CourseListModel();
					type.itemName = '機器の種類';
					type.itemData = deviceInfo[0].terminalType;
					this.m_BefConfirmList.push(type);
					const form = new CourseListModel();
					form.itemName = '機器のご提供方法';
					form.itemData = deviceInfo[0].terminalForm;
					this.m_BefConfirmList.push(form);
				}
			}
		}
		this.setListData('BefConfirmList', this.m_BefConfirmList);
		// 変更後確認リスト
		this.m_ConfirmListModel = this.m_MypageWizardMngr.getCache().m_ConfirmListModel;
		if (ObjectUtil.isNullOrUndefined(this.m_ConfirmListModel) || this.m_ConfirmListModel.length === 0) {
			const amount = new CourseListModel();
			amount.itemName = '変更後の月額';
			if (this.m_NetInfo.payAmount !== '-')
				amount.itemData = Number(this.m_NetInfo.payAmount).toLocaleString() + '円';
			else 
				amount.itemData = '0円';
			this.m_ConfirmListModel.push(amount);
			const course = new CourseListModel();
			course.itemName =  '申込みコース';
			course.itemData =  this.m_NetInfo.cDispnamep;
			this.m_ConfirmListModel.push(course);
			// 機器の種類
			if (ObjectUtil.isNotNullOrUndefined(this.m_NetInfo.afterEquipment)) {
				const type = new CourseListModel();
				type.itemName = '機器の種類';
				type.itemData = this.m_NetInfo.afterEquipment;
				this.m_ConfirmListModel.push(type);
			}
			// 機器のご提供方法
			if (ObjectUtil.isNotNullOrUndefined(this.m_NetInfo.afterProvision)) {
				const form = new CourseListModel();
				form.itemName = '機器のご提供方法';
				form.itemData = this.m_NetInfo.afterProvision;
				this.m_ConfirmListModel.push(form);
			}
		}
		this.setListData('ConfirmListModel', this.m_ConfirmListModel);
	}

	/**
	 * 工事費判定
	 */
	private setConstructionFee() {
		const req: GetConstructionCostRequest = new GetConstructionCostRequest();
		req.constructionCategoryCd = '04';
		req.temporaryCostCategoryCd = '01';
		req.multipleFlg = false;
		req.freeFlg = false;
		const provChangeFlg = (this.m_NetInfo.deviceForm === '購入' && this.m_NetInfo.afterProvision === 'レンタル') ? true: false;
		this.m_MypageWizardMngr.getConstructionCost(this, req, this.m_NetInfo.grade, false, provChangeFlg, result => {
			if (result) {
				if (result.temporaryCost.length > 0) {
					this.m_NetInfo.cost = this.m_MypageWizardMngr.getTaxFee(this, result.temporaryCost[0].temporaryCost);
					this.m_NetInfo.payUnit = '円';
				}
				else
					this.m_NetInfo.cost = 'お見積り';
				this.refreshView();
			}
		});
	}

	// /**
	//  * 請求書有料発行の文言
	//  */
	// private setPaymentRemark() {
	// 	const contractServiceList = this.m_MypageWizardMngr.getViewInfo().contractServiceList;
	// 	if (contractServiceList && contractServiceList.length > 0) {
	// 		const payment = this.m_MypageWizardMngr.getViewInfo().customerPayment[0].payment[0].paymentTypeCD;
	// 		const freeService = contractServiceList.filter(data => data.service.payment[0].amount === 0);
	// 		if (freeService && freeService.length > 0 && payment === '2')
	// 			this.setViewMode('請求書有料発行', '表示');
	// 	}
	// }
	// --------------------------------------------------------------------------
}
