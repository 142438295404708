import { WizardModel } from '../../models/WizardModel';
import { MypageWizardFlow } from './MypageWizardFlow';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { CommonCompleteView } from '../common/common_complete/CommonCompleteView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MypageBaseView } from '../base/MypageBaseView';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import { ContractServiceModel } from '../../service/models/apimodels/ContractServiceModel';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import { AddNetSelectView } from '../Srvnet/new/add_net_select/AddNetSelectView';
import { AddNetConfirmView } from '../Srvnet/new/add_net_confirm/AddNetConfirmView';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import { MGeneral } from '../../service/models/entity/MGeneral';
import { AddNetSettingView } from '../Srvnet/new/add_net_setting/AddNetSettingView';
import { Customer } from '../../models/bss/customer/Customer';
import { ServiceModel } from '../../service/models/apimodels/ServiceModel';
import { ObjectUtil } from '../../common/util/ObjectUtil';
import { ContractInfo } from '../Srvnet/revise/mod_net_select/models/ContractInfo';
import { YakkanListModel } from '../../service/models/apimodels/YakkanListModel';
import { CommonAgreeView } from '../common/common_agree/CommonAgreeView';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import { RegistContractRequest } from '../../service/models/dto/mypagerenewal/RegistContractRequest';
import { AddOthernetConfirmView } from '../Srvnet/new/add_othernet_confirm/AddOthernetConfirmView';
import MypageCodeTypeConstants from '../../common/MypageCodeTypeConstants';
import { AddOthernetSelectView } from '../Srvnet/new/add_othernet_select/AddOthernetSelectView';
import { CourseListModel } from '../../models/mypage/net/CourseListModel';
import { NetFlowModel } from '../../models/mypage/net/NetFlowModel';
import { CustomerSupportView } from '../common/customer_support/CustomerSupportView';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';
import MypageYakkanConstants from '../../common/MypageYakkanConstants';
import { AddLearningSelectView } from '../itscomLearning/add/add_learning_select/AddLearningSelectView';
import { AddLearningConfirmView } from '../itscomLearning/add/add_learning_comfirm/AddLearningConfirmView';
import { learningCourseListModel } from '../../models/mypage/itscomLearning/learningCourseListModel';
import { AddLearningAgree } from '../itscomLearning/add/add_learning_agree/AddLearningAgree';
import { AddLearningAgreeView } from '../itscomLearning/add/add_learning_agree/AddLearningAgreeView';
/**
 * ITSCOM HOME_追加ウィザードクラス
 */
export class ItscomLearningNewWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト(通常)
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new AddLearningSelectView().name;
		// 遷移パターン(通常)
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: 'コース選択',viewName: this.m_StartViewName, path: 'add_learning_select'});
        this.m_DefaultWizardFlow.push({stepNum: 2, title: '申込み内容の確認', viewName: new AddLearningConfirmView().name, path: 'add_learning_comfirm'});
        this.m_DefaultWizardFlow.push({stepNum: 3, title: '契約約款', viewName: new AddLearningAgreeView().name, path: 'add_learning_agree'});
        this.m_DefaultWizardFlow.push({stepNum: 4, title: '申込み完了', viewName: new CommonCompleteView().name, path: 'add_learning_complete'});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.statusCdList = new Array('0', '1', '2','3');
		req.customerId = mngr.getParams().customerId;
		req.contractFlg = true;
		req.displayList = new Array();
		req.commonList = new Array();
		// 約款の取得
		if (ObjectUtil.isNotNullOrUndefined(mngr.getViewInfo())) {
			let cacheData: LearningServiceNewWizardModel = mngr.getCache();
			req.yakkanList = new Array();
			req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_LEARNING));
			if (cacheData.m_LearningModel.dispCourse === 'デジタネ') {
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_LEARNING_DEGITANE));
			} else {
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_LEARNING_DEKITASU));
			}
		}
		// イッツコムラーニングサービス一覧を取得
		req.generalList = new Array();
		req.generalList.push(new MGeneral(MypageCodeTypeConstants.ITSCOM_LEARNING_SERVICE, null));
		return req;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): LearningServiceNewWizardModel {
		let cacheData = new LearningServiceNewWizardModel();
		cacheData.m_LearningList = new Array();
		cacheData.m_LearningModel = new learningCourseListModel();
		return cacheData;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr, view: MypageBaseView): string {
		let test: boolean = false;
		if (test) {
			return '/error';
		}
		return null;
	}

	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView, resultCd: string): RegistInfoRequest{
		const mailconstants = MAIL_CONSTANTS.find (mail => {
			return mail.view === 'イッツコムラーニング_申込み';
		});
		const req: RegistInfoRequest = new RegistInfoRequest();
		const cacheData: LearningServiceNewWizardModel = mngr.getCache();
		// お客様メール
		req.customerMailId = mailconstants.customerMail;
		req.customerId = mngr.getParams().customerId;
		// 業務部門
		req.systemMailId = mailconstants.systemMail;
		// 問い合わせ
		req.inquiryId = mailconstants.inquiryMail;
		req.systemInfo = new InquiryMailInfo();
		if (cacheData.m_LearningModel.dispCourse.includes('デジタネ')) {
			// テンプレ出し分け用フラグ
			req.systemInfo.degitaneFlg = true;
			req.systemInfo.loginId = cacheData.m_LearningModel.loginId;
		}
		// 画面タイトル記載コース
		req.systemInfo.appCourse = cacheData.m_LearningModel.dispCourse;
		// 本文記載コース
		req.systemInfo.appService = cacheData.m_LearningModel.inquiryCourse;
		return req;
	}
}

/**
 * 画面ウィザードAPI用モデル
 */
export class LearningServiceNewWizardModel {
	// public 変数	-----------------------------------------------------------
	public m_LearningList: learningCourseListModel[] = null;				// 店子コースリスト
	public m_LearningModel: learningCourseListModel = null;					// ネット画面情報
	// ------------------------------------------------------------------------
}
/**
 * 約款モデル
 */
export class YakkanModel {
	// public 変数	-----------------------------------------------------------
		public yakkanNum: string = null;
		public select: boolean = false;
	// ------------------------------------------------------------------------
	public constructor(num : string) {
		this.yakkanNum = num;
	}
}
