import { WprModel } from "../../../../../wpr-framework/view/deco/WprModel";
import { WprDI_ScopeModel } from "../../../../../wpr-framework/view/di/WprDI_ScopeModel";
import { ObjectUtil } from "../../../../common/util/ObjectUtil";
import { learningCourseListModel } from "../../../../models/mypage/itscomLearning/learningCourseListModel";
import { MagazineModel } from "../../../../service/models/apimodels/MagazineModel";
import { ViewInfoModel } from "../../../../service/models/apimodels/ViewInfoModel";
import { MypageBaseView } from "../../../base/MypageBaseView";
import { ProgressBarData } from "../../../common/progress_bar/ProgressBarView";
import { MypageWizardMngr } from "../../../wizard/models/MypageWizardMngr";

/**
 * イッツコムラーニング申込確認画面
 */
export class AddLearningConfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('MagazineConfirmView'); }
	// --------------------------------------------------------------------------

	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;	// 画面ウィザード用モデル
	@WprModel('Amount')
	private m_Amount: string = null;	// 月額料金
	@WprModel('LearningModel')
	private m_LearningModel: learningCourseListModel	= null;					// 画面遷移用
	private m_MagazineModel: MagazineModel = null;	// Itscomマガジンモデル
	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('dispCourse', 'コース', 'LearningModel');
		this.addConfig('amount', '金額', 'Amount', { bindModel: true, converter: '3桁カンマ' });
		this.addConfig('loginId', 'ログインID', 'LearningModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result: ViewInfoModel) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_LearningModel = this.m_MypageWizardMngr.getCache().m_LearningModel;
			const course = this.m_MypageWizardMngr.getCache().m_LearningList.filter(row => row.check === true);
			this.m_LearningModel.dispCourse = course[0].dispCourse;
			this.m_LearningModel.payAmount = course[0].payAmount;
			this.m_LearningModel.amountType = course[0].amountType;
			this.m_LearningModel.payUnit = course[0].payUnit;
			this.m_Amount = this.m_LearningModel.amountType + this.m_LearningModel.payAmount + this.m_LearningModel.payUnit;
			this.m_LearningModel.inquiryCourse = course[0].inquiryCourse;
			this.setViewMode('コース',this.m_LearningModel.dispCourse);
			this.refreshView();
		});
	}

	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 申込む
	 */
	public onNext(param: any): void {
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}

	// --------------------------------------------------------------------------
}
