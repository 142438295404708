import React, { ReactNode } from 'react';

import { Divider, Grid, Typography } from '@material-ui/core';

import printIcon from '/shared/image/common/icon_print_on.png';
import warningIcon from '/shared/image/common/important_yellow.png';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import questionnaireIcon from '/shared/image/common/button_questionnaire_on.png';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';
import { WprSpan } from '../../../../../wpr-framework/component/controls/WprSpan';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { LearningCancelCompleteView } from './LearningCancelCompleteView';
import styles from './LearningCancelComplete.scss';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';

/**
 * イッツコムラーニング解約申込完了画面用コンポーネント
 */
export class LearningCancelComplete extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new LearningCancelCompleteView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** マガジン解約申込完了画面ビュー情報 */
	public get viewInfo(): LearningCancelCompleteView { return this.view as LearningCancelCompleteView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	 */
	public getStylesObject(): any { return styles; }



	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div>
					<div className='pageCard'>
						<div className='main'>
							<div className='title_center'>
								<MatTypography className='title_complete' name={'title_complete'} view={this.view} />
							</div>
							<div className='title_normal'>
								<MatTypography name={'user'} view={this.view} className='title_border' />
							</div>
							<div className='title_normal'>
								{/* <MatTypography name={'msg1'} view={this.view} className='title_msg'/> */}
								<WprSpan name={'msg1'} view={this.view} className='title_msg'></WprSpan>
							</div>
							<div className='title_normal'>
								<MatTypography name={'msg2'} view={this.view} className='title_msg' />
							</div>
							<div className='feeTable'>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>コース</div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue' style={{ color: 'black' }}>
										<WprSpan name={'dispCourse'} className='itemUnit' view={this.view} style={{ color: '#484848', fontWeight: '550', fontsize: '40spx' }}></WprSpan>
									</Grid>
								</Grid>
								<Grid container className='feeRow'>
									<WprContainer mode='コース.デキタス' view={this.view}>
										<Grid item xs={12} md={4} className='feeTitle'>
											<div>デキタスログインID</div>
										</Grid>
									</WprContainer>
									<WprContainer mode='コース.デジタネ' view={this.view}>
										<Grid item xs={12} md={4} className='feeTitle'>
											<div>デジタネログインID</div>
										</Grid>
									</WprContainer>
									<Grid item xs={12} md={8} className='feeVal courseValue' style={{ color: 'black' }}>
										<WprSpan name={'loginId'} className='itemUnit' view={this.view} style={{ color: '#484848', fontWeight: '550', fontsize: '40spx', overflowWrap: 'anywhere'}}></WprSpan>
									</Grid>
								</Grid>
							</div>
							<div className='questionnaire'>
								<WprAnchor name='toQuestionnaire' view={this.view}  >
									<img src={questionnaireIcon} style={{ maxWidth: '100%' }} />
								</WprAnchor>
							</div>
						</div>
						<Divider />
						<div className='pageFooter footer-space'>
							<div>
								<MatButton name='back' view={this.view} className='completeButton backContInfoList' >
									<NavigateBeforeIcon />
									<span className='screen'>契約内容の確認・変更メニューへ</span>
								</MatButton>
							</div>
						</div>

					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
