import React, { ReactNode } from 'react';
import { Divider, Typography, Grid, Table, TableBody, TableCell, Card, TableContainer, TableRow } from '@material-ui/core';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import { AddNetConfirmView } from './AddNetConfirmView';
import { MatTableContainer } from '../../../../../wpr-framework/component/controls/material/MatTableContainer';
import { MatTableRow } from '../../../../../wpr-framework/component/controls/material/MatTableRow';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import netIcon from '/shared/image/common/logo_iTSCOMNET.png';
import { MatSelect } from '../../../../../wpr-framework/component/controls/material/MatSelect';
import styles from './AddNetConfirm.scss';
import { WprSpan } from '../../../../../wpr-framework/component/controls/WprSpan';

/**
 * ［サービス追加（変更）申込み］申込み内容の確認用コンポーネント
 */
export class AddNetConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new AddNetConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ［サービス追加（変更）申込み］申込み内容の確認ビュー情報 */
	public get viewInfo(): AddNetConfirmView	{ return this.view as AddNetConfirmView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	protected onRegisterListRender(): void {
	
		this.addListRender('ConfirmListModel', null, this.onCourseListModelRender);
		// this.addListRender('CampaignListModel', null, this.onCampaignListModelRender);
	}
	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div className='pageCard'>
				<div className="pageHeader stepFlow">
					<ProgressBar name='progressBar' view={this.view} />
				</div>
				<div className='sub-logo2'>
					<img src={netIcon}/>
				</div>
				<div className='main net'>
					<div className='pageTitle'>
						<div className='title_left'>
							<MatTypography name='title' className='title_text' view={this.view}/>
							<Typography className='title_sentence'>以下の内容でよろしければ、「申込み」ボタンをクリックしてください。</Typography>
						</div>
					</div>
					<WprContainer mode='キャンペーンコード.有' view={this.view}>
						<div className='campaignArea'>
							<span className='campaignTitle' style={{ color: '#484848', fontWeight:'700'}}>適用キャンペーン</span><br/>
								<MatTypography name='campaignName' style={{ color: '484848'}} view={this.view} className='campaignText'/>
								<MatTypography name='campaignExplanation' style={{ color: '484848'}} view={this.view}className='campaignText'/>
						</div>
					</WprContainer>
					<div className='feeTable'>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle' >
									<div>現在の月額利用料の合計</div>
								</Grid>
								<Grid item xs={12} md={8} className='currentFeeArea courseValue'>
									<Grid item xs={12} md={8} className='feeVal courseValue'>
										<MatTypography name={'curFee'} className='itemUnit unit' view={this.view}/>
										<span className='feeUnit' style={{fontWeight: '500'}}>円</span>
									</Grid>
									<WprContainer mode='お得パック.加入済み' view={this.view}>
										<span className='otokuName'>
											<MatTypography name='otokuPlan' view={this.view} style={{color: '#333333'}}/>
										</span>
									</WprContainer>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle' >
									<div>新しい月額利用料の合計</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<WprContainer mode='新月額利用料.料金' view={this.view}>
										<MatTypography name='newFee' className='newFeeUnit' view={this.view}/>
										<span className='feeUnit_bold'>円</span>
									</WprContainer>
									<WprContainer mode='新月額利用料.※' view={this.view}>
										<Typography className='itemUnit'>※ ご連絡にてご案内致します。</Typography>
									</WprContainer>
								</Grid>
							</Grid>	
						</div>
					</div>
					<div className='itemTitle title_maru'>追加申込み内容</div>
					<div className='feeTable nonMarginTop'>
						<Grid container>
							<Grid item xs={12}>
								<MatTableContainer listName={'ConfirmListModel'} view={this.view}>
									<Table className ='optionTable' style={{marginBottom:'0px'}}>
										<colgroup>
											<col style={{width: '42%'}}/>
											<col/>
										</colgroup>
										<TableBody className='optionBody'  style={{marginBottom:'0px'}}>
											{this.renderList('ConfirmListModel')}
										</TableBody>
									</Table>
								</MatTableContainer>
							</Grid>
						</Grid>
					</div>
					<div className='attention_fontSize'>
						<ul className='ul_kome'>
							<li>
								表示されている月額は定価となります。実際の請求金額とは異なる場合があります。
							</li>
							<li>
								合計月額には割引も適用されています。
							</li>
							<li>
								長期契約プランご利用中のお客さまにつきましては、2024年9月以降に長期契約プラン以外のサービスを変更または追加された場合、システム仕様上一時的に、一部サービスにまとめて割引が適用された状態での明細表示となり、まとめて割引そのものの明細は非表示となります。2024年9月価格改定におきましては1世帯あたり360円（税込396円）以上の増額はございません。
							</li>
						</ul>
					</div>
					<WprContainer mode='工事情報.表示' view={this.view}>
						<div className='title_space itemTitle title_maru'>作業の希望日時</div>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>第一希望</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue value'>
									<MatTypography name='desiredDate1' view={this.view} className='itemUnit' style={{marginRight: '20px'}}/>
									<MatSelect name='selectTime1' vm='時間帯' view={this.view} className='itemSelect'/>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>第二希望</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue value'>
									<MatTypography name='desiredDate2' view={this.view} className='itemUnit' style={{marginRight: '20px'}}/>
									<MatSelect name='selectTime2' vm='時間帯' view={this.view} className='itemSelect'/>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>第三希望</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue value'>
									<MatTypography name='desiredDate3' view={this.view} className='itemUnit' style={{marginRight: '20px'}}/>
									<MatSelect name='selectTime3' vm='時間帯' view={this.view} className='itemSelect'/>
								</Grid>
							</Grid>	
						</div>
						<div className='itemTitle title_maru'>工事費</div>
						<div className='feeTable'>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>工事費</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<WprSpan name='cost' view={this.view} className='itemUnit'/>
									<MatTypography name='payUnit' className='itemUnit' view={this.view}/>
								</Grid>
							</Grid>
						</div>
						<div className='feeTable' style={{marginTop: '30px'}}>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>前日連絡先</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='beforeContact' className='itemUnit' view={this.view} />
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>当日連絡先</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='sameDayContact' className='itemUnit' view={this.view} />
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>当日立ち合い者</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='name' className='itemUnit' view={this.view} />
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>リフォーム</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='reformDisp' view={this.view} className='itemUnit'/>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>セキュリティ会社</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='securityDisp' view={this.view} className='itemUnit'/>
								</Grid>
							</Grid>
						</div>
					</WprContainer>
					{/* 工事費複数の場合 */}
					{/* <Card className='optionList'>
						<TableContainer>
							<Table className = 'top'>
								<colgroup>
									<col style={{width:'35%'}}/>
									<col style={{width:'65%'}}/>
								</colgroup>
								<TableBody className='radius'>
									<TableRow className='hedderCell'>
										<TableCell align='center' className='border_right' rowSpan={this.state.data.sentenceData} style={{borderBottom: 'none'}}>
											<Typography variant='body2' className='font_bold' style={{fontSize:'15px'}}>工事費</Typography>
										</TableCell>
									</TableRow>
									{this.renderList('')}
								</TableBody>
							</Table>
						</TableContainer>
					</Card> */}
					{/* <WprContainer mode='初期費用キャンペーン.有' view={this.view}>
						<div className='title_space'>
							<div className='itemTitle title_maru'>キャンペーン</div>
						</div>
						<div className='feeTable'>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>内容</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									<MatTypography name='campaignName' view={this.view} className='itemUnit'/>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>工事・配送費</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue'>
									-<MatTypography name='campaignDiscount' view={this.view} component='span' className='itemUnit'/><span className='itemUnit'>円</span>
								</Grid>
							</Grid>
						</div>
					</WprContainer> */}
				</div>
				<Divider className='select1_underSpace'/>
				<div className='pageFooter footer-space'>
					<div>
						<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
							この内容で申込む
							<NavigateNextIcon />
						</MatButton>
					</div>
					<div>
						<MatButton name='back' view={this.view} className='backButton' >
							<NavigateBeforeIcon />
							戻る
						</MatButton>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=ConfirmListModel
	 */
	public onCourseListModelRender(rowInfo: WprRowInfo): any {
		return (
			<MatTableRow view={this.view} row={rowInfo} className='hedderrow'>
				<TableCell>
					<MatTypography name='itemName' view={this.view} row={rowInfo}/>
				</TableCell>
				<TableCell className='courseDetail'>
					<MatTypography name='itemData' className='itemData' style={{fontWeight: '500'}} view={this.view} row={rowInfo}/>
					<MatTypography name='comment' className='itemData' view={this.view} row={rowInfo}/>
				</TableCell>
			</MatTableRow>
		);
	}
	/**
	 * リスト名=CampaignListModel
	 */
	// public onCampaignListModelRender(rowInfo: WprRowInfo): any {
	// 	return (
	// 		<MatTypography name='campaignName' style={{ color: '484848'}} view={this.view} row={rowInfo} className='campaignText'/>
	// 	);
	// }
	// --------------------------------------------------------------------------

}
