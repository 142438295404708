import React, { ReactNode } from 'react';

import { Typography, Grid, Divider } from '@material-ui/core';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import netIcon from '/shared/image/common/logo_iTSCOMNET.png';
import importantIcon from '/shared/image/common/important_purple.png';
import errorIcon from '/shared/image/common/icon_error.png';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { WprErrorContainer } from '../../../../../wpr-framework/component/controls/WprErrorContainer';
import { WprInput } from '../../../../../wpr-framework/component/controls/WprInput';
import { WprSpan } from '../../../../../wpr-framework/component/controls/WprSpan';
import { WprViewError } from '../../../../../wpr-framework/component/controls/WprViewError';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import styles from './LearningCancelEntry.scss';
import { LearningCancelEntryView } from './LearningCancelEntryView';
/**
 * イッツコムラーニング解約申込用コンポーネント
 */
export class LearningCancelEntry extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new LearningCancelEntryView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** アクセスアカウントパスワード変更ビュー情報 */
	public get viewInfo(): LearningCancelEntryView { return this.view as LearningCancelEntryView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	 */
	public getStylesObject(): any { return styles; }


	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('ErrorListModel', null, this.onErrorListModelRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='inContractArea'>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text'  view={this.view} />
								<Typography className='title_comment' style={{ fontWeight: '500' }}>ログインIDを入力し、「確認」ボタンをクリックしてください。</Typography>
							</div>
						</div>
						<WprContainer mode='エラー.表示' view={this.view}>
							<div className='errorArea'>
								<span className='parallel'>
									<img src={errorIcon} alt='' className='x_img'/>
									<div className='errorList'>
										{this.renderList('ErrorListModel')}
									</div>
								</span>
							</div>
						</WprContainer>
						<div className='feeTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>コース</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{ color: 'black' }}>
									<WprSpan name={'dispCourse'} className='itemUnit' view={this.view} style={{ color: '#484848', fontWeight: '550', fontsize: '40spx' }}></WprSpan>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<WprContainer mode='コース.デキタス' view={this.view}>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>デキタスログインID
											<span className='txtRequired'>*</span>
										</div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal2 courseValue'>
										<WprInput name='loginId' className='password' view={this.view} style={{ fontWeight: '500', fontSize: '14px'}}/>
									</Grid>
								</WprContainer>
								<WprContainer mode='コース.デジタネ' view={this.view}>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>デジタネログインID
											<span className='txtRequired'>*</span>
										</div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal2 courseValue'>
										<WprInput placeholder='例:*****@***.itscom.net' name='loginId' className='password' view={this.view} style={{ fontWeight: '500', fontSize: '14px'}}/>
									</Grid>
								</WprContainer>
							</Grid>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' className='orangeButton orangeButton_parts' view={this.view}>確認
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</>
		);
	}

	/**
	 * リスト名=ErrorListModel
	 */
	public onErrorListModelRender(rowInfo: WprRowInfo): any {
		return (
			<div>
				<MatTypography name='errorMessage' view={this.view} row={rowInfo}/>
			</div>
		);
	}

	// --------------------------------------------------------------------------
}
