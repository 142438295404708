
import { WprBaseViewMultiModeInfo } from '../../../../../../wpr-framework/mode/WprBaseViewMultiMode';
import { WprMultiMode } from '../../../../../../wpr-framework/mode/WprMultiMode';
import { WprControlInfo } from '../../../../../../wpr-framework/view/control/WprControlInfo';
import { WprDI_Control } from '../../../../../../wpr-framework/view/di/WprDI_Control';
import { ObjectUtil } from '../../../../../common/util/ObjectUtil';
import { AddLearningSelectView } from '../AddLearningSelectView';

/**
 * ログインID
 */
export class LoginIdCheck extends WprBaseViewMultiModeInfo<AddLearningSelectView> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_loginId: WprControlInfo	= null;	// ログインID
	
	// --------------------------------------------------------------------------

	
	
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('LoginIdCheckMode');
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('入力済み');
		list.push('未入力');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		this.addControl('next', { enabled: [ { mode: '入力済み', value: true }, { mode: 'other', value: false } ] });
		this.addControl('loginId', { check: true });
		// this.addControl('next1', { enabled: [ { mode: '次に遷移するボタン活性', value: true }, { mode: 'other', value: false } ] });
	}

	/**
	 * 画面の状態をチェックしてモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 */
	public onCheckMultiMode(curMode: WprMultiMode, newMode: WprMultiMode): void {
		if (this.getViewMode('ログインID') === '表示') {
			if (ObjectUtil.isNotNullOrEmptyStr(this.m_loginId.value)) {
				newMode.addMode('入力済み');
			} else {
				newMode.addMode('未入力');
			}
		} else {
			newMode.addMode('入力済み');
		}
	}
	// --------------------------------------------------------------------------
}
