import { WizardModel } from '../../models/WizardModel';
import { MypageWizardFlow } from './MypageWizardFlow';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { CommonCompleteView } from '../common/common_complete/CommonCompleteView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MypageBaseView } from '../base/MypageBaseView';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import { ContractServiceModel } from '../../service/models/apimodels/ContractServiceModel';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import { AddNetSelectView } from '../Srvnet/new/add_net_select/AddNetSelectView';
import { AddNetConfirmView } from '../Srvnet/new/add_net_confirm/AddNetConfirmView';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import { MGeneral } from '../../service/models/entity/MGeneral';
import { AddNetSettingView } from '../Srvnet/new/add_net_setting/AddNetSettingView';
import { Customer } from '../../models/bss/customer/Customer';
import { ServiceModel } from '../../service/models/apimodels/ServiceModel';
import { ObjectUtil } from '../../common/util/ObjectUtil';
import { ContractInfo } from '../Srvnet/revise/mod_net_select/models/ContractInfo';
import { YakkanListModel } from '../../service/models/apimodels/YakkanListModel';
import { CommonAgreeView } from '../common/common_agree/CommonAgreeView';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import { RegistContractRequest } from '../../service/models/dto/mypagerenewal/RegistContractRequest';
import { AddOthernetConfirmView } from '../Srvnet/new/add_othernet_confirm/AddOthernetConfirmView';
import MypageCodeTypeConstants from '../../common/MypageCodeTypeConstants';
import { AddOthernetSelectView } from '../Srvnet/new/add_othernet_select/AddOthernetSelectView';
import { CourseListModel } from '../../models/mypage/net/CourseListModel';
import { NetFlowModel } from '../../models/mypage/net/NetFlowModel';
import { CustomerSupportView } from '../common/customer_support/CustomerSupportView';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';
import MypageYakkanConstants from '../../common/MypageYakkanConstants';
/**
 * ITSCOM HOME_追加ウィザードクラス
 */
export class NetServiceNewWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト(通常)
	private m_OtherWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト(その他サービス(その他))
	private m_KattobiWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト(その他サービス(かっとびプラン))
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------
	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new AddNetSelectView().name;
		// 遷移パターン(通常)
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: 'コース選択', viewName: this.m_StartViewName, path: 'add_net_select'});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: 'コース内容の選択', viewName: new AddNetSettingView().name, path: 'add_net_setting'});
        this.m_DefaultWizardFlow.push({stepNum: 3, title: '申込み内容の確認', viewName: new AddNetConfirmView().name, path: 'add_net_confirm'});
        this.m_DefaultWizardFlow.push({stepNum: 4, title: '契約約款', viewName: new CommonAgreeView().name, path: 'service_net_useagree'});
        this.m_DefaultWizardFlow.push({stepNum: 5, title: '申込み完了', viewName: new CommonCompleteView().name, path: 'add_net_complete'});
		// 遷移パターン(その他サービス(その他))
		this.m_OtherWizardFlow = new Array();
		this.m_OtherWizardFlow.push(this.m_DefaultWizardFlow[0]);
		this.m_OtherWizardFlow.push(this.m_DefaultWizardFlow[1]);
		this.m_OtherWizardFlow.push({stepNum: 2, title: 'コース内容の選択', viewName: new AddOthernetSelectView().name, path: 'add_othernet_select'});
		this.m_OtherWizardFlow.push(this.m_DefaultWizardFlow[3]);
		this.m_OtherWizardFlow.push(this.m_DefaultWizardFlow[4]);
		this.m_OtherWizardFlow.push(this.m_DefaultWizardFlow[5]);
		// 遷移パターン(その他サービス(かっとびプラン))
		this.m_KattobiWizardFlow = new Array();
		this.m_KattobiWizardFlow.push(this.m_DefaultWizardFlow[0]);
		this.m_KattobiWizardFlow.push(this.m_DefaultWizardFlow[1]);
		this.m_KattobiWizardFlow.push({stepNum: 2, title: 'コース内容の選択', viewName: new AddOthernetSelectView().name, path: 'add_othernet_select'});
		this.m_KattobiWizardFlow.push({stepNum: 3, title: '申込み内容の確認', viewName: new AddOthernetConfirmView().name, path: 'add_othernet_confirm'});
		this.m_KattobiWizardFlow.push(this.m_DefaultWizardFlow[4]);
		this.m_KattobiWizardFlow.push({stepNum: 5, title: '申込み完了', viewName: new CommonCompleteView().name, path: 'add_kattobi_complete'});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		let cacheData: NetServiceNewWizardModel = mngr.getCache();
		// コース内容選択
		if (cacheData.m_NetFlowModel.defaultFlg) 
			return this.m_DefaultWizardFlow;
		// その他サービス(その他)
		if (cacheData.m_NetFlowModel.otherFlg)
			return this.m_OtherWizardFlow;
		// その他サービス(かっとびプラン)
		if (cacheData.m_NetFlowModel.kattobiFlg)
			return this.m_KattobiWizardFlow;
		return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.serviceKey = new Array();
		req.masterFlg = true;
		req.paymentFlg = true;
		req.contractFlg = true;
		req.ownerFlg = true;
		req.buildingFlg = true;
		req.serviceLineFlg = true;
		req.areaFlg = true;
		req.displayList = new Array();
		req.commonList = new Array();
		const commonY = new MCommon('コース(約款)', 'namecd', 'course_net', null);
		req.commonList.push(commonY);
		if (ObjectUtil.isNotNullOrUndefined(mngr.getViewInfo())) {
			/*約款出しわけ処理 */
			req.yakkanList = new Array();
			let yakkan_1 : YakkanModel = new YakkanModel(MypageYakkanConstants.ITSCOM_SERVICE);
			let yakkan_2 : YakkanModel = new YakkanModel(MypageYakkanConstants.ITSCOM_PRICE);
			let yakkan_7 : YakkanModel = new YakkanModel(MypageYakkanConstants.INTENET_CATV);
			let yakkan_8 : YakkanModel = new YakkanModel(MypageYakkanConstants.IMPORTANT_HIKARI);
			let yakkan_9 : YakkanModel = new YakkanModel(MypageYakkanConstants.ITSCOM_HIKARI_INTERNET);
			let yakkan_19 : YakkanModel = new YakkanModel(MypageYakkanConstants.ITSCOM_SERVICE_IMPORTANT);
			let cacheData: NetServiceNewWizardModel = mngr.getCache();
			let yakkanList = mngr.getMCommonList(view, 'namecd', 'course_net', null, null);
			yakkan_19.select = true // 重要説明事項
			yakkan_1.select = true;	// 共通約款
			yakkan_2.select = true;	// 料金表
			let noHirariFlg = false;
			// 通常コース判定
			const normalList = new Array();
			const normalKeyList: string[] = ['m160', 'wide', 'plus', 'just', 'wide2year', 'm1602year'];
			for (const key of normalKeyList) {
				const normal = yakkanList.filter(data => data.category3 === key);
				if (normal && normal.length > 0)
					normalList.push(normal[0]);
			}
			for (const normal of normalList) {
				if (cacheData.m_NetInfo.serviceCd === normal.commonValue) {
					yakkan_7.select = true;
					noHirariFlg = true;
					break;
				}
			}
			// かっとびコース判定
			const kattobiList = new Array();
			const kattobiKeyList: string[] = ['fG', 'fHS', 'fT', 'mG', 'mHS', 'mT'];
			for (const key of kattobiKeyList) {
				const kattobi = yakkanList.filter(data => data.category3 === key);
				if (kattobi && kattobi.length)
					kattobiList.push(kattobi[0]);
			}
			for (const kattobi of kattobiList) {
				if (cacheData.m_NetInfo.serviceCd === kattobi.commonValue) {
					yakkan_8.select = true;
					noHirariFlg = true;
					break;
				}
			}
			// 通常コース、かっとびコース以外でFTTHの場合、イッツコム光約款を表示
			const customer = mngr.getViewInfo().customer;
			if (!noHirariFlg && customer.building[0].buildingLineTypeCD === '2')
				yakkan_9.select = true;
			// 非表示リスト判定
			const hiddenList = new Array();
			const hiddenKeyList: string[] = ['fl_ADSL', 'fl_FTTH', 'fl_DIAL', 'au_FTTH', 'value'];
			for (const key of hiddenKeyList) {
				const hidden = yakkanList.filter(data => data.category3 === key);
				if (hidden && hidden.length > 0) 
					hiddenList.push(hidden[0]);
			}
			for (const hidden of hiddenList) {
				if (cacheData.m_NetInfo.serviceCd === hidden.commonValue) {
					yakkan_7.select = false;
					yakkan_8.select = false;
					yakkan_9.select = false;
					break;
				}
			}
			const yakkanModelList : YakkanModel[] = new Array();
			yakkanModelList.push(yakkan_19);
			yakkanModelList.push(yakkan_1);
			yakkanModelList.push(yakkan_7);
			yakkanModelList.push(yakkan_8);
			yakkanModelList.push(yakkan_9);
			yakkanModelList.push(yakkan_2);
			for (const yakkanModel of yakkanModelList) {
				if (yakkanModel.select)
					req.yakkanList.push(new MGeneral('040', yakkanModel.yakkanNum));	// ★'040'の定数探す
			}
		}
		// コース
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMNET_COURSE_FTTH_APART_FTTH));	// FTTH/集合/物件プラン(FTTH光回線orFTTH高速光配線)
		const service1 = new ContractServiceModel();
		service1.serviceCd = MypageDispdivcdConstants.ITSCOMNET_COURSE_FTTH_APART_FTTH;
		req.serviceKey.push(service1);
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMNET_COURSE_FTTH_OTHER));		// FTTH/集合・戸建賃貸/物件プラン(その他)
		const service2 = new ContractServiceModel();
		service2.serviceCd = MypageDispdivcdConstants.ITSCOMNET_COURSE_FTTH_OTHER;
		req.serviceKey.push(service2);
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMNET_COURSE_FTTH_DETACH));		// FTTH/戸建、FTTH/戸建賃貸/物件プラン(FTTH賃貸戸建用)
		const service3 = new ContractServiceModel();
		service3.serviceCd = MypageDispdivcdConstants.ITSCOMNET_COURSE_FTTH_DETACH;
		req.serviceKey.push(service3);
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMNET_COURSE_OTHER));			// FTTH以外
		const service4 = new ContractServiceModel();
		service4.serviceCd = MypageDispdivcdConstants.ITSCOMNET_COURSE_OTHER;
		req.serviceKey.push(service4);
		// 回線種別名称
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMNET_LINETYPE));
		// 物件プラン
		const common1 = new MCommon('物件プラン取得', 'building_plan');
		req.commonList.push(common1);
		// オーナー契約IH
		const common2 = new MCommon('オーナー契約IHリスト', 'IH', 'OWNER_SCD');
		req.commonList.push(common2);
		// コースCD：FTTH光回線
		const common3 = new MCommon('コースCDFTTH光回線', 'srvnet', 'hikari_line_course');
		req.commonList.push(common3);
		// 店子コース
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMNET_COURSE_TANAKO_OVERWRITE));	// 上書きコース
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMNET_COURSE_TANAKO_ADD));		// 追加コース
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ACCESSPASS_SERVICE));				// 店子コース名称
		const common9 = new MCommon('ケーブルモデム(Wi-Fi)名称','cable_modem_wifi');
		req.commonList.push(common9);
		const common10 = new MCommon('ケーブルモデム名称','cable_modem_wired');
		req.commonList.push(common10);
		// お得パック
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_OTOKUNAME));	//お得パックサービス
		req.commonList.push(new MCommon('お得パックの取得', 'otoku', 'plan_cd'));
		// その他コース
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMNET_OTHERCOURSE_NAME));
		const service5 = new ContractServiceModel();
		service5.serviceCd = MypageDispdivcdConstants.ITSCOMNET_OTHERCOURSE_NAME;
		req.serviceKey.push(service5);
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.ITSCOMHOME_TERMINALSTATUS));
		const common = new MCommon('かっとびバリュー','namecd', 'course_net','value');
		req.commonList.push(common);
		const common4 = new MCommon('','srvnet', 'ftth_wire_sv','wire_disp_sv');
		req.commonList.push(common4);
		// とことんサポート
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TOKOTON_SUPPORT_COURSE_NAME));
		// 完了処理
		const common5 = new MCommon('equipmentTypeCd', 'Add_net', 'equ', 'req');
		req.commonList.push(common5);
		const common6 = new MCommon('FTTHケーブルモデム(Wi-Fi)コース', 'srvnet', 'ftth_wireless_sv', 'wireless_disp_sv');
		req.commonList.push(common6);
		const common7 = new MCommon('HFCケーブルモデム(Wi-Fi)コース', 'srvnet', 'hfc_wireless_sv', 'wireless_disp_sv');
		req.commonList.push(common7);
		const common8 = new MCommon('optionCd', 'srvnet', 'wire_sv');
		req.commonList.push(common8);
		req.generalList = new Array();
		req.generalList.push(new MGeneral(MypageCodeTypeConstants.INTRODUCTION_CODE, null));
		// ネット10Gサービス
		req.generalList.push(new MGeneral(MypageCodeTypeConstants.NET_TEN_SERVICE, null));
		// IPオプション
		const ipList: string[] = ['fl_ADSL', 'fl_FTTH', 'fl_DIAL', 'value'];
		for(const ip of ipList) {
			const common = new MCommon('IPオプション判定', 'namecd', 'course_net', ip);
			req.commonList.push(common);
		}
		// 支払い方法
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.PAYMENT_DISPNAME));
		return req;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): NetServiceNewWizardModel {
		let cacheData = new NetServiceNewWizardModel();
		cacheData.m_NetFlowModel = new NetFlowModel();
		cacheData.m_NetInfo = new CourseListModel();
		cacheData.m_OtherCourseListModel = new Array();
		cacheData.m_ContractInfo = new ContractInfo();
		cacheData.m_CourseListModel = new Array();
		cacheData.m_ConfirmListModel = new Array();
		cacheData.m_RelocateServiceList = new Array();
		cacheData.m_TanakoList = new Array();
		return cacheData;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr, view: MypageBaseView): string {
		const viewCode = '002001';
		//トリプルの場合：追加判定
		if (view.m_Name === 'AddNetSelectView') {
			const customer = mngr.getViewInfo().customer;
			const building = mngr.getViewInfo().buildingList[0];
			//特定導入方式の場合:001
			if (customer.building[0].buildingTypeCD !== '3' && mngr.getViewInfo().searchBuildingList && mngr.getViewInfo().searchBuildingList.length > 0) {
				let flg = false;
				for (const introCode of mngr.getMGenralList(view, MypageCodeTypeConstants.INTRODUCTION_CODE, '')) {
					if (mngr.getViewInfo().searchBuildingList.length > 0 && mngr.getViewInfo().searchBuildingList[0].buildingImplementMethodCD === introCode.name1)
						flg = true;
				}
				if (!flg)
					return '/customer_support?no=001' + viewCode;
			}
			//戸建賃貸の場合:002
			if (customer.building[0].buildingTypeCD === '2') {
				return '/customer_support?no=002' + viewCode;
			}
			//2軸物件の対象の場合:003
			else if (building.ftthTokkiMethod === '3' && building.bunkatuMotoTatemonoNum) {
				return '/customer_support?no=003' + viewCode;
			}
			//回線種別：その他の場合:004
			else if (customer.building[0].buildingLineTypeCD === '3') {
				return '/customer_support?no=004' + viewCode;
			}
			// マイグレの場合
			else if (mngr.isMigration(view)) {
				return '/customer_support?no=007' + viewCode;
			}
		}
		else if (view.m_Name === 'AddNetConfirmView') {
			//設置場所：006
			const location = /応接室|職場|休憩室|社長室/;
			if (location.test(mngr.getCache().m_NetInfo.location)) {
				view.setStorageItem('c_customerSupportCache', mngr.getCache().m_NetInfo.location);
				return '/customer_support?no=006' + viewCode;
			}
		}
	}

	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistDataApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistContractRequest{
		const cacheData: NetServiceNewWizardModel = mngr.getCache();
		const req: RegistContractRequest = new RegistContractRequest();
		// 登録処理
		if (cacheData.m_NetInfo.directflg) {
			const equ = mngr.getMCommonList(view, 'Add_net', 'equ', 'req', null);
			req.customerId = mngr.getParams().customerId;
			req.equipmentTypeCd = equ[0].commonValue;
			// req.rentalFlag = '1';
			req.rentalFlag = '9';
			req.startDate = '即日';
			req.serviceCd = cacheData.m_NetInfo.serviceCd;
			req.chargeTypeCd = '001';
			req.infrastructureTypeCd = '060';
			const deviceType = cacheData.m_ConfirmListModel.filter(data => data.itemName === '端末の種類');
			if (ObjectUtil.isNotNullOrUndefined(deviceType) && deviceType.length > 0) {
				if (deviceType[0].itemData === 'ケーブルモデム（wi-fi）') {
					let optFlg = true;
					const ftthCableList =  mngr.getMCommonList(view, 'srvnet', 'ftth_wireless_sv', 'wireless_disp_sv', null);
					const hfcCableList = mngr.getMCommonList(view, 'srvnet', 'hfc_wireless_sv', 'wireless_disp_sv', null);
					// FTTHケーブルモデム
					for (const ftthCable of ftthCableList) {
						if (ftthCable.commonValue === cacheData.m_NetInfo.serviceCd) {
							optFlg = false;
							break;
						}
					}
					// HFCケーブルモデム
					if (optFlg) {
						for (const hfcCable of hfcCableList) {
							if (hfcCable.commonValue === cacheData.m_NetInfo.serviceCd) {
								optFlg = false;
								break;
							}
						}
					}
					// FTTHケーブルモデム,HFCケーブルモデム以外の場合
					if (optFlg) {
						const optCd = mngr.getMCommonList(view,'srvnet', 'wire_sv', null, null);
						req.optionList = new Array;
						req.optionList.push(optCd[0].commonValue);
					}
				}
			}
			return req;
		}
		else
			return null;
	}

	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView, resultCd: string): RegistInfoRequest{
		const req: RegistInfoRequest = new RegistInfoRequest();
		const cacheData: NetServiceNewWizardModel = mngr.getCache();
		const customer = mngr.getViewInfo().customer;
		let errorMsg = null;
		let directResult = '仮申込み';
		if (resultCd) {
			const contenueList = new Array('2010000', '3010000', '3010002', '3030000', '3050000', '3150000', '3090003');
			if (resultCd === '0') {
				directResult = '直接申込';
			}
			else if (contenueList.includes(resultCd)) {
				switch (resultCd) {
					case '2010000': //customerID＝無効
					errorMsg = "お客様IDが無効エラー";
						break;
					case '3010000': //oldContractID＝無効
					errorMsg = "契約番号が無効エラー";
						break;
					case '3010002': //serviceCD＝登録不可
					errorMsg = "サービス登録不可エラー";
						break;
					case '3030000': //chargeTypeCD＝登録不可
					errorMsg = "料金種別登録不可エラー";
						break;
					case '3050000': //equipmentTypeCD＝登録不可
					errorMsg = "端末種別登録不可エラー";
						break;
					case '3150000': //infrastructureTypeCD＝登録不可
					errorMsg = "インフラ種別登録不可エラー";
						break;
					case '3090003': //optionCD＝登録不可
					errorMsg = "オプション登録不可エラー";
						break;
				}
				directResult = '仮申込み';
			}
		}
		req.customerId = mngr.getParams().customerId;

		// サービスCD設定
		let serviceCd = null;
		if (ObjectUtil.isNotNullOrUndefined(cacheData.m_NetInfo.tanakoServiceCd))
			serviceCd = cacheData.m_NetInfo.tanakoServiceCd;
		else
			serviceCd = cacheData.m_NetInfo.serviceCd;
		const netFletsDirectList: string[] = ['fl_ADSL', 'fl_FTTH', 'fl_DIAL'];
		// 顧客メール
		for (const netFletsDirect of netFletsDirectList) {
			const common = mngr.getMCommonList(view, 'namecd', 'course_net', netFletsDirect, null, null);
			if (common[0].commonValue === serviceCd) {
				const mailconstants = MAIL_CONSTANTS.find (mail => {
					return mail.view === 'ネット_追加_フレッツ';
				});
				req.customerMailId = mailconstants.customerMail;
				req.systemMailId = mailconstants.systemMail;
				req.inquiryId = mailconstants.inquiryMail;
				break;
			}
		}
		if (ObjectUtil.isNullOrUndefined(req.customerMailId)) {
			const mailconstants = MAIL_CONSTANTS.find (mail => {
				return mail.view === 'ネット_追加';
			});
			req.customerMailId = mailconstants.customerMail;
			req.systemMailId = mailconstants.systemMail;
			req.inquiryId = mailconstants.inquiryMail;
		}
		// 仮申込み処理
		if (directResult === '仮申込み') {
			req.systemInfo = new InquiryMailInfo();
			req.systemInfo.appService = cacheData.m_NetInfo.cDispnamep;
			let deviceInfo = '';
			if (ObjectUtil.isNotNullOrUndefined(cacheData.m_NetInfo.equipmentSelect)) {
				if (cacheData.m_NetInfo.equipmentProvidingCheck)
					deviceInfo = cacheData.m_NetInfo.equipmentSelect + ' レンタル';
				else
					deviceInfo = cacheData.m_NetInfo.equipmentSelect;
			}
			req.systemInfo.contentList = new Array();
			req.systemInfo.contentList.push('お申込みサービス　：　' + cacheData.m_NetInfo.cDispnamep);
			req.systemInfo.contentList.push('端末の種類　　： ' + deviceInfo);
			// お得パック
			if (cacheData.m_NetInfo.otokuFlg)
				req.systemInfo.otokuStatus = '契約中';
			else if (cacheData.m_NetInfo.yesOtoku)
				req.systemInfo.otokuStatus = '未契約(お得パック希望する)';
			else if (cacheData.m_NetInfo.noOtoku)
				req.systemInfo.otokuStatus = '未契約(お得パック希望しない)';
			else
				req.systemInfo.otokuStatus = '';
			// 工事
			if (cacheData.m_NetInfo.constractionFlg) {
				req.systemInfo.constractionFlg = true;
				req.systemInfo.desiredDate1 = cacheData.m_NetInfo.desiredDate1.replace(/-/g, '/');
				req.systemInfo.desiredDate2 = cacheData.m_NetInfo.desiredDate2.replace(/-/g, '/');
				req.systemInfo.desiredDate3 = cacheData.m_NetInfo.desiredDate3.replace(/-/g, '/');
				req.systemInfo.selectTime1 = view.getValueMap('時間帯').getValueFormKey(cacheData.m_NetInfo.selectTime1);
				req.systemInfo.selectTime2 = view.getValueMap('時間帯').getValueFormKey(cacheData.m_NetInfo.selectTime2);
				req.systemInfo.selectTime3 = view.getValueMap('時間帯').getValueFormKey(cacheData.m_NetInfo.selectTime3);
				// 希望場所
				if (cacheData.m_NetInfo.floor)
					req.systemInfo.location = cacheData.m_NetInfo.floor + ' ' + cacheData.m_NetInfo.location;
				else
					req.systemInfo.location = cacheData.m_NetInfo.location;
				if (cacheData.m_NetInfo.location === 'その他')
					req.systemInfo.location += ( ' ' + cacheData.m_NetInfo.comment);
				// 工事費
				if (customer.building[0].buildingTypeCD === '3')
					req.systemInfo.constructionCost = cacheData.m_NetInfo.cost;
				else {
					if (cacheData.m_NetInfo.payUnit)
						req.systemInfo.constructionCost = Number(cacheData.m_NetInfo.cost).toLocaleString() + cacheData.m_NetInfo.payUnit;
					else
						req.systemInfo.constructionCost = cacheData.m_NetInfo.cost;
				}
				if (cacheData.m_NetInfo.securityComment)
					req.systemInfo.securityComment = cacheData.m_NetInfo.securityComment;
				else
					req.systemInfo.securityComment = 'なし';
				// リフォーム有無
				if (cacheData.m_NetInfo.reformFlg)
					req.systemInfo.reform = 'あり';
				else
					req.systemInfo.reform = 'なし';
				// 連絡先
				req.systemInfo.beforeContact = cacheData.m_NetInfo.beforeContact;
				req.systemInfo.sameDayContact = cacheData.m_NetInfo.sameDayContact;
				req.systemInfo.witness = cacheData.m_NetInfo.name;
			}
			// キャンペーン
			if (cacheData.m_NetInfo.campaignCode) {
				req.systemInfo.campaignFlg = true;
				req.systemInfo.campaignGroupName = cacheData.m_NetInfo.campaignGroup.campaignGroupName;
				req.systemInfo.campaignList = cacheData.m_NetInfo.campaignList;
			}
			// 支払い方法
			const payment = mngr.getMDisplayList(view, MypageDispdivcdConstants.PAYMENT_DISPNAME, '').filter(data => data.namecd === mngr.getViewInfo().customerPayment[0].payment[0].paymentTypeCD);
			if (payment && payment.length > 0)
				req.systemInfo.paymentDispname = payment[0].dispnamep;
			else
				req.systemInfo.paymentDispname = '';
		}
		return req;
	}
}

/**
 * 画面ウィザードAPI用モデル
 */
export class NetServiceNewWizardModel {
	// public 変数	-----------------------------------------------------------
		public m_NetFlowModel: NetFlowModel = null;
		public m_ContractInfo: ContractInfo = null;
		public m_TanakoList: CourseListModel[] = null;				// 店子コースリスト
		public m_NetInfo: CourseListModel = null;					// ネット画面情報
		public m_YakkanListModel: YakkanListModel[]	= null; 		// 約款リストモデル
		public m_CourseListModel: CourseListModel[]	= null;			// コースリスト
		public m_ConfirmListModel: CourseListModel[] = null;		// 追加申込み内容
		public m_RelocateServiceList: CourseListModel[]	= null;		// 移設日時リスト
		public m_OtherCourseListModel: CourseListModel[] = null;	// その他コースリスト
	// ------------------------------------------------------------------------

	// public メソッド --------------------------------------------------------
	/**
	 * コース情報取得
	 * @param view 
	 * @param customer 
	 * @param buildingType 
	 */
	public getCourseInfo(view: MypageBaseView, customer: Customer, buildingPlan: MCommon): ServiceModel[] {
		let courseService: ServiceModel[] = null;
		// FTTHの場合
		if (customer.building[0].buildingLineTypeCD === '2') {
			// 集合
			if (customer.building[0].buildingTypeCD === '1') {
				if (buildingPlan.commonValue === '1' || buildingPlan.commonValue === '2')
					courseService = view.mngr.getServiceList(view, MypageDispdivcdConstants.ITSCOMNET_COURSE_FTTH_APART_FTTH);
				else
					courseService = view.mngr.getServiceList(view ,MypageDispdivcdConstants.ITSCOMNET_COURSE_FTTH_OTHER);
			}
			// 戸建賃貸
			else if (customer.building[0].buildingTypeCD === '2') {
				if (buildingPlan.commonValue === '3')
					courseService = view.mngr.getServiceList(view, MypageDispdivcdConstants.ITSCOMNET_COURSE_FTTH_DETACH);
				else
					courseService = view.mngr.getServiceList(view ,MypageDispdivcdConstants.ITSCOMNET_COURSE_FTTH_OTHER);
			}
			// 戸建
			else if (customer.building[0].buildingTypeCD === '3')
				courseService = view.mngr.getServiceList(view, MypageDispdivcdConstants.ITSCOMNET_COURSE_FTTH_DETACH);
		}
		// FTTH以外の場合
		else
			courseService = view.mngr.getServiceList(view, MypageDispdivcdConstants.ITSCOMNET_COURSE_OTHER);
		return courseService;
	}

	/**
	 * 回線種別取得
	 * @param view 
	 * @param courseService 
	 * @returns 
	 */
	public getLineType(view: MypageBaseView, courseService: ServiceModel[]): MDisplay {
		const lineTypeList = view.mngr.getMDisplayList(view, MypageDispdivcdConstants.ITSCOMNET_LINETYPE, '');
		if (ObjectUtil.isNotNullOrUndefined(courseService) && courseService.length > 0) {
			const lineType = lineTypeList.filter(row => row.namecd === courseService[0].display.genecd);
			return lineType[0];
		}
		return null;
	}

	/**
	 * コースリスト(PC/SP)設定
	 */
	public setCourseDisplay(view: MypageBaseView) {
		if (window.outerWidth > 440) {
			view.setViewMode('コース','PC');
			view.setViewMode('その他コース','PC');
		}
		else {
			view.setViewMode('コース','SP');
			view.setViewMode('その他コース','SP');
		}
	}
	// ------------------------------------------------------------------------
}
/**
 * 約款モデル
 */
export class YakkanModel {
	// public 変数	-----------------------------------------------------------
		public yakkanNum: string = null;
		public select: boolean = false;
	// ------------------------------------------------------------------------
	public constructor(num : string) {
		this.yakkanNum = num;
	}
}
