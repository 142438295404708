import React, { ReactNode } from 'react';

import { Typography, Grid, Divider } from '@material-ui/core';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import importantIcon from '/shared/image/common/important_purple.png';
import netIcon from '/shared/image/common/logo_iTSCOMTV.png';
import { AddLearningConfirmView } from './AddLearningConfirmView';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { WprSpan } from '../../../../../wpr-framework/component/controls/WprSpan';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import styles from './AddLearningConfirm.scss';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
/**
 * イッツコムラーニング申込確認画面用コンポーネント
 */
export class AddLearningConfirm extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new AddLearningConfirmView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** マガジン申込画面ビュー情報 */
	public get viewInfo(): AddLearningConfirmView { return this.view as AddLearningConfirmView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	 */
	public getStylesObject(): any { return styles; }


	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='inContractArea'>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text'  view={this.view} />
								<Typography className='title_comment' style={{ fontWeight: '500' }}>以下の内容でよろしければ、「申込む」ボタンをクリックしてください。</Typography>
							</div>
						</div>
						<div className='feeTable' style={{ marginBottom: '0px'}}>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>申込みコース</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{ color: 'black' }}>
									<WprSpan name={'dispCourse'} className='itemUnit' view={this.view} style={{ color: '#484848', fontWeight: '550', fontsize: '40spx' }}></WprSpan>
								</Grid>
							</Grid>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>料金</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal courseValue' style={{ color: 'black' }}>
									<WprSpan name={'amount'} className='itemUnit' view={this.view} style={{ color: '#484848', fontWeight: '550', fontsize: '40spx' }}></WprSpan>
								</Grid>
							</Grid>
							<WprContainer mode='コース.デジタネ' view={this.view}>
								<Grid container className='feeRow'>
									<Grid item xs={12} md={4} className='feeTitle'>
										<div>デジタネログインID</div>
									</Grid>
									<Grid item xs={12} md={8} className='feeVal courseValue' style={{ color: 'black' }}>
										<WprSpan name={'loginId'} className='itemUnit' view={this.view} style={{ color: '#484848', fontWeight: '550', fontsize: '40spx', overflowWrap: 'break-word'}}></WprSpan>
									</Grid>
								</Grid>
							</WprContainer>
						</div>
						<WprContainer mode='コース.デキタス' view={this.view}>
							<div className='attention_fontSize'>
								<ul style={{ listStyle: 'none',marginBottom: '0px'}} className='text'>
									<li>
										当月●デキタスのご利用について
									</li>
									<li>
										【サービスのご利用について】
									</li>
								</ul>
								<ul className='ul_ten attention_fontSize margin'>
									<li>
										サービスがご利用可能となりましたら、当社よりメールにて連絡いたします。イッツコムマイページにてデキタスサービス用のIDおよび初期パスワードをご確認のうえ、ログインをお願いいたします。
									</li>
									<li>
										17時までに送信されたお申し込みにつきましては、翌日中よりサービスをご利用いただけます。17時以降のお申し込みの場合、翌々日よりご利用いただけます。
									</li>
									<li>
										利用開始日の属する月から３ヵ月間を最低利用期間とします。
									</li>
								</ul>
								<ul style={{ listStyle: 'none',marginBottom: '0px'}} className='text'>
									<li>
										【初期パスワードの変更について】
									</li>
								</ul>
								<ul className='ul_ten attention_fontSize margin divider'>
									<li>
										初期パスワードは初回ログイン時後に速やかにご変更ください。
									</li>
								</ul>
							</div>
						</WprContainer>
						<WprContainer mode='コース.デジタネ' view={this.view}>
							<div className='attention_fontSize'>
								<ul style={{ listStyle: 'none',marginBottom: '0px'}} className='text'>
									<li>
										●デジタネのご利用について
									</li>
									<li>
										【サービスのご利用について】
									</li>
								</ul>
								<ul className='ul_ten attention_fontSize margin'>
									<li>
										17時までに送信されたお申し込みにつきましては、翌日中よりサービスをご利用いただけます。17時以降のお申し込みの場合、翌々日よりご利用いただけますので専用サイトよりログインし利用開始をお願いいたします。
									</li>
									<li>
										無料体験でご利用されていたログインID、パスワードは継続してご利用いただけます。
									</li>
								</ul>
								<ul className='ul_kome attention_fontSize margin'>
									<li>
										無料体験期間中のお申し込みの場合でも、ご利用開始日より有料契約へ変更となり、ご利用開始月より請求開始となります。
									</li>
									<li>
										月払いプランは加入月を含む2か月間の最低利用期間の設定がございます。
									</li>
									<li>
										年払いプランの契約期間は1年間となり、ご解約は契約期間満了月付での受付となります。なお、解約のお申し出がない場合は契約は自動更新となります。
									</li>
								</ul>
								<ul style={{ listStyle: 'none',paddingLeft: 'unset'}} className='margin divider'>
									<li>
										また、契約期間中にプランの料金に変更があった場合でも、残期間に応じた追加の請求および減額は行わないことを、あらかじめご了承ください。
									</li>
								</ul>
							</div>
						</WprContainer>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' className='orangeButton orangeButton_parts' view={this.view}>この内容で申込む
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
