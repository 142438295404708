import { WprBaseViewListMultiModeInfo } from "../../../../../../wpr-framework/mode/WprBaseViewListMultiModeInfo";
import { WprMultiMode } from "../../../../../../wpr-framework/mode/WprMultiMode";
import { WprControlInfo } from "../../../../../../wpr-framework/view/control/WprControlInfo";
import { WprDI_Control } from "../../../../../../wpr-framework/view/di/WprDI_Control";
import { MGeneral } from "../../../../../service/models/entity/MGeneral";
import { AddLearningAgreeView } from "../AddLearningAgreeView";


/**
 * 約款チェックリストモード
 */
export class YakkaCheckListMode extends WprBaseViewListMultiModeInfo<AddLearningAgreeView, MGeneral> {
	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_yakkanName: WprControlInfo	= null;	// 名称1
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('YakkanListModel');
	}
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('選択済');
		list.push('重要説明事項');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		// this.addControl('yakkan', {class: [ { mode: '重要説明事項', class: 'important' }, { mode: 'other', class: '' } ] });
		this.addControl('yakkan', { check: true, class: [ { mode: '選択済', class: 'checked' }, { mode: '重要説明事項', class: 'important' }, { mode: 'other', class: '' } ] });
	}

	/**
	 * ビューの状態をチェックして行のモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 * @param row 行データ
	 */
	public onCheckListMultiMode(curMode: WprMultiMode, newMode: WprMultiMode, row: MGeneral): void {
		newMode.addMode('重要説明事項');
		if (row.flag1)
			newMode.addMode('選択済');
	}
	// --------------------------------------------------------------------------
}
