import { WprListData } from "../../../../../wpr-framework/view/deco/WprListData";
import { WprDI_ScopeModel } from "../../../../../wpr-framework/view/di/WprDI_ScopeModel";
import MypageDispdivcdConstants from "../../../../common/MypageDispdivcdConstants";
import { ObjectUtil } from "../../../../common/util/ObjectUtil";
import { Contract } from "../../../../models/bss/customer/Contract";
import { Customer } from "../../../../models/bss/customer/Customer";
import { ItscomNetService } from "../../../../service/ItscomNetService";
import { ServiceModel } from "../../../../service/models/apimodels/ServiceModel";
import { GetNetInfoRequest } from "../../../../service/models/dto/mypagerenewal/GetNetInfoRequest";
import { MCommon } from "../../../../service/models/entity/primary/MCommon";
import { MDisplay } from "../../../../service/models/entity/secondary/MDisplay";
import { MypageBaseView } from "../../../base/MypageBaseView";
import { ProgressBarData } from "../../../common/progress_bar/ProgressBarView";
import { MypageWizardMngr } from "../../../wizard/models/MypageWizardMngr";
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { CourseListMode } from './mode/CourseListMode';
import { NetServiceNewWizardModel } from "../../../wizard/NetServiceNewWizardFlow";
import { CourseListModel } from "../../../../models/mypage/net/CourseListModel";
import { NetFlowModel } from "../../../../models/mypage/net/NetFlowModel";
import { learningCourseListModel } from "../../../../models/mypage/itscomLearning/learningCourseListModel";
import { LearningServiceNewWizardModel } from "../../../wizard/ItscomLearningNewWizardFlow";
import { WprModel } from "../../../../../wpr-framework/view/deco/WprModel";
import { ErrorModel } from "../../../../models/mypage/smart/ErrorModel";
import { WprValidError } from "../../../../../wpr-framework/view/validation/WprValidError";
import { WprControlInfo } from "../../../../../wpr-framework/view/control/WprControlInfo";
import { WprDI_Control } from "../../../../../wpr-framework/view/di/WprDI_Control";
import { LoginIdCheck } from "./mode/MultiCheck";
import { WprMailAddressValidator } from "../../../../../wpr-framework/view/validation/validator/WprMailAddressValidator";
import MypageCodeTypeConstants from "../../../../common/MypageCodeTypeConstants";
import { MGeneral } from "../../../../service/models/entity/MGeneral";
import { WprMaxLengthValidator } from "../../../../../wpr-framework/view/validation/validator/WprMaxLengthValidator";


/**
 * イッツコムラーニング申込画面
 */
export class AddLearningSelectView extends MypageBaseView {
	
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('AddLearningSelectView'); }
	// --------------------------------------------------------------------------

	// DI情報  ------------------------------------------------------------------
	@WprDI_Control
	private m_LoginId: WprControlInfo	= null;	// ログインID
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprListData('LearningList')
	private m_LearningList: learningCourseListModel[]			= null;		// コースリスト
	@WprModel('LearningModel')
	private m_LearningModel: learningCourseListModel	= null;					// 画面遷移用
	@WprListData('ErrorListModel')
	private m_ErrorListModel: ErrorModel[]	= null;	// エラーモデル
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_CourseListMode = new CourseListMode();	// コースリストモード
	@WprViewMode
	private m_LoginIdCheckMode = new LoginIdCheck();
	// --------------------------------------------------------------------------
	
	// メンバ変数  ---------------------------------------------------------------
	private m_Customer: Customer 							= null;		// 顧客情報
	private m_LearningServiceNewModel: LearningServiceNewWizardModel  = null;		// ネット追加モデル
	private m_NewMailValidator: WprMailAddressValidator = new WprMailAddressValidator();	//メールアドレスバリデーター
	private m_MaxLengthValidator: WprMaxLengthValidator = new WprMaxLengthValidator();
	private m_LearningService: MGeneral[]				= null;
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ウィザード定義 */
	public get mngr(): MypageWizardMngr 					{ return this.m_MypageWizardMngr; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addListConfig('dispCourse', 'コース名', 'LearningList');
		this.addListConfig('amountType', '料金タイプ', 'LearningList');
		this.addListConfig('payAmount', '料金一覧', 'LearningList', { converter: '3桁カンマ' });
		this.addListConfig('payUnit', '料金単位', 'LearningList');
		this.addListConfig('check', '選択', 'LearningList');
		this.addConfig('loginId', 'ログインID', 'LearningModel');
		this.addListConfig('errorMessage', 'エラーメッセージ', 'ErrorListModel');
	}
	
	/**
	* アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext, true);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			// 遷移情報
			this.m_LearningServiceNewModel = new LearningServiceNewWizardModel();
			this.m_LearningModel =  this.m_MypageWizardMngr.getCache().m_LearningModel;
			// 戻ってきたときにログインIDが表示されるように
			if (ObjectUtil.isNotNullOrUndefined(this.m_LearningModel) && this.m_LearningModel.dispCourse === 'デジタネ') {
				this.setViewMode('ログインID', '表示');
			}
			// 顧客情報
			this.m_Customer = this.m_MypageWizardMngr.getViewInfo().customer;
			this.setCourseList();
			this.refreshView();
		});
	}

	/**
	 * リスト値変更通知
	 * @param name 名前
	 * @param listName リスト名
	 * @param row 行データ
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeListValue(name: string, listName: string, row: any, value: any): boolean {
		if (name === 'check') {
			// チェック切り替えるごとにエラー文言は削除
			this.m_ErrorListModel = new Array();
			this.setViewMode('エラー','非表示');
			// ログインID保持
			this.m_LearningModel.loginId = this.m_LoginId.value;
			// check全て外す
			for (const list of this.m_LearningList)
				list.check = false;
			row.check = true;
			if (row.dispCourse === 'デジタネ') {
				this.setViewMode('ログインID', '表示');
			} else {
				this.setViewMode('ログインID', '非表示');
			}
			this.refreshView();
		}
		return true;
	}

	/**
	 * 入力チェック（独自のValidationを実施する場合に使用する）
	 *	エラー追加例：validError.addError('エラーメッセージ');				// 相関チェックなどのエラー
	 * 				 this.setControlError('control', 'error');			 // コントロールのエラー
	 * 				 validError.showErrorMessage.('エラーメッセージ');	   // エラーメッセージ表示
	 * 		※上記のエラーを追加した場合は、アクションは実行しません。
	 * 		※コントロールの値をチェックする場合は、refreshModelは使用しないでください。
	 * 		　コントロールの値をチェックする場合は、コントロールをDIしてvalueを使用してください。
	 * 		　コントロールが双方向のコンバータを使用している場合は、dataValueを使用してください。
	 * @param actionName アクション名
	 * @param validError バリデーションエラー情報
	 * @param row 行情報
	 */
	 protected onValid(actionName: string, validError: WprValidError, row?: any): void {
		 if (actionName == 'next' && this.getViewMode('ログインID') ===  '表示') {
			this.m_ErrorListModel = new Array();
			let errorFlg:boolean = false;
			if (this.m_LoginId.value) {
				if (!this.m_NewMailValidator.checkValidValue(this.m_LoginId.value)) {
					errorFlg = true;
				}
				if (!this.m_MaxLengthValidator.checkValidValue(this.m_LoginId.value, 256)) {
					errorFlg = true;
				}
				if (errorFlg) {
					const model = new ErrorModel();
					model.errorMessage = 'デジタネログインID（メールアドレス）を正しく入力してください';
					this.m_ErrorListModel.push(model);
				}
			}
		}
		if (this.m_ErrorListModel.length > 0) {
			validError.addError('Error');
			this.setViewMode('エラー','表示');
			this.setListData('ErrorListModel', this.m_ErrorListModel);
		}
	 }
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 確認
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}

	// --------------------------------------------------------------------------

	// privateメソッド ---------------------------------------------------------
	/**
	 * コースリスト設定
	 */
	private setCourseList() {
		this.m_LearningList = this.m_MypageWizardMngr.getCache().m_LearningList;
		// イッツコムラーニングサービス(加入)
		this.m_LearningService = this.m_MypageWizardMngr.getMGenralList(this, MypageCodeTypeConstants.ITSCOM_LEARNING_SERVICE, '').filter(row => row.flag2 === true);
		if (ObjectUtil.isNullOrUndefined(this.m_LearningList) || this.m_LearningList.length === 0) {
			if (ObjectUtil.isNotNullOrEmptyArray(this.m_LearningService)) {
				for (const learningService of this.m_LearningService) {
					const learning: learningCourseListModel = new learningCourseListModel();
					learning.dispCourse = learningService.name2;
					learning.amountType = learningService.flag1 ? '月額' : '年額';
					learning.payAmount = this.m_MypageWizardMngr.getTaxFee(this, String(learningService.num1));
					learning.payUnit = '円（税込）';
					learning.inquiryCourse = learningService.name3;
					this.m_LearningList.push(learning);
				}
			}
			this.m_LearningList[0].check = true;
			this.setListData('LearningList', this.m_LearningList);
		}
		else{
			this.setListData('LearningList', this.m_LearningList);
		}
	}
	// --------------------------------------------------------------------------
}
