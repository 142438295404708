// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * イッツコムラーニング確認画面用CSS\r\n */\n.AddLearningConfirm-text {\n  padding-inline-start: 0; }\n  .AddLearningConfirm-text li {\n    text-indent: -1em;\n    padding-left: 1em;\n    list-style: none; }\n\n.AddLearningConfirm-margin {\n  margin-top: 0px !important;\n  margin-bottom: 0px; }\n  .AddLearningConfirm-margin li {\n    margin: auto; }\n\n.AddLearningConfirm-divider {\n  margin-bottom: 50px; }\n  @media screen and (max-width: 450px) {\n    .AddLearningConfirm-divider {\n      margin-bottom: 0px; } }\n", ""]);
// Exports
exports.locals = {
	"text": "AddLearningConfirm-text",
	"margin": "AddLearningConfirm-margin",
	"divider": "AddLearningConfirm-divider"
};
module.exports = exports;
