import { WprBaseViewListMultiModeInfo } from "../../../../../wpr-framework/mode/WprBaseViewListMultiModeInfo";
import { WprMultiMode } from "../../../../../wpr-framework/mode/WprMultiMode";
import { MGeneralCheckInfo } from "../../../../models/MGeneralCheckInfo";
import { S_01_004View } from "../S_01_004View";

/**
 * 約款チェックリストモード
 */
export class IeCheckListMode extends WprBaseViewListMultiModeInfo<S_01_004View, MGeneralCheckInfo> {

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super('IEList');
	}
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------
	/**
	 * モードリスト取得（この画面モードで使用する全てのモードを定義する）
	 * @param list モードリスト
	 */
	public useModeList(list: string[]): void {
		list.push('選択済');
	}

	/**
	 * フィールド定義を登録処理
	 */
	public onRegisterControl(): void {
		// this.addControl('yakkan', {class: [ { mode: '重要説明事項', class: 'important' }, { mode: 'other', class: '' } ] });
		this.addControl('b_Show', { check: true, class: [ { mode: '選択済', class: 'button_white button_white_checked' }, { mode: 'other', class: 'button_white' } ] });
	}

	/**
	 * ビューの状態をチェックして行のモードを変更する
	 * @param curMode 現在のモード
	 * @param newMode 新しいモード
	 * @param row 行データ
	 */
	public onCheckListMultiMode(curMode: WprMultiMode, newMode: WprMultiMode, row: MGeneralCheckInfo): void {
		if (row.checked)
			newMode.addMode('選択済');
	}
	// --------------------------------------------------------------------------
}
