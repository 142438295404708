
/**
 *  【約款】
 */
class MypageYakkanConstants {
    
	/** 000~099 =========================================== */
	/** イッツコムサービス契約約款 */
    ITSCOM_SERVICE:string = '1';
	/** イッツコムサービス料金表 */
    ITSCOM_PRICE:string = '2';
	/** イッツコムひかり テレビジョンサービス契約約款 */
    ITSCOM_HIKARI_TV:string = '3';
	/** ケーブルテレビジョンサービス契約約款 */
    CABLETV_CATV:string = '4';
	/** B-CASカード使用許諾契約約款 */
    CABLETV_BCAS:string = '5';
	/** イッツコムみるプラス利用規約 */
    ITSCOM_UNLIMITED:string = '6';
	/** ケーブルインターネットサービス契約約款 */
    INTENET_CATV:string = '7';
	/** かっとび光サービス契約約款 */
    IMPORTANT_HIKARI:string = '8';
	/** イッツコムひかり インターネットサービス契約約款 */
    ITSCOM_HIKARI_INTERNET:string = '9';
	/** ケーブルプラス電話契約規約 */
	PHONE_CABLEPLUS:string = '10';
	/** iTSCOM HOMEサービス重要説明事項/契約約款*/
    IMPORTANT_ITSCOMHOME:string = '11';
	/** テレビプッシュサービス契約約款 */
    TV_PUSH:string = '12';
	/** 快適メッシュWi-Fi利用規約 */
    MESH_WIFI:string = '13';
	/** 東急でんき＆ガス規約 */
	ENERGY_DENKI_GAS:string = '14';
	/** 重要説明事項／各種約款・規約（イッツコム エナジー 抜粋版） */
	ENERGY_IMPORTANT:string = '15';
	/** 東急でんき＆ガス特定商取引法に関する表記 */
	ENERGY_TRADE_LAW:string = '16';
	/** かっとびサーバ利用型サービス契約約款 */
	KATTOBI_SERVER:string = '17';
	/** 定期契約商品契約約款 */
	REGULAR_CONTRACT:string = '18';
	/** 重要説明事項/各種約款・規約 */
	ITSCOM_SERVICE_IMPORTANT = '19';
	/** イッツコムラーニング契約約款 */
	ITSCOM_LEARNING = '20';
	/** デキタス利用規約 */
	ITSCOM_LEARNING_DEKITASU = '21';
	/** デジタネ利用規約 */
	ITSCOM_LEARNING_DEGITANE = '22';
}

export default new MypageYakkanConstants();