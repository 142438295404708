import React, { ReactNode } from 'react';
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom';
import { WprSwitch } from '../../../wpr-framework/component/route/WprSwitch';
import { WprBaseViewComponent } from '../../../wpr-framework/view/component/WprBaseViewComponent';
import { MyPageRouterView } from './MyPageRouterView';
import { Footer } from '../common/footer/Footer';
import { Ambassador } from '../ambassador/Ambassador';
import { UsageDetails_001 } from '../usageDetails/usageDetails_001/UsageDetails_001';
import { Header } from '../common/header/Header';
import { S_01_001 } from '../contract_confirm/s_01_001/S_01_001';
import { S_01_002 } from '../contract_confirm/s_01_002/S_01_002';
import { S_01_003 } from '../contract_confirm/s_01_003/S_01_003';
import { S_01_004 } from '../contract_confirm/s_01_004/S_01_004';
import { S_01_005 } from '../contract_confirm/s_01_005/S_01_005';
import { MvnoEntryPlan1 } from '../mvno/entry/mvno_entry_plan1/MvnoEntryPlan1';
import { Grid } from '@material-ui/core';
import { MvnoEntryPlan2 } from '../mvno/entry/mvno_entry_plan2/MvnoEntryPlan2';
import { MvnoEntryPlan3 } from '../mvno/entry/mvno_entry_plan3/MvnoEntryPlan3';
import { MvnoEntryCustomer } from '../mvno/entry/mvno_entry_customer/MvnoEntryCustomer';
import { MvnoEntryProcess } from '../mvno/entry/mvno_entry_process/MvnoEntryProcess';
import { MvnoEntryTotalFee } from '../mvno/entry/mvno_entry_total_fee/MvnoEntryTotalFee';
import { MvnoEntryPreConfirm } from '../mvno/entry/mvno_entry_pre_confirm/MvnoEntryPreConfirm';
import { MvnoEntryIdentification } from '../mvno/entry/mvno_entry_identification/MvnoEntryIdentification';
import { MvnoComplete } from '../mvno/mvno_complete/MvnoComplete';
import { MvnoCapacityChange1 } from '../mvno/changeCapacity/mvno_capacity_change1/MvnoCapacityChange1';
import { MvnoCapacityChange2 } from '../mvno/changeCapacity/mvno_capacity_change2/MvnoCapacityChange2';
import { MvnoMnpInquiry } from '../mvno/mnp/mvno_mnp_inquiry/MvnoMnpInquiry';
import { MvnoCapacityChangePreConfirm } from '../mvno/changeCapacity/mvno_capacity_change_pre_confirm/MvnoCapacityChangePreConfirm';
import { WprRoute } from '../../../wpr-framework/component/route/WprRoute';
import { MvnoMnpInquiryConfirm } from '../mvno/mnp/mvno_mnp_inquiry_confirm/MvnoMnpInquiryConfirm';
import { MypageError } from '../common/mypage_error/MypageError';
import { ProgressBar } from '../common/progress_bar/ProgressBar';
import { NotFound } from '../common/not_found/NotFound';
import { MvnoConfirmCapacity } from '../mvno/confirmCapacity/mvno_confirm_capacity/MvnoConfirmCapacity';
import { MvnoAddCapacity } from '../mvno/confirmCapacity/mvno_add_capacity/MvnoAddCapacity';
import { MvnoAddCapacityConfirm } from '../mvno/confirmCapacity/mvno_add_capacity_confirm/MvnoAddCapacityConfirm';
import { MenuBar } from '../common/menu_bar/MenuBar';
import { WprContainer } from '../../../wpr-framework/component/controls/WprContainer';
import { MvnoEntryUserIdentification } from '../mvno/entry/mvno_entry_user_identification/MvnoEntryUserIdentification';
import { VideoUnlimitedComfirm } from '../videoService/video_unlimited_comfirm/VideoUnlimitedComfirm';
import { TokyuElectricComplete } from '../tokyu_Electric/tokyu_electric_complete/TokyuElectricComplete';
import { VideoIpstbComfirm } from '../videoService/video_ipstb_comfirm/VideoIpstbComfirm';
import { VideoPassComfirm } from '../videoService/video_pass_comfirm/VideoPassComfirm';
import { VideoPassComplete } from '../videoService/video_pass_complete/VideoPassComplete';
import { Menu } from '../menu/Menu';
import { CommonComplete } from '../common/common_complete/CommonComplete';
import { ItscomHomeAgree } from '../itscom_home/new/itscom_home_agree/ItscomHomeAgree';
import { ItscomHomeComfirm } from '../itscom_home/new/itscom_home_comfirm/ItscomHomeComfirm';
import { ItscomHomeSelect1 } from '../itscom_home/new/itscom_home_select1/ItscomHomeSelect1';
import { ItscomHomeSelect2 } from '../itscom_home/new/itscom_home_select2/ItscomHomeSelect2';
import { ItscomHomeReviseSelect } from '../itscom_home/revise/itscom_home_revise_select/ItscomHomeReviseSelect';
import { ItscomHomeReviceComfirm } from '../itscom_home/revise/itscom_home_revise_comfirm/ItscomHomeReviceComfirm';
import { MagazineConfirm } from '../magazine/magazine_confirm/MagazineConfirm';
import { MagazineApplyComplete } from '../magazine/magazine_apply_complete/MagazineApplyComplete';
import { MagazineCancelConfirm } from '../magazine/magazine_cancel_confirm/MagazineCancelConfirm';
import { MagazineCancelComplete } from '../magazine/magazine_cancel_complete/MagazineCancelComplete';
import { AddAccesspassSelect } from '../internet/add_accesspass_select/AddAccesspassSelect';
import { AddAccesspassConfirm } from '../internet/add_accesspass_confirm/AddAccesspassConfirm';
import { AddAccesspassComplete } from '../internet/add_accesspass_complete/AddAccesspassComplete';
import { ModIpopFinish } from '../Srvnet/revise/Mod_ipop_finish/ModIpopFinish';
import { AddNhkConfirm } from '../Srvother/add_nhk_confirm/AddNhkConfirm';
import { ModNhkConfirm } from '../Srvother/mod_nhk_confirm/ModNhkConfirm';
import { ModIpopConfirm } from '../Srvnet/revise/Mod_ipop_confirm/ModIpopConfirm';
import { ModOthernetSelect } from '../Srvnet/revise/mod_othernet_select/ModOthernetSelect';
import { VideoPassPrint } from '../videoService/video_pass_print/VideoPassPrint';
import { AddNhkSelect } from '../Srvother/add_nhk_select/AddNhkSelect';
import { CommonAgree } from '../common/common_agree/CommonAgree';
import { AddPhoneSelect } from '../Phone/add_phone_select/AddPhoneSelect';
import { AddPhoneConfirm } from '../Phone/add_phone_confirm/AddPhoneConfirm';
import { AddPhoneUseagree } from '../Phone/add_phone_useagree/AddPhoneUseagree';
import { ReiAccesspassConfirm } from '../internet/rei_accesspass_confirm/ReiAccesspassConfirm';
import { ModNetConfirm } from '../Srvnet/revise/mod_net_confirm/ModNetConfirm';
import { AddPhoneComplete } from '../Phone/add_phone_complete/AddPhoneComplete';
import { ReiAccesspassComplete } from '../internet/rei_accesspass_complete/ReiAccesspassComplete';
import styles from './MyPageRouter.scss';
import { AccesspassPrint } from '../internet/accesspass_print/AccesspassPrint';
import { NameChangeInformation } from '../name_change/name_change_information/NameChangeInformation';
import { NameChangeForm } from '../name_change/name_change_form/NameChangeForm';
import { NameChangeIdentification } from '../name_change/name_change_identification/NameChangeIdentification';
import { NameChangeConfirm } from '../name_change/name_change_confirm/NameChangeConfirm';
import { AddTvComfirm } from '../tv/new/add_tv_comfirm/AddTvComfirm';
import { AddTvSelect1 } from '../tv/new/add_tv_select1/AddTvSelect1';
import { AddTvSelect2 } from '../tv/new/add_tv_select2/AddTvSelect2';
import { TokotonSupportSelect } from '../tokotonSupport/tokoton_support_select/TokotonSupportSelect';
import { TokotonSupportConfirm } from '../tokotonSupport/tokoton_support_confirm/TokotonSupportConfirm';
import { TokotonSupportUseagree } from '../tokotonSupport/tokoton_support_useagree/TokotonSupportUseagree';
import { MobileConfirm } from '../Srvnet/mobile_confirm/MobileConfirm';
import { MobileComplete } from '../Srvnet/mobile_complete/MobileComplete';
import { ReiAccesspassPrint } from '../internet/rei_accesspass_print/ReiAccesspassPrint';
import { NameChangeComplete } from '../name_change/name_change_complete/NameChangeComplete';
import { AddNetSelect } from '../Srvnet/new/add_net_select/AddNetSelect';
import { AddNetConfirm } from '../Srvnet/new/add_net_confirm/AddNetConfirm';
import { AddNetSetting } from '../Srvnet/new/add_net_setting/AddNetSetting';
import { ModNetSelect } from '../Srvnet/revise/mod_net_select/ModNetSelect';
import { ModTvComfirm } from '../tv/revise/mod_tv_comfirm/ModTvComfirm';
import { ModTvSelect1 } from '../tv/revise/mod_tv_select1/ModTvSelect1';
import { ModTvSelect2 } from '../tv/revise/mod_tv_select2/ModTvSelect2';
import { ModNetSetting } from '../Srvnet/revise/mod_net_setting/ModNetSetting';
import { ModTvequipConfirm } from '../tv/equipment/mod_tvequip_confirm/ModTvequipConfirm';
import { ModTvequipSelect } from '../tv/equipment/mod_tvequip_select/ModTvequipSelect';
import { LineAgree } from '../line/agree/LineAgree';
import { LineLiff } from '../line/liff/LineLiff';
import { AddTvopConfirm } from '../tv/option/add_tvop_confirm/AddTvopConfirm';
import { AddTvopSelect } from '../tv/option/add_tvop_select/AddTvopSelect';
import { AddTvopprovConfirm } from '../tv/option/add_tvopprov_confirm/AddTvopprovConfirm';
import { ModTvopConfirm } from '../tv/option/mod_tvop_confirm/ModTvopConfirm';
import { TvopComplete } from '../tv/option/tvop_complete/TvopComplete';
import { AddMeshWifiSelect } from '../meshWifi/add_meshWifi_select/AddMeshWifiSelect';
import { AddMeshWifiConfirm } from '../meshWifi/add_meshWifi_confirm/AddMeshWifiConfirm';
import { RelocateComfirm } from '../relocate/relocate_comfirm/RelocateComfirm';
import { RelocateSelect1 } from '../relocate/relocate_select1/RelocateSelect1';
import { RelocateSelect2 } from '../relocate/relocate_select2/RelocateSelect2';
import { ItscomHomeReviseComplete } from '../itscom_home/revise/itscom_home_revise_complete/ItscomHomeReviseComplete';
import { NhkEdit } from '../Srvother/nhk_edit/NhkEdit';
import { CustomerSupport } from '../common/customer_support/CustomerSupport';
import { AddOthernetConfirm } from '../Srvnet/new/add_othernet_confirm/AddOthernetConfirm';
import { AddOthernetSelect } from '../Srvnet/new/add_othernet_select/AddOthernetSelect';
import { ModOthernetConfirm } from '../Srvnet/revise/mod_othernet_confirm/ModOthernetConfirm';
import { ConstrConfirm } from '../construction/constr_confirm/ConstrConfirm';
import { ConstrSearch } from '../construction/constr_search/ConstrSearch';
import { ContsrNotes } from '../construction/contsr_notes/ContsrNotes';
import { ConstrList } from '../construction/constr_list/ConstrList';
import { CallForwardingSelect } from '../Phone/call_forwarding_select/CallForwardingSelect';
import { CallForwardingConfirm } from '../Phone/call_forwarding_confirm/CallForwardingConfirm';
import { ConstrChange } from '../construction/constr_change/ConstrChange';
import { ConstrLog } from '../construction/constr_log/ConstrLog';
import { ConstrCancel } from '../construction/constr_cancel/ConstrCancel';
import { AddPhoneIdentification } from '../Phone/add_phone_identification/AddPhoneIdentification';
import { NameChangeNewInformation } from '../name_change/name_change_new_information/NameChangeNewInformation';
import { NameChangeNewForm } from '../name_change/name_change_new_form/NameChangeNewForm';
import { NameChangeNewIdentification } from '../name_change/name_change_new_identification/NameChangeNewIdentification';
import { NameChangeNewConfirm } from '../name_change/name_change_new_confirm/NameChangeNewConfirm';
import { NameChangeNewComplete } from '../name_change/name_change_new_complete/NameChangeNewComplete';
import { NameChangeError } from '../name_change/name_change_error/NameChangeError';
import { CallForwardingComplete } from '../Phone/call_forwarding_complete/CallForwardingComplete';
import { TerminalRegist } from '../terminalRegist/terminal_regist/TerminalRegist';
import { AddLearningSelect } from '../itscomLearning/add/add_learning_select/AddLearningSelect';
import { AddLearningConfirm } from '../itscomLearning/add/add_learning_comfirm/AddLearningConfirm';
import { LearningCancelConfirm } from '../itscomLearning/cancel/learning_cancel_comfirm/LearningCancelConfirm';
import { LearningCancelComplete } from '../itscomLearning/cancel/learning_cancel_complete/LearningCancelComplete';
import { AddLearningAgree } from '../itscomLearning/add/add_learning_agree/AddLearningAgree';
import { LearningCancelEntry } from '../itscomLearning/cancel/learning_cancel_entry/LearningCancelEntry';

/**
 * router用コンポーネント
 */
export class MyPageRouter extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new MyPageRouterView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** routerビュー情報 */
	public get viewInfo(): MyPageRouterView { return this.view as MyPageRouterView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		let hiddenClass: string = null;
		const url:string = window.location.href;
		if (url.includes('video_pass_print') || url.includes('accesspass_print') || url.includes('line_agree') || url.includes('line_liff')
		 || url.includes('name_change_new_information') || url.includes('name_change_new_form') || url.includes('name_change_new_identification') || url.includes('name_change_new_confirm') || url.includes('name_change_new_complete'))
			hiddenClass = 'hiddenClass';
		return (
			<>
				<div className='base'>
					<div className={hiddenClass}>
						<Header/>
					</div>
					<Router>
						<div className='common'>
							<Grid container className='gridContainer-common' alignItems="flex-start" justifyContent="flex-start">
								<Grid item xs={12} sm={3} md={3} lg={3} className={hiddenClass}>
									<MenuBar />
								</Grid>
								<Grid item xs md lg className='griditem-mainview'>
									<WprSwitch view={this.view}>
										{/* <Route exact path={'/'} component={withRouter(Menu)} /> */}
											<Route exact path={'/menu'} component={withRouter(Menu)} />
											<Route exact path={'/'} component={withRouter(S_01_001)} />
											<Route path={'/s002'} component={withRouter(S_01_002)} />
											<Route path={'/s003'} component={withRouter(S_01_003)} />
											<Route path={'/s004'} component={withRouter(S_01_004)} />
											<Route path={'/s005'} component={withRouter(S_01_005)} />
											<WprRoute exact path={'/customer_support'} component={withRouter(CustomerSupport)} noMove />
											<Route exact path={'/mvno_001'} component={withRouter(MvnoEntryProcess)} />
											<Route exact path={'/mvno_002'} component={withRouter(MvnoEntryPlan1)} />
											<Route exact path={'/mvno_003'} component={withRouter(MvnoEntryPlan2)} />
											<Route exact path={'/mvno_004'} component={withRouter(MvnoEntryPlan3)} />
											<Route exact path={'/mvno_005'} component={withRouter(MvnoEntryCustomer)} />
											<Route exact path={'/mvno_006'} component={withRouter(MvnoEntryTotalFee)} />
											<Route exact path={'/mvno_007'} component={withRouter(MvnoEntryPreConfirm)} />
											<Route exact path={'/mvno_008'} component={withRouter(MvnoEntryIdentification)} />
											<WprRoute exact path={'/mvno_009'} component={withRouter(MvnoComplete)} noMove />
											<WprRoute exact path={'/mvno_010'} component={withRouter(MvnoEntryUserIdentification)} noMove />
											<Route exact path={'/mvno_change_001'} component={withRouter(MvnoCapacityChange1)} />
											<Route exact path={'/mvno_change_002'} component={withRouter(MvnoCapacityChange2)} />
											<Route exact path={'/mvno_change_003'} component={withRouter(MvnoCapacityChangePreConfirm)} />
											<WprRoute exact path={'/mvno_change_004'} component={withRouter(MvnoComplete)} noMove />
											<Route exact path={'/mvno_mnp_001'} component={withRouter(MvnoMnpInquiry)} />
											<Route exact path={'/mvno_mnp_002'} component={withRouter(MvnoMnpInquiryConfirm)} />
											<WprRoute exact path={'/mvno_mnp_003'} component={withRouter(MvnoComplete)} noMove />
											<Route exact path={'/mvno_capacity_001'} component={withRouter(MvnoConfirmCapacity)} />
											<Route exact path={'/mvno_add_capacity_001'} component={withRouter(MvnoAddCapacity)} />
											<Route exact path={'/mvno_add_capacity_002'} component={withRouter(MvnoAddCapacityConfirm)} />
											<WprRoute exact path={'/mvno_add_capacity_003'} component={withRouter(MvnoComplete)} noMove />
											<Route exact path={'/magazine_confirm'} component={withRouter(MagazineConfirm)} />
											<WprRoute exact path={'/magazine_apply_complete'} component={withRouter(MagazineApplyComplete)} noMove/>
											<Route exact path={'/magazine_cancel_confirm'} component={withRouter(MagazineCancelConfirm)} />
											<WprRoute exact path={'/magazine_cancel_complete'} component={withRouter(MagazineCancelComplete)} noMove/>
											<Route exact path={'/itscom_home_select1'} component={withRouter(ItscomHomeSelect1)} />
											<Route exact path={'/itscom_home_select2'} component={withRouter(ItscomHomeSelect2)} />
											<Route exact path={'/itscom_home_confirm'} component={withRouter(ItscomHomeComfirm)} />
											<Route exact path={'/itscom_home_agree'} component={withRouter(CommonAgree)} />
											<WprRoute exact path={'/itscom_home_complete'} component={withRouter(CommonComplete)} noMove/>
											<Route exact path={'/itscom_home_revise_select'} component={withRouter(ItscomHomeReviseSelect)} />
											<Route exact path={'/itscom_home_revise_confirm'} component={withRouter(ItscomHomeReviceComfirm)} />
											<WprRoute exact path={'/itscom_home_revise_complete'} component={withRouter(CommonComplete)} noMove />
											<Route exact path={'/video_unlimited_comfirm'} component={withRouter(VideoUnlimitedComfirm)} />
											<Route exact path={'/video_unlimited_agree'} component={withRouter(CommonAgree)} />
											<WprRoute exact path={'/video_unlimited_complete'} component={withRouter(CommonComplete)} noMove/>
											<Route exact path={'/video_ipstb_comfirm'} component={withRouter(VideoIpstbComfirm)} />
											<Route exact path={'/video_ipstb_agree'} component={withRouter(CommonAgree)} />
											<WprRoute exact path={'/video_ipstb_complete'} component={withRouter(CommonComplete)} noMove/>
											<Route exact path={'/video_pass_comfirm'} component={withRouter(VideoPassComfirm)} />
											<WprRoute exact path={'/video_pass_complete'} component={withRouter(VideoPassComplete)} noMove/>
											<Route exact path={'/video_pass_print'} component={withRouter(VideoPassPrint)} />
											<WprRoute exact path={'/common_complete'} component={withRouter(CommonComplete)} noMove/>
											<Route exact path={'/common_agree'} component={withRouter(CommonAgree)} />
											<Route exact path={'/add_nhk_confirm'} component={withRouter(AddNhkConfirm)} />
											<WprRoute exact path={'/add_nhk_complete'} component={withRouter(CommonComplete)} noMove/>
											<Route exact path={'/add_nhk_select'} component={withRouter(AddNhkSelect)} />
											<Route exact path={'/nhk_edit'} component={withRouter(NhkEdit)} />
											<WprRoute exact path={'/edit_nhk_complete'} component={withRouter(CommonComplete)} noMove/>
											<Route exact path={'/mod_nhk_confirm'} component={withRouter(ModNhkConfirm)} />
											<WprRoute exact path={'/mod_nhk_complete'} component={withRouter(CommonComplete)} noMove/>
											<Route exact path={'/mod_ipop_confirm'} component={withRouter(ModIpopConfirm)} />
											<Route exact path={'/mod_ipop_finish'} component={withRouter(ModIpopFinish)} />
											<Route exact path={'/add_net_select'} component={withRouter(AddNetSelect)} />
											<Route exact path={'/add_net_setting'} component={withRouter(AddNetSetting)} />
											<Route exact path={'/add_net_confirm'} component={withRouter(AddNetConfirm)} />
											<WprRoute exact path={'/add_net_complete'} component={withRouter(CommonComplete)} noMove/>
											<WprRoute exact path={'/add_kattobi_complete'} component={withRouter(CommonComplete)} noMove/>
											<Route exact path={'/mod_net_select'} component={withRouter(ModNetSelect)} />
											<Route exact path={'/mod_net_confirm'} component={withRouter(ModNetConfirm)} />
											<Route exact path={'/mod_net_setting'} component={withRouter(ModNetSetting)} />
											<WprRoute exact path={'/mod_net_complete'} component={withRouter(CommonComplete)} noMove/>
											<WprRoute exact path={'/mod_kattobi_complete'} component={withRouter(CommonComplete)} noMove/>
											<WprRoute exact path={'/mod_cable_complete'} component={withRouter(CommonComplete)} noMove/>
											<Route exact path={'/add_othernet_select'} component={withRouter(AddOthernetSelect)} />
											<Route exact path={'/add_othernet_confirm'} component={withRouter(AddOthernetConfirm)} />
											<Route exact path={'/mod_othernet_select'} component={withRouter(ModOthernetSelect)} />
											<Route exact path={'/mod_othernet_confirm'} component={withRouter(ModOthernetConfirm)} />
											<Route exact path={'/service_net_useagree'} component={withRouter(CommonAgree)} />
											<Route exact path={'/add_accesspass_select'} component={withRouter(AddAccesspassSelect)} />
											<Route exact path={'/add_accesspass_confirm'} component={withRouter(AddAccesspassConfirm)} />
											<WprRoute exact path={'/add_accesspass_complete'} component={withRouter(AddAccesspassComplete)} noMove/>
											<Route exact path={'/accesspass_print'} component={withRouter(AccesspassPrint)} />
											<Route exact path={'/add_phone_select'} component={withRouter(AddPhoneSelect)} />
											<Route exact path={'/add_phone_confirm'} component={withRouter(AddPhoneConfirm)} />
											<Route exact path={'/add_phone_Identification'} component={withRouter(AddPhoneIdentification)} />
											<Route exact path={'/add_phone_useagree'} component={withRouter(CommonAgree)} />
											<WprRoute exact path={'/add_phone_complete'} component={withRouter(CommonComplete)} noMove/>
											<Route exact path={'/call_forwarding_select'} component={withRouter(CallForwardingSelect)} />
											<Route exact path={'/call_forwarding_confirm'} component={withRouter(CallForwardingConfirm)} />
											{/* <WprRoute exact path={'/call_forwarding_complete'} component={withRouter(CommonComplete)} noMove/> */}
											<WprRoute exact path={'/call_forwarding_complete'} component={withRouter(CallForwardingComplete)} noMove/>
											<Route exact path={'/rei_accesspass_confirm'} component={withRouter(ReiAccesspassConfirm)} />
											<WprRoute exact path={'/rei_accesspass_complete'} component={withRouter(ReiAccesspassComplete)} noMove/>
											<Route exact path={'/rei_accesspass_print'} component={withRouter(ReiAccesspassPrint)} />
											<Route exact path={'/add_tv_select1'} component={withRouter(AddTvSelect1)} />
											<Route exact path={'/add_tv_select2'} component={withRouter(AddTvSelect2)} />
											<Route exact path={'/add_tv_comfirm'} component={withRouter(AddTvComfirm)} />
											<Route exact path={'/add_tv_agree'} component={withRouter(CommonAgree)} />
											<WprRoute exact path={'/add_tv_complete'} component={withRouter(CommonComplete)} noMove/>
											<Route exact path={'/mod_tv_select1'} component={withRouter(ModTvSelect1)} />
											<Route exact path={'/mod_tv_select2'} component={withRouter(ModTvSelect2)} />
											<Route exact path={'/mod_tv_comfirm'} component={withRouter(ModTvComfirm)} />
											<Route exact path={'/mod_tv_agree'} component={withRouter(CommonAgree)} />
											<WprRoute exact path={'/mod_tv_complete'} component={withRouter(CommonComplete)} noMove/>
											<Route exact path={'/mod_tvequip_select'} component={withRouter(ModTvequipSelect)} />
											<Route exact path={'/mod_tvequip_confirm'} component={withRouter(ModTvequipConfirm)} />
											<WprRoute exact path={'/mod_tvequip_complete'} component={withRouter(CommonComplete)} noMove/>
											<Route exact path={'/add_tvop_select'} component={withRouter(AddTvopSelect)} />
											<Route exact path={'/add_tvop_confirm'} component={withRouter(AddTvopConfirm)} />
											<WprRoute exact path={'/add_tvop_complete'} component={withRouter(TvopComplete)} noMove/>
											<Route exact path={'/add_tvopprov_confirm'} component={withRouter(AddTvopprovConfirm)} />
											<WprRoute exact path={'/add_tvopprov_complete'} component={withRouter(TvopComplete)} noMove/>
											<Route exact path={'/mod_tvop_confirm'} component={withRouter(ModTvopConfirm)} />
											<WprRoute exact path={'/mod_tvop_complete'} component={withRouter(TvopComplete)} noMove/>
											<Route exact path={'/relocate_select1'} component={withRouter(RelocateSelect1)} />
											<Route exact path={'/relocate_select2'} component={withRouter(RelocateSelect2)} />
											<Route exact path={'/relocate_comfirm'} component={withRouter(RelocateComfirm)} />
											<WprRoute exact path={'/relocate_agree'} component={withRouter(CommonAgree)} />
											<WprRoute exact path={'/relocate_complete'} component={withRouter(CommonComplete)} noMove/>
											<Route exact path={'/constr_list'} component={withRouter(ConstrList)} />
											<Route exact path={'/contsr_notes'} component={withRouter(ContsrNotes)} />
											<Route exact path={'/constr_search'} component={withRouter(ConstrSearch)} />
											<WprRoute exact path={'/constr_confirm'} component={withRouter(ConstrConfirm)} />
											<WprRoute exact path={'/constr_complete'} component={withRouter(CommonComplete)} noMove/>
											<WprRoute exact path={'/constr_change'} component={withRouter(ConstrChange)} />
											<WprRoute exact path={'/constr_change_complete'} component={withRouter(CommonComplete)} noMove/>
											<WprRoute exact path={'/contsr_cancel'} component={withRouter(ConstrCancel)} />
											<WprRoute exact path={'/constr_cancel_complete'} component={withRouter(CommonComplete)} noMove/>
											<WprRoute exact path={'/contsr_log'} component={withRouter(ConstrLog)} />
											<Route exact path={'/tokoton_support_select'} component={withRouter(TokotonSupportSelect)} />
											<Route exact path={'/tokoton_support_confirm'} component={withRouter(TokotonSupportConfirm)} />
											<Route exact path={'/tokoton_support_useagree'} component={withRouter(CommonAgree)} />
											<WprRoute exact path={'/tokoton_support_complete'} component={withRouter(CommonComplete)} noMove/>
											<Route exact path={'/add_meshWifi_select'} component={withRouter(AddMeshWifiSelect)} />
											<Route exact path={'/add_meshWifi_confirm'} component={withRouter(AddMeshWifiConfirm)} />
											<Route exact path={'/add_meshWifi_agree'} component={withRouter(CommonAgree)} />
											<WprRoute exact path={'/add_meshWifi_complete'} component={withRouter(CommonComplete)} noMove/>
											<Route exact path={'/mobile_confirm'} component={withRouter(MobileConfirm)} />
											<WprRoute exact path={'/mobile_complete'} component={withRouter(MobileComplete)} noMove/>
											<Route exact path={'/name_change_information'} component={withRouter(NameChangeInformation)} />
											<Route exact path={'/name_change_form'} component={withRouter(NameChangeForm)} />
											<Route exact path={'/name_change_agree'} component={withRouter(CommonAgree)} />
											<Route exact path={'/name_change_identification'} component={withRouter(NameChangeIdentification)} />
											<Route exact path={'/name_change_confirm'} component={withRouter(NameChangeConfirm)} />
											<WprRoute exact path={'/name_change_complete'} component={withRouter(NameChangeComplete)} noMove/>
											<Route exact path={'/ambassador_001'} component={withRouter(Ambassador)} />
											<Route exact path={'/usage_details_001'} component={withRouter(UsageDetails_001)} />
											<WprRoute exact path={'/error'} component={withRouter(MypageError)} noMove />
											<Route exact path={'/line_liff'} component={withRouter(LineLiff)} />
											<Route exact path={'/line_agree'} component={withRouter(LineAgree)} />
											<Route exact path={'/name_change_new_information'} component={withRouter(NameChangeNewInformation)} />
											<Route exact path={'/name_change_new_form'} component={withRouter(NameChangeNewForm)} />
											<Route exact path={'/name_change_new_identification'} component={withRouter(NameChangeNewIdentification)} />
											<Route exact path={'/name_change_new_confirm'} component={withRouter(NameChangeNewConfirm)} />
											<Route exact path={'/name_change_new_complete'} component={withRouter(NameChangeNewComplete)} />
											<Route exact path={'/terminal_regist'} component={withRouter(TerminalRegist)} />
											<WprRoute exact path={'/terminal_regist_complete'} component={withRouter(CommonComplete)} noMove/>
											<WprRoute exact path={'/nameChangeError'} component={withRouter(NameChangeError)} noMove />
											<Route exact path={'/add_learning_select'} component={withRouter(AddLearningSelect)} />
											<Route exact path={'/add_learning_comfirm'} component={withRouter(AddLearningConfirm)} />
											<Route exact path={'/add_learning_agree'} component={withRouter(AddLearningAgree)} />
											<WprRoute exact path={'/add_learning_complete'} component={withRouter(CommonComplete)} noMove/>
											<Route exact path={'/learning_cancel_entry'} component={withRouter(LearningCancelEntry)} />
											<Route exact path={'/learning_cancel_comfirm'} component={withRouter(LearningCancelConfirm)} />
											<WprRoute exact path={'/learning_cancel_complete'} component={withRouter(LearningCancelComplete)} noMove/>
											<Route component={withRouter(NotFound)} />
									</WprSwitch>
								</Grid>
							</Grid>
						</div>
					</Router>
					<Footer />
					{/* </WprContainer> */}
				</div >
			</>
		);
	}
	// --------------------------------------------------------------------------
}
