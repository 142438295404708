import { WprBaseScopeInfo } from "../../wpr-framework/scope/WprBaseScopeInfo";
import { CustomerInfo } from "../models/CustomerInfo";
import { MvnoCapacityChangeInfo } from "../service/models/apimodels/MvnoCapacityChangeInfo";
import { MvnoEntryInfo } from "../service/models/apimodels/MvnoEntryInfo";
import { MvnoMnpInfo } from "../service/models/apimodels/MvnoMnpInfo";
import { HMvnoTmpSaved } from "../service/models/entity/HMvnoTmpSaved";
import { IpaddressChangeWizardFlow } from "../view/wizard/IpaddressChangeWizardFlow";
import { MypageWizardFlow } from "../view/wizard/MypageWizardFlow";
import { MypageWizardMngr } from "../view/wizard/models/MypageWizardMngr";
import { NetServiceChangeWizardFlow } from "../view/wizard/NetServiceChangeWizardFlow";
import { NetServiceNewWizardFlow } from "../view/wizard/NetServiceNewWizardFlow";
import { NhkCancelWizardFlow } from "../view/wizard/NhkCancelWizardFlow";
import { NhkNewWizardFlow } from "../view/wizard/NhkNewWizardFlow";
import { ItscomHomeNewWizardFlow } from "../view/wizard/ItscomHomeNewWizardFlow";
import { MagazineChangeWizardFlow } from "../view/wizard/MagazineChangeWizardFlow";
import { MagazineCancelWizardFlow } from "../view/wizard/MagazineCancelWizardFlow";
import { AddAccesspassNewWizardFlow } from "../view/wizard/AddAccesspassNewWizardFlow";
import { ItscomNetIssueWizardFlow } from "../view/wizard/ItscomNetIssueWizardFlow";
import { ItscomPhoneNewWizardFlow } from "../view/wizard/ItscomPhoneNewWizardFlow";
import { ItscomHomeChangeWizardFlow } from "../view/wizard/ItscomHomeChangeWizardFlow";
import { VideoUnlimitedWizardFlow } from "../view/wizard/VideoUnlimitedWizardFlow";
import { VideoIpstbWizardFlow } from "../view/wizard/VideoIpstbWizardFlow";
import { VideoPassWizardFlow } from "../view/wizard/VideoPassWizardFlow";
import { NameChangeWizardFlow } from "../view/wizard/NameChangeWizardFlow";
import { TvNewWizardFlow } from "../view/wizard/TvNewWizardFlow";
import { TokotonSupportNewWizardFlow } from "../view/wizard/TokotonSupportNewWizardFlow";
import { MobileConfirmWizardFlow } from "../view/wizard/MobileConfirmWizardFlow";
import { TvChangeWizardFlow } from "../view/wizard/TvChangeWizardFlow";
import { TvEquipWizardFlow } from "../view/wizard/TvEquipWizardFlow";
import { TvOptionWizardFlow } from "../view/wizard/TvOptionWizardFlow";
import { MeshWifiNewWizardFlow } from "../view/wizard/MeshWifiNewWizardFlow";
import { RelocateWizardFlow } from "../view/wizard/RelocateWizardFlow";
import { NhkEditWizardFlow } from "../view/wizard/NhkEditWizardFlow";
import { ConstructionWizardFlow } from "../view/wizard/ConstructionWizardFlow";
import { CallForwardingWizardFlow } from "../view/wizard/CallForwardingWizardFlow";
import { NameChangeNewWizardFlow } from "../view/wizard/NameChangeNewWizardFlow";
import { TerminalRegistWizardFlow } from "../view/wizard/TerminalRegistWizardFlow";
import { ItscomLearningNewWizardFlow } from "../view/wizard/ItscomLearningNewWizardFlow";
import { ItscomLearningCancelWizardFlow } from "../view/wizard/ItscomLearningCancelWizardFlow";
// import { MobileConfirmWizardFlow } from "../view/wizard/MobileConfirmWizardFlow";





/**
 * サンプルスコープ情報
 */
 export class ContractScopeInfo extends WprBaseScopeInfo {
    // コンストラクタ  -----------------------------------------------------------
    public constructor() { super('ContractScopeInfo'); }
    // --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 初期化通知
	 */
	public onInitialize(): void {
        // MVNO：申込 -----------------------------------
		const entry: MvnoEntryInfo = new MvnoEntryInfo();
		entry.entryInfo = new HMvnoTmpSaved();
		this.setScopeModel('MvnoEntryInfo', entry);

		// MVNO：容量変更 -----------------------------------
		// const capacityChangea: MvnoCapacityChangeInfo = new MvnoCapacityChangeInfo();
		// this.setScopeModel('CapacityChangeInfo', capacityChangea);

		// MVNO：MNP開通問い合わせ -----------------------------------
		const mvnoMnpInfo: MvnoMnpInfo = new MvnoMnpInfo();
		mvnoMnpInfo.customerInfo = new CustomerInfo();
		this.setScopeModel('MvnoMnpInfo', mvnoMnpInfo);
		
		// MyPAGE ウィザード管理クラス -----------------------------------
		const mypageWizardFlowList: MypageWizardFlow[] = new Array();
		mypageWizardFlowList.push(new ItscomHomeNewWizardFlow());
		mypageWizardFlowList.push(new ItscomNetIssueWizardFlow());
		mypageWizardFlowList.push(new ItscomPhoneNewWizardFlow());
		mypageWizardFlowList.push(new NhkNewWizardFlow());
		mypageWizardFlowList.push(new NhkEditWizardFlow());
		mypageWizardFlowList.push(new NhkCancelWizardFlow());
		mypageWizardFlowList.push(new IpaddressChangeWizardFlow());
		mypageWizardFlowList.push(new NetServiceChangeWizardFlow());
		mypageWizardFlowList.push(new NetServiceNewWizardFlow());
		mypageWizardFlowList.push(new MagazineCancelWizardFlow());
		mypageWizardFlowList.push(new MagazineChangeWizardFlow());
		mypageWizardFlowList.push(new AddAccesspassNewWizardFlow());
		mypageWizardFlowList.push(new TokotonSupportNewWizardFlow());
		mypageWizardFlowList.push(new ItscomHomeChangeWizardFlow());
		mypageWizardFlowList.push(new VideoUnlimitedWizardFlow());
		mypageWizardFlowList.push(new VideoIpstbWizardFlow());
		mypageWizardFlowList.push(new VideoPassWizardFlow());
		mypageWizardFlowList.push(new NameChangeWizardFlow());
		mypageWizardFlowList.push(new NameChangeNewWizardFlow());
		mypageWizardFlowList.push(new MeshWifiNewWizardFlow());
		mypageWizardFlowList.push(new TvNewWizardFlow());
		mypageWizardFlowList.push(new TvChangeWizardFlow());
		mypageWizardFlowList.push(new TvEquipWizardFlow());
		mypageWizardFlowList.push(new TvOptionWizardFlow());
		mypageWizardFlowList.push(new MobileConfirmWizardFlow());
		mypageWizardFlowList.push(new RelocateWizardFlow());
		mypageWizardFlowList.push(new ConstructionWizardFlow());
		mypageWizardFlowList.push(new CallForwardingWizardFlow());
		mypageWizardFlowList.push(new TerminalRegistWizardFlow());
		mypageWizardFlowList.push(new ItscomLearningNewWizardFlow());
		mypageWizardFlowList.push(new ItscomLearningCancelWizardFlow());
		const mypageWizardMngr: MypageWizardMngr = new MypageWizardMngr();
		mypageWizardMngr.setFlowList(mypageWizardFlowList);
		this.setScopeModel('MypageWizardMngr', mypageWizardMngr);
	}
	// --------------------------------------------------------------------------
    // override メソッド  --------------------------------------------------------
    // --------------------------------------------------------------------------
}

