import { WizardModel } from '../../models/WizardModel';
import { MypageWizardFlow } from './MypageWizardFlow';
import { CommonCompleteView } from '../common/common_complete/CommonCompleteView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MypageBaseView } from '../base/MypageBaseView';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { TvNewWizardModel } from './TvNewWizardFlow';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { TerminalRegistView } from '../terminalRegist/terminal_regist/TerminalRegistView';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import { TerminalList } from '../../models/iss/SetMypInstallTerminal/TerminalList';
import { MypageErrorView } from '../common/mypage_error/MypageErrorView';

/**
 * 端末登録_ウィザードクラス
 */
export class TerminalRegistWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new TerminalRegistView().name;

		// 遷移パターン１
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: 'トップ', viewName: null, path: this.getOldUrl() + 'Top/My_top/'});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: '機器申込み', viewName: new TerminalRegistView().name, path: 'terminal_regist'});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: '申込み完了', viewName: new CommonCompleteView().name, path: 'terminal_regist_complete'});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		return req;
	}
	
	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistInfoRequest{
		return null;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr, view: MypageBaseView): string {
		return null;
	}

	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): TerminalRegistWizarddModel {
		let cacheData = new TerminalRegistWizarddModel();
		cacheData.m_TerminalListModel = new TerminalList();
		return cacheData;
	}

	// --------------------------------------------------------------------------
}
/**
* 画面ウィザードAPI用モデル
*/
export class TerminalRegistWizarddModel {
// public 変数	-----------------------------------------------------------
	public m_TerminalListModel: TerminalList = null;
// ------------------------------------------------------------------------
}