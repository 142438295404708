// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/*\r\n * イッツコムラーニング解約確認画面用CSS\r\n */\n.LearningCancelConfirm-margin {\n  margin-top: 0px !important;\n  margin-bottom: 1rem; }\n  .LearningCancelConfirm-margin li {\n    margin: auto; }\n\n.LearningCancelConfirm-text {\n  padding-inline-start: 0; }\n  .LearningCancelConfirm-text li {\n    text-indent: -1em;\n    padding-left: 1em;\n    list-style: none; }\n\n.LearningCancelConfirm-divider {\n  margin-bottom: 50px; }\n  @media screen and (max-width: 450px) {\n    .LearningCancelConfirm-divider {\n      margin-bottom: 0px; } }\n", ""]);
// Exports
exports.locals = {
	"margin": "LearningCancelConfirm-margin",
	"text": "LearningCancelConfirm-text",
	"divider": "LearningCancelConfirm-divider"
};
module.exports = exports;
